<template>
  <div>
    <v-row justify="start">
      <v-btn
        text
        @click="untriggerDemoArea(false)"
      >
        <v-icon left>
          mdi-chevron-left
        </v-icon>
        <span class="back-to-courses">Visualizza lista corsi</span>
      </v-btn>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <DemoAreaComments />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <DemoAreaPath />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DemoAreaComments from "./DemoAreaComments.vue";
import DemoAreaPath from "./DemoAreaPath.vue";

export default {
  name: "DemoArea",
  components: { DemoAreaComments, DemoAreaPath },
  props: {
    showDemoArea: {
      type: Function,
      default: () => ({})
    },
  },
  methods: {
    untriggerDemoArea(boolean){
      this.showDemoArea(boolean);
    }
  }
};
</script>

<style scoped>
.back-to-courses {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
  text-transform: uppercase;
}
</style>
