<template>
  <div class="mt-2 landing-professor">
    <v-row
      no-gutters
      justify="end"
      align="end"
      class="mb-15 mt-10"
    >
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-btn
          text
          :to="{ name: `home-page` }"
          color="transparent"
        >
          <v-icon
            left
            color="#F4F5F6"
          >
            mdi-chevron-left
          </v-icon>
          <span class="back-to-home"> TORNA ALLA HOME</span>
        </v-btn>
      </v-col>
      <follow-us :align-items="'flex-end'" />
    </v-row>
    <h4 class="type-of-user">
      PROFESSORE
    </h4>
    <h1
      class="professor-title mb-10 mt-10"
      :class="{ 'professor-title-mobile': $vuetify.breakpoint.xsOnly }"
    >
      ENTRA NELLA RETE <span class="colored-hacker">HACKERSGEN</span>
    </h1>
    <h4 class="professor-paragraph mb-7">
      Hackersgen.com è una piattaforma formativa nata dalla volontà di Sorint Lab di offrire continuità alle attività formative dedicate alla scuola anche durante il periodo di lockdown.
      Da sempre Sorint offre opportunità di lavoro e di crescita a giovani ragazzi di talento presso le sedi Italiane ed internazionali e proprio per questo crediamo che la collaborazione con gli Istituti Tecnici del territorio italiano sia fondamentale. 
      Le attività sperimentate per oltre un anno in piattaforma oggi possono dirsi mature e pronte a nuove sfide: abbiamo quindi scelto di aprire la piattaforma e il nostro network, in maniera completamente gratuita, a tutti gli Istituti Italiani che desiderano usufruire delle nostre competenze!
    </h4>
    <v-col
      cols="12"
      class="pa-0"
      align="center"
    >
      <v-btn
        class="professor-link py-2 px-3"
        @click="changeRoute"
      >
        <img
          src="@assets/landingPage/download.svg"
          class="download-icon mr-1"
        >
        UNISCITI AL NOSTRO NETWORK
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import followUs from "@cmp-landing/FollowUs.vue";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "LandingProfessor",
  components: { followUs },
  mixins:[matomoTracker],
  data() {
    return {};
  },
  methods: {
    async changeRoute() {
      await this.trackEvent("LandingPage", "ClickOnPreSignUp");
      this.$router.push({ name: "pre-sign-up"})
    }
  }
};
</script>

<style scoped>
  .landing-professor{
    position:relative;
  }
.back-to-home {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.type-of-user {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #f39200;
}

.professor-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 49px;
  text-align: center;
  color: #f4f5f6;
}

.professor-title-mobile{
  font-size: 40px;
}

.professor-paragraph {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #98a6ae;
}

.colored-hacker {
  background: linear-gradient(#f39200, #fcee21);
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.professor-link {
  max-width: 325px;
  background: #f39200 !important;
  border-radius: 12px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: capitalize;
}
.professor-link-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #f4f5f6;
  inline-size: stretch;
  width: stretch;
  overflow-wrap: break-word;
  word-break: break-word;
  text-transform: capitalize;
}

.download-icon {
  width: 24px;
  height: 24px;
}
</style>
