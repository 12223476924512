<template>
  <div class="stars-wrapper">
    <div
      ref="stars"
      class="stars-container"
    />
  </div>
</template>

<script>
import * as THREE from "three";
export default {
  name: "Stars",
  data() {
    return {
      scene: null,
      renderer: null,
      camera: null,
    };
  },

  mounted() {
    this.createBackground();
  },
  methods: {
    recreateBackground(){
      this.scene = new THREE.Scene();
      this.resetCamera();
      Array(1000).fill().forEach(this.addStar);
      this.renderer.render(this.scene, this.camera);
    },
    createBackground(){
      this.scene = new THREE.Scene();
      this.resetCamera();
      const pointLight = new THREE.PointLight(0xffffff, 1, 2000);
      pointLight.castShadow = true;
      pointLight.position.set(250, 180, 500);
      this.scene.add(pointLight);
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(
        this.$refs.stars.clientWidth,
        this.$refs.stars.clientHeight,
      );
      renderer.setPixelRatio(devicePixelRatio);
      renderer.setClearColor(0x01002e, 1);
      this.renderer = renderer;
      this.$refs.stars.appendChild(this.renderer.domElement);
      Array(1000).fill().forEach(this.addStar);
      this.renderer.render(this.scene, this.camera);
    },
    resetCamera() {
      const camera = new THREE.PerspectiveCamera(
        75,
        this.$refs.stars.clientWidth / this.$refs.stars.clientHeight,
        0.1,
        500,
      );
      camera.position.z = 100;
      this.camera = camera;
    },
    addStar() {
      const geometry = new THREE.SphereGeometry(0.2);
      const material = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        emissive: 0xffffff,
      });
      const star = new THREE.Mesh(geometry, material);
      const [x, y, z] = Array(3)
        .fill()
        .map(() => THREE.MathUtils.randFloatSpread(1000));
      star.castShadow = false;
      star.position.set(x, y, z - 200);
      this.scene.add(star);
    },
  },
};
</script>

<style scoped>
.stars-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
.stars-container {
  width: 100%;
  height: 2000px;
  background: #01002e;
  position: absolute;
}
</style>
