import api from "@services/axiosInstance";
import publicApi from "@services/publicAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Auth/post_login
 */
function login(email, password) {
  return api.post("/login", {
    Email: email,
    Password: password,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Auth/post_verify_otp
 */
function verifyOtp(otp, sessionToken) {
  return api.post("/verify-otp", {
    otp,
    sessionToken,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Auth/post_resend_otp
 */
function resendOtp(email, sessionToken) {
  return api.post("/resend-otp", {
    email,
    sessionToken,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Auth/post_login_sorint
 */
function loginSorint(keycloakToken) {
  return api.post(`/login/sorint?kc_token=${keycloakToken}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Auth/post_refresh
 */
function refreshToken(token) {
  return api.post(`/refresh?token=${token}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Auth/post_oauth_login_with_credentials
 */
function oauthLoginWithCredentials(email, password, nonce) {
  return api.post(
    `/oauth/login-with-credentials?email=${email}&password=${password}&nonce=${nonce}`
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Auth/post_oauth_login_with_jwt
 */
function oauthLoginWithJWT(nonce) {
  return api.post(`/oauth/login-with-jwt?nonce=${nonce}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Student/post_sign_up_student__classroomid_
 */
function signUpStudent({
  name,
  surname,
  email,
  password,
  classId,
  privacy,
  newsletter,
  can_contact,
  schoolYear,
  secEmail,
}) {
  return api.post(`/sign-up/student/${classId}`, {
    email: email,
    password: password,
    name: name,
    surname: surname,
    privacy: privacy,
    newsletter: newsletter,
    canContact: can_contact,
    schoolYear: schoolYear,
    secEmail: secEmail
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Visitor/post_sign_up_visitor
 */
function signUpGuest({
  name,
  surname,
  email,
  password,
  company,
  privacy,
  newsletter,
}) {
  return api.post(`/sign-up/visitor`, {
    Email: email,
    Password: password,
    Name: name,
    Surname: surname,
    Privacy: privacy,
    Newsletter: newsletter,
    Company: company,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Visitor/post_confirm_address_visitor__visitorID_
 */
function confirmVisitorAddress(visitorID) {
  return api.post(`/confirm-address/visitor/${visitorID}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Professor/post_sign_up_professor
 */
function signUpTeacher({
  name,
  surname,
  email,
  password,
  school,
  newsletter,
  privacy,
}) {
  return api.post(`/sign-up/professor`, {
    email: email,
    password: password,
    name: name,
    surname: surname,
    school_code: school,
    newsletter: newsletter,
    privacy: privacy,
  });
}

/**
 * https://s4s-api.sorintdev.it/main/docs/index.html#/Professor/post_sign_up_professor_and_school
 */
function signUpTeacherAndSchool({
  name,
  surname,
  email,
  password,
  school,
  newsletter,
  privacy,
}) {
  return api.post(`/sign-up/professor-and-school`, {
    email: email,
    password: password,
    name: name,
    surname: surname,
    school: school,
    newsletter: newsletter,
    privacy: privacy,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Reset%20Password/post_forgot_password
 */
function forgotPassword(email) {
  return api.post(`/forgot-password?email=${email}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/post_user_change_password
 */
function changePassword({ oldPassword, newPassword }) {
  return api.post(`/user/change-password`, {
    OldPassword: oldPassword,
    NewPassword: newPassword,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Reset%20Password/post_reset_password
 */
function resetPassword({ passwordToken, newPassword }) {
  return api.post(`/reset-password`, {
    PasswordToken: passwordToken,
    NewPassword: newPassword,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/post_user_avatar
 */
function uploadImage(image) {
  const formData = new FormData();
  formData.append("image", image);
  return api.post(`/user/avatar`, formData);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/get_user_avatar
 */
function getAvatar() {
  return api.get(`/user/avatar`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/User/delete_user_avatar
 */
function deleteMyAvatar() {
  return api.delete("/user/avatar");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/get_user
 */
function getMyProfile() {
  return api.get(`/user/my`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Student/put_student_profile
 */
function updateStudentProfile(secondaryMail, canContact) {
  return api.put(`/student/profile`, {
    sec_email: secondaryMail,
    can_contact: canContact,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/User/put_user
 */
function updateUserInformation(name, surname, id) {
  return api.put("/user", {
    name,
    surname,
    user_id: id,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Student/put_student_profile
 */
function updateProfessorProfile(secondaryMail) {
  return api.put(`/professor/profile`, {
    sec_email: secondaryMail,
  });
}
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Visitor/put_visitor_profile
 */
function updateGuestProfile(secondaryMail) {
  return api.put(`/visitor/profile`, {
    sec_email: secondaryMail,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/User/get_check_email
 */

export default {
  login,
  verifyOtp,
  resendOtp,
  signUpStudent,
  signUpTeacher,
  signUpTeacherAndSchool,
  signUpGuest,
  confirmVisitorAddress,
  refreshToken,
  forgotPassword,
  resetPassword,
  changePassword,
  uploadImage,
  getAvatar,
  deleteMyAvatar,
  getMyProfile,
  oauthLoginWithJWT,
  oauthLoginWithCredentials,
  loginSorint,
  updateStudentProfile,
  updateUserInformation,
  updateProfessorProfile,
  updateGuestProfile,
};
