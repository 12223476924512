import Vue from "vue";
import Vuex from "vuex";
import bellMessages from "@store/modules/bellMessages";
import config from "@store/modules/config";
import trigger from "@store/modules/trigger";
import auth from "@store/modules/auth";
import schools from "@store/modules/schools";

Vue.use(Vuex);

const actions = {
  resetState({ commit }) {
    commit("auth/resetState", {}, { root: true });
  },
};

export default new Vuex.Store({
  modules: {
    bellMessages,
    config,
    trigger,
    auth,
    schools,
  },
  actions,
});
