<template>
  <div class="presignup-wrapper">
    <LandingBell />
    <LandingNavigation
      :color="color"
      :flat="false"
      :is-download="false"
    />
    <v-row
      no-gutters
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="8"
        lg="12"
        align="center"
        class="px-5"
      >
        <GoBackButton
          v-if="$vuetify.breakpoint.smAndDown"
          class="mt-10"
        />
        <v-col
          cols="12"
          class="pa-0"
          align="start"
        >
          <h2 class="registration-title mb-5 mt-3">
            Registrati
          </h2>
          <!-- Commentato in attesa di testo definitivo Michele (17/08/2022) -->
          <!-- <h4 class="registration-text mb-10">
            Trasformiamo idee in risultati per scrivere con i nostri clienti
            storie d’innovazione, guidiamo le aziende attraverso un percorso
            d’innovazione digitale che risponda alle esigenze di business.
          </h4> -->
        </v-col>
        <div class="login-card mb-8 pa-6">
          <v-row
            no-gutters
            justify="space-between"
          >
            <v-col
              v-if="$vuetify.breakpoint.lgAndUp"
              class="pa-0"
              cols="12"
              md="3"
            >
              <div class="spacer-title" />
              <v-row
                v-for="permission in permissions"
                :key="permission.text"
                no-gutters
                :class="permission.text.length > 33 ? 'pb-6' : 'pb-10'"
              >
                <h4 class="permission-text">
                  {{ permission.text }}
                </h4>
              </v-row>
            </v-col>
            <v-col
              v-if="$route.query.filter !== 'live-event'"
              class="pa-0"
              cols="12"
              lg="3"
            >
              <v-row
                no-gutters
                justify="center"
                class="py-3"
              >
                <v-col
                  class="pa-0"
                  cols="10"
                  align="center"
                >
                  <h3 class="user-type-title">
                    Appassionato
                  </h3>
                  <h4 class="user-type-subtitle">
                    Unisciti alla Community
                  </h4>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
              >
                <v-col
                  class="pa-0 top-spacing"
                  cols="12"
                  sm="8"
                  lg="12"
                >
                  <v-row
                    v-for="permission in permissions"
                    :key="permission.text"
                    no-gutters
                    justify="start"
                    justify-lg="center"
                    align="center"
                    class="row-spacing flex-nowrap"
                  >
                    <img
                      v-if="permission.visitor"
                      src="@assets/landingPage/check.svg"
                      alt="check"
                    >
                    <img
                      v-else
                      src="@assets/landingPage/x.svg"
                      alt="check"
                    >
                    <h4
                      v-if="!$vuetify.breakpoint.lgAndUp"
                      class="permission-text ml-4"
                    >
                      {{ permission.text }}
                    </h4>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                justify="center"
              >
                <v-btn
                  color="#F39200"
                  class="signup-btn"
                  @click="$router.push({ name: 'sign-up-guest-form' })"
                >
                  Iscriviti
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              v-if="!$vuetify.breakpoint.lgAndUp && $route.query.filter !== 'live-event'"
              cols="12"
            >
              <v-divider
                class="mt-6 mb-4"
                dark
              />
            </v-col>
            <v-col
              class="pa-0"
              cols="12"
              lg="3"
            >
              <v-row
                no-gutters
                justify="center"
                class="py-3"
              >
                <v-col
                  class="pa-0"
                  cols="10"
                  align="center"
                >
                  <h3 class="user-type-title">
                    Professore
                  </h3>
                  <h4 class="user-type-subtitle">
                    Collabora con le tue classi
                  </h4>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                justify="center"
              >
                <v-col
                  class="pa-0 top-spacing"
                  cols="12"
                  sm="8"
                  lg="12"
                >
                  <v-row
                    v-for="permission in permissions"
                    :key="permission.text"
                    no-gutters
                    justify="start"
                    justify-lg="center"
                    align="center"
                    class="row-spacing flex-nowrap"
                  >
                    <img
                      v-if="permission.prof"
                      src="@assets/landingPage/check.svg"
                      alt="check"
                    >
                    <img
                      v-else
                      src="@assets/landingPage/x.svg"
                      alt="check"
                    >
                    <h4
                      v-if="!$vuetify.breakpoint.lgAndUp"
                      class="permission-text ml-4"
                    >
                      {{ permission.text }}
                    </h4>
                  </v-row>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
              >
                <v-btn
                  color="#F39200"
                  class="signup-btn"
                  @click="$router.push({ name: 'sign-up-teacher-form' })"
                >
                  Iscriviti
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              v-if="!$vuetify.breakpoint.lgAndUp"
              cols="12"
            >
              <v-divider
                class="mt-6 mb-4"
                dark
              />
            </v-col>
            <v-col
              class="pa-0"
              cols="12"
              lg="3"
            >
              <v-row
                no-gutters
                justify="center"
                class="py-3"
              >
                <v-col
                  class="pa-0"
                  cols="10"
                  align="center"
                >
                  <h3 class="user-type-title">
                    Studente
                  </h3>
                  <h4 class="user-type-subtitle">
                    Trasforma le tue idee in realtà
                  </h4>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
              >
                <v-col
                  class="pa-0 top-spacing"
                  cols="12"
                  sm="8"
                  lg="12"
                >
                  <v-row
                    v-for="permission in permissions"
                    :key="permission.text"
                    no-gutters
                    justify="start"
                    justify-lg="center"
                    align="center"
                    class="row-spacing flex-nowrap"
                  >
                    <img
                      v-if="permission.student"
                      src="@assets/landingPage/check.svg"
                      alt="check"
                    >
                    <img
                      v-else
                      src="@assets/landingPage/x.svg"
                      alt="check"
                    >
                    <h4
                      v-if="!$vuetify.breakpoint.lgAndUp"
                      class="permission-text ml-4"
                    >
                      {{ permission.text }}
                    </h4>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                justify="center"
              >
                <v-col
                  class="pa-0"
                  cols="10"
                >
                  <h4 class="prof-suggestion-text">
                    Chiedi di essere iscritto dal tuo professore
                  </h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LandingBell from "@cmp-landing/LandingBell.vue";
import LandingNavigation from "@cmp-landing/LandingNavigation.vue";
import GoBackButton from "../shared/utils/GoBackButton.vue";
export default {
  name: "PreSignUp",
  components: { LandingBell, LandingNavigation, GoBackButton },
  data() {
    return {
      color: "#010024",
      permissions: [
        {
          text: "Visualizzazione corsi on demand",
          visitor: true,
          prof: true,
          student: true,
        },
        {
          text: "Visualizzazione corsi live",
          visitor: false,
          prof: true,
          student: true,
        },
        {
          text: "Visualizzazione Q&A",
          visitor: true,
          prof: true,
          student: true,
        },
        {
          text: "Gamification & Ranking",
          visitor: false,
          prof: false,
          student: true,
        },
        {
          text: "Monitoraggio presenza ai corsi on demand",
          visitor: false,
          prof: false,
          student: true,
        },
        {
          text: "Monitoraggio presenza ai corsi live",
          visitor: false,
          prof: false,
          student: true,
        },
        {
          text: "Condivisione materiale didattico",
          visitor: true,
          prof: true,
          student: true,
        },
        {
          text: "Test di certificazione",
          visitor: false,
          prof: false,
          student: true,
        },
        {
          text: "Creazione e gestione Classi",
          visitor: false,
          prof: true,
          student: false,
        },
      ],
    };
  },
  created() {
    const { filter } = this.$route.query;
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}

h2,
.v-card-text {
  font-family: "Karasuma Gothic", sans-serif;
}

#studentAction {
  font-weight: 500;
  font-size: 0.9rem;
}

#info {
  color: lightgray;
  font-size: 0.88rem;
  font-weight: 20;
  font-style: italic;
  font-family: sans-serif;
}

.btn-font {
  font-family: "Karasuma Gothic", sans-serif;
}

.header {
  height: 24%;
}

a {
  color: white;
  text-decoration: none;
}

a:visited {
  color: #f37021;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}
.permission-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  line-height: 16px;
  color: #f4f5f6;
}

.user-type-title {
  font-family: "outerorbit", "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #f4f5f6;
  margin-bottom: 5px;
}
.user-type-subtitle {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #98a6ae;

  width: 203px;
  height: 38px;
}
.spacer-title {
  height: 120px;
}
.top-spacing {
  margin-top: 32px;
}
.row-spacing {
  margin-bottom: 30px;
}
.signup-btn {
  width: 96px;
  height: 35px;
  border-radius: 12px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #f4f5f6;
}
.prof-suggestion-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f39200;
}
.section-spacer {
  margin: 20px 0;
  width: 200px;
  max-width: 100%;
  height: 1px;
  background: #01002e;
}

.presignup-wrapper {
  padding-top: 150px;
  z-index: 2;
}

@media screen and (max-width: 950px) {
  .presignup-wrapper {
    padding-top: 90px;
  }
}
.registration-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 39.06px;
  line-height: 36px;
  letter-spacing: 1px;
  color: #ffffff;
}
.registration-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98a6ae;
}
</style>
