<template>
  <div class="landing-contact">
    <v-row no-gutters align="center" justify="center" class="mt-15">
      <v-col cols="12" class="pa-0">
        <v-row no-gutters justify="center" align="center">
          <h1 class="contact-title">
            {{ title }}
          </h1>
        </v-row>
        <v-col class="pa-0">
          <v-form
            ref="form"
            v-model="valid"
            class="mt-10"
            :key="formKey"
            :lazy-validation="lazy"
          >
            <v-row no-gutters>
              <v-col
                class="pa-0"
                :class="{ 'pr-7': $vuetify.breakpoint.smAndUp }"
                cols="12"
                sm="6"
              >
                <LandingInput
                  :label="'Email (campo obbligatorio)'"
                  :place-holder="'Scrivi qui la tua mail'"
                  :is-disabled="sent"
                  @onUpdateValue="updateEmail"
                />
              </v-col>
              <v-col
                class="pa-0"
                :class="{
                  'pl-7': $vuetify.breakpoint.smAndUp,
                  'mt-5': !$vuetify.breakpoint.smAndUp,
                }"
                cols="12"
                sm="6"
              >
                <LandingInput
                  :label="typeLabel"
                  :place-holder="typePlaceHolder"
                  :is-disabled="sent"
                  @onUpdateValue="updateRole"
                />
              </v-col>
            </v-row>
            <LandingInput
              class="mt-5"
              :label="'Oggetto'"
              :place-holder="'Scrivi qui l\'oggetto della mail'"
              :is-disabled="sent"
              @onUpdateValue="updateSubject"
            />
            <LandingTextArea
              class="mt-5"
              :label="'Testo (campo obbligatorio)'"
              :place-holder="'Scrivi qui il messaggio'"
              :is-disabled="sent"
              @onUpdateValue="updateText"
            />
            <span class="privacy-link">
              <a
                href="https://www.sorint.com/privacy-policy/"
                target="_blank"
                rel="noopener"
                class="mt-3"
                @click="() => trackEvent('LandingPage', 'ClickOnPrivacyPolicy')"
              >
                Clicca qui per leggere la privacy policy di Sorint.</a
              >
            </span>

            <v-checkbox
              :rules="[(v) => v || 'Campo obbligatorio']"
              dark
              color="#F39200"
            >
              <template #label>
                <span class="privacy-checkbox-label"
                  >Ho letto e accetto la privacy policy</span
                >
              </template>
            </v-checkbox>
            <v-btn
              :disabled="!valid || !text || !isValidEmail || sent"
              :loading="loading"
              color="#F39200"
              dark
              class="mt-3 send-btn"
              min-height="45px"
              @click="submit"
            >
              <img src="@assets/landingPage/send.svg" alt="send" class="mr-3" />
              <span class="send-text">
                {{ sent ? "Messaggio inviato!" : "Invia Messaggio" }}
              </span>
            </v-btn>
          </v-form>
        </v-col>
      </v-col>
      <v-snackbar
        v-model="snackbar.enabled"
        timeout="3000"
        right
        top
        :color="snackbar.color"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-row>
  </div>
</template>

<script>
import services from "@services/contactUs";
import { handleAxiosError } from "@utils/notifications";
import LandingInput from "@cmp-landing/LandingInput.vue";
import LandingTextArea from "@cmp-landing/LandingTextArea.vue";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "LandingContact",
  components: { LandingInput, LandingTextArea },
  mixins: [matomoTracker],
  props: {
    title: {
      type: String,
      default: "Email",
    },
  },
  data: () => ({
    valid: true,
    sent: false,
    formKey: 0,
    email: "",
    role: "",
    subject: "",
    text: "",
    loading: false,
    rules: {
      required: (value) => Boolean(value) || "Valore richiesto",
      min: (v) => v.length >= 4 || "Minimo 4 caratteri" /* have to change */,
      messageMaxLength: (v) =>
        !v ||
        v.length <= 200 ||
        "Il messaggio deve essere inferiore a 200 caratteri",
      email: (v) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "Email non valida",
    },
    lazy: false,
    snackbar: {
      enabled: false,
      text: "",
      color: "",
    },
  }),
  computed: {
    isAdminView() {
      return !(this.title === "Hai una domanda?");
    },
    typeLabel() {
      if (this.isAdminView) {
        return "Cosa vuoi proporci?";
      }
      return "Chi sei?";
    },
    typePlaceHolder() {
      if (this.isAdminView) {
        return "Idea/Corso";
      }
      return "Studente/Professore/Lavoratore";
    },
    formatEmailBody() {
      return `
        Email: ${this.email}</br>
        ${this.isAdminView ? "Tipologia" : "Ruolo"}: ${this.role}</br>
        Oggetto: ${this.subject}</br>
        Testo: ${this.text}
      `;
    },
    isValidEmail() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email
      );
    },
  },
  created() {
    if (this.decodedToken && this.userEmail) {
      this.email = this.userEmail;
    }
  },
  methods: {
    updateRole(role) {
      this.role = role;
    },
    updateEmail(email) {
      this.email = email;
    },
    updateSubject(subject) {
      this.subject = subject;
    },
    updateText(text) {
      this.text = text;
    },
    resetInputs() {
      this.email = "";
      this.subject = "";
      this.role = "";
      this.text = "";
    },
    async submit() {
      await this.trackEvent("LandingPage", "ClickOnSubmitContactForm");
      this.loading = true;

      try {
        await services.contactUs(this.email, this.formatEmailBody);
        this.sent = true;
        this.resetInputs();
        this.formKey++;
      } catch (err) {
        await handleAxiosError(`Errore invio messaggio`, err);
        this.$logger(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.contact-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #f4f5f6;
  text-align: center;
}
.privacy-link a,
.privacy-link a:visited,
.privacy-link a:active,
.privacy-link a:focus-within,
.privacy-link a:focus,
.privacy-link a:focus-visible {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-decoration-line: underline;
  color: #f4f5f6 !important;
}
.privacy-checkbox-label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #e0e0e0;
}
.send-btn {
  background: #f39200;
  border-radius: 12px;
}
.send-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f2f2f2;
  text-transform: capitalize;
}
</style>
