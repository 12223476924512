const state = (() => ({
  needsUpdate: false,
}))();

const actions = {
  resetTrigger({ commit }) {
    commit("resetTrigger");
  },
  triggerUpdate({ commit }) {
    commit("setTrigger");
  },
};

const mutations = {
  resetTrigger(state) {
    state.needsUpdate = false;
  },
  setTrigger(state) {
    state.needsUpdate = true;
  },
};

const getters = {
  needsUpdate(state) {
    return state.needsUpdate;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
