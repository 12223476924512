<template>
  <div class="landing-input">
    <h3 class="label">
      {{ label }}
    </h3>
    <input
      v-model="value"
      class="input-field"
      :placeholder="placeHolder"
      :disabled="isDisabled"
    >
  </div>
</template>

<script>
export default {
  name: "LandingInput",
  props:{
    label:{
      type:String,
      default: ""
    },
    placeHolder:{
      type:String,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  data(){
    return {
      value:""
    }
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  methods: {
    updateValue() {
      this.$emit("onUpdateValue", this.value);
    },
  },
};
</script>

<style scoped>
.label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #C6CED2;
}
.input-field{
  color: #f4f5f6;
  padding: 12px 16px;
  border: 1px solid #E8EBED;
  border-radius: 12px;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  width: 100%;
}

.input-field:focus{
  border: 1px solid #f39200;
  outline: 0;
  box-shadow: none;
}

.input-field::placeholder{
  color: #5a6972;
}
</style>
