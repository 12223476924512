<template>
  <div class="mt-2 landing-guest">
    <v-row
      no-gutters
      justify="end"
      align="end"
      class="mb-15 mt-10"
    >
      <v-col v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
          text
          :to="{ name: `home-page` }"
          color="transparent"
        >
          <v-icon
            left
            color="#F4F5F6"
          >
            mdi-chevron-left
          </v-icon>
          <span class="back-to-home"> TORNA ALLA HOME</span>
        </v-btn>
      </v-col>
      <follow-us :align-items="'flex-end'" />
    </v-row>
    <h4 class="type-of-user">
      APPASSIONATO
    </h4>
    <h1 class="guest-title mb-10 mt-10">
      SEGUI I NOSTRI <span class="colored-hacker">CORSI GRATUITI</span>
    </h1>
    <h4 class="guest-paragraph mb-7">
      Hackersgen.com è una piattaforma formativa nata dalla volontà di Sorint
      Lab di offrire continuità alle attività formative dedicate alla scuola
      anche durante il periodo di lockdown. Durante il primo anno di attività
      alla nostra community si sono uniti più di 3500 appassionati di
      informatica, che si formano sui nostri corsi, interagiscono con i nostri
      teacher, supportano studenti rispondendo alle loro domande pubbliche e
      condividono i loro progetti IT. Se ti abbiamo incuriosito, registrati! È
      tutto gratis e il tuo contributo è per noi fondamentale!
    </h4>
    <h4 class="form-section-title mt-15">
      Sei un ex studente?
    </h4>
    <v-col class="d-flex justify-center mt-5">
      <v-btn
        class="guest-link"
        href="https://www.sorint.com/lavora-con-noi/#annunci"
        target="_blank"
        @click="() => trackEvent('LandingPage', 'ClickOnPreSignUp')"
      >
        <v-icon
          left
          size="24"
        >
          mdi-account-plus-outline
        </v-icon>
        UNISCITI A NOI
      </v-btn>
    </v-col>
    <landing-contact :title="`HAI UN'IDEA O UN CORSO DA PROPORCI?`" />
  </div>
</template>

<script>
import followUs from "@cmp-landing/FollowUs.vue";
import landingContact from "./LandingContact.vue";
import matomoTracker from "@mixins/matomoTracker";


export default {
  name: "LandingGuest",
  components: { followUs, landingContact },
  mixins:[matomoTracker]
};
</script>

<style scoped>
.landing-guest {
  position: relative;
}
.back-to-home {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.type-of-user {
  font-family: "outerorbit", 'Orbitron';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: #f39200;
}

.guest-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 49px;
  text-align: center;
  color: #f4f5f6;
}

.guest-paragraph {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #98a6ae;
}

.colored-hacker {
  background: linear-gradient(#f39200, #fcee21);
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.form-section-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #f4f5f6;
  text-align: center;
}

.guest-link {
  background: #f39200 !important;
  border-radius: 12px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: capitalize;
}

.user-x-icon {
  width: 24px;
  height: 24px;
}
</style>
