<template>
  <div class="main-wrapper">
    <landing-bell />
    <landing-navigation
      :color="color"
      :flat="flat"
      :is-download="isDownloadSeason"
      :update-selected-user="updateSelectedUser"
    />
    <v-main class="landing-wrapper">
      <div class="background-wrapper">
        <Stars />
        <BackgroundPlanets
          :padding-top="$vuetify.breakpoint.mdAndDown ? 1600 : 1200"
        />
      </div>
      <v-container class="container-wrapper">
        <transition
          name="slide-fade"
          tag="div"
          class="transition-container"
          mode="out-in"
        >
          <router-view />
        </transition>
      </v-container>

      <v-container class="supporters-wrapper" />
    </v-main>
    <v-scale-transition>
      <v-btn
        v-show="fab"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        color="#F39200"
        class="z-index-btn"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <landing-footer />
  </div>
</template>

<script>
import landingNavigation from "@cmp-landing/LandingNavigation";
import landingFooter from "@cmp-landing/LandingFooter";
import landingBell from "@cmp-landing/LandingBell";
import { mapGetters } from "vuex";
import BackgroundPlanets from "@cmp-space/BackgroundPlanets.vue";
import Stars from "@cmp-space/Stars.vue";

export default {
  name: "App",
  components: {
    landingNavigation,
    landingFooter,
    landingBell,
    BackgroundPlanets,
    Stars,
  },

  metaInfo() {
    return {
      title: "HackersGen",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `HackersGen: Rimani aggiornato sulla proposta formativa live e ondemand interamente "Made in Sorint.lab" e pensata per supportare lo sviluppo di innovativi progetti scolastici`,
        },
      ],
    };
  },
  data: () => ({
    selectedUser: "",
    fab: null,
    color: "",
    flat: null,
    downloadLink: "https://download-event.io",
    marqueeText:
      "DOWNLOAD INNOVATION! Non perdere l'opportunità di partecipare all'evento gratuito dove potrai seguire sessioni IT sui principali trend tech, iscriverti all'Hackathon e conoscere professionisti IT anche internazionali. >>> CLICCA QUI <<<",
  }),
  computed: {
    ...mapGetters("config", ["isDownloadSeason"]),
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "#010024";
        this.flat = false;
      } else {
        this.color = "#01002e";
        this.flat = true;
      }
    },
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") {
        return;
      }
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    updateSelectedUser(_user) {
      this.selectedUser = _user;
    },
  },
};
</script>

<style scoped>
.z-index-btn {
  z-index: 101;
}
.landing-wrapper {
  background-color: #01002e;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  position: relative;
}

.slide-fade-enter-active {
  transition: all 1.2s;
}

.slide-fade-leave-active {
  transition: all 1s;
}

.slide-fade-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.background-wrapper {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.container-wrapper {
  max-width: 1200px;
  padding-top: 150px;
  z-index: 3;
}

@media screen and (max-width: 950px) {
  .container-wrapper {
    padding-top: 90px;
  }
}
.transition-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 950px) {
  .container-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.supporters-wrapper {
  max-width: 100%;
  z-index: 3;
}
</style>
