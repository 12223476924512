<template>
  <v-app-bar
    height="max(100px, 'fit-content')"
    :color="color"
    :flat="flat"
    dark
    app
    class="landing-navigation"
    :class="{ expand: flat }"
  >
    <v-container class="fill-height navigation-wrapper">
      <v-row
        no-gutters
        class="fill-height"
        align="center"
        :justify="isXs ? 'space-between' : 'start'"
      >
        <v-img
          src="@/assets/landingPage/HG_LOGO.webp"
          max-height="31px"
          :max-width="isXs ? '250px' : '269px'"
        />
        <v-app-bar-nav-icon v-if="isXs" @click.stop="drawer = true" />
        <v-row
          v-else
          class="ml-5 fill-height"
          no-gutters
          align="center"
          justify="space-between"
        >
          <v-btn class="mx-2 home-btn" rounded text :to="{ name: `home-page` }">
            Home
          </v-btn>
          <v-btn
            class="mx-2 navigation-btn"
            rounded
            text
            target="_blank"
            @click="
              () => {
                trackCVLink();
                gotoCV();
              }
            "
          >
            il tuo cv
          </v-btn>
          <v-btn
            v-if="homePageRoute"
            class="mx-2 navigation-btn"
            rounded
            text
            @click="goToSection('corsi')"
          >
            corsi
          </v-btn>
          <v-menu offset-y transition="slide-y-transition">
            <template #activator="{ on, attrs, value }">
              <v-btn
                class="mx-2 navigation-btn"
                :class="{ 'animated-btn': !noLinks }"
                rounded
                text
                v-bind="attrs"
                :disabled="noLinks"
                v-on="on"
              >
                Altre offerte
                <v-icon
                  class="navigation-btn_icon orange--text"
                  :class="{ rotate: value }"
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <v-list class="links-list">
              <v-list-item
                v-for="(link, index) in links"
                :key="index"
                class="link-list_item"
                :href="link.url"
                target="_blank"
                @click="() => trackOffersLink(link.url)"
              >
                <v-list-item-title class="link-list_title">
                  {{ link.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            class="mx-2 navigation-btn"
            rounded
            text
            data-cy="signin"
            @click="() => gotoSignin()"
          >
            Accedi
          </v-btn>
          <v-btn
            class="ml-2 register-btn"
            height="48px"
            text
            data-cy="signup"
            @click="() => gotoRegister()"
          >
            Registrati
          </v-btn>
        </v-row>
      </v-row>
    </v-container>
    <v-scale-transition mode="out-in" leave-absolute>
      <MobileMenu
        v-if="drawer"
        :links="links"
        @onCloseDrawer="drawer = false"
      />
    </v-scale-transition>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import settingsServices from "@services/settings";
import MobileMenu from "@cmp-landing/MobileMenu.vue";
import matomoTracker from "@mixins/matomoTracker";

import { pushAuthUrl, pushFormsUrl } from "@/utils/externalRouter";

export default {
  name: "LandingNavigation",
  components: { MobileMenu },
  mixins: [matomoTracker],
  props: {
    flat: Boolean,
    color: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    drawer: false,
    isXs: false,
    links: [],
    items: [
      ["mdi-television-play", "Corsi", "#corsi"],
      ["mdi-trophy-outline", "Progetti", "#progetti"],
      ["mdi-star-circle-outline", "Download innovation", "#dw-innovation"],
      ["mdi-login", "Accedi", "login"],
      ["mdi-account-plus-outline", "Registrati", "pre-sign-up"],
    ],
  }),
  computed: {
    ...mapGetters("config", ["isDownloadSeason"]),
    getItems() {
      if (!this.isDownloadSeason) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.items.splice(2, 1); //per rimuovere il bottone del download innovation
        return this.items;
      }
      return this.items;
    },
    noLinks() {
      return !(this.links.length > 0);
    },
    homePageRoute() {
      return this.$route.name === `home-page`;
    },
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  async created() {
    const { data } = await settingsServices.getLandingLinks();
    this.links = data;
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 950;
    },
    goTo(link) {
      if (link[0] === "#") {
        this.$vuetify.goTo(link);
      } else {
        this.$router.push({
          name: link,
        });
      }
    },
    async goToSection(_id) {
      await this.trackEvent("LandingPage", "ClickOnCoursesSection");
      document
        .getElementById(_id)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    async trackOffersLink(offerName) {
      await this.trackEvent("LandingPage", "ClickOnOurOffer", offerName);
    },
    async trackCVLink() {
      await this.trackEvent("LandingPage", "ClickOnCVLink");
    },
    gotoCV() {
      pushFormsUrl("/upload-cv");
    },
    gotoSignin() {
      pushAuthUrl();
    },
    gotoRegister() {
      pushAuthUrl("/register");
    },
  },
};
</script>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.landing-navigation {
  left: 0 !important;
}

.navigation-wrapper {
  max-width: 1200px;
}

.expand {
  height: max(100px, "fit-content") !important;
  padding-top: 10px;
}

.home-btn {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.navigation-btn {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.animated-btn::after {
  position: absolute;
  content: "";
  left: -10px;
  right: 0;
  z-index: -1;
  height: 105%;
  width: 105%;
  transform: scale(0.9) translateZ(0);
  filter: blur(15px);
  border-radius: 50px;
  background: linear-gradient(
    to left,
    #ff5770,
    #e4428d,
    #c42da8,
    #9e16c3,
    #6501de,
    #9e16c3,
    #c42da8,
    #e4428d,
    #ff5770
  );
  opacity: 0.5;
  background-size: 200% 200%;
  animation: animateGlow 1.75s linear infinite;
}

@keyframes animateGlow {
  0% {
    background-position: 200% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.register-btn {
  padding: 10px 16px;
  border: 2px solid #f39200;
  border-radius: 8px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.links-list {
  background-color: #1d1c4c;
  border-radius: 8px;
}

.link-list_title {
  color: #f4f5f6;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
}

.navigation-btn_icon {
  transition: transform 0.3s ease-in-out !important;
}

.navigation-btn_icon.rotate {
  transform: rotate(180deg);
}
</style>
