<template>
  <div class="mt-2 landing-student">
    <v-row
      no-gutters
      justify="end"
      align="end"
      class="mb-15 mt-10"
    >
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-btn
          text
          :to="{ name: `home-page` }"
          color="transparent"
        >
          <v-icon
            left
            color="#F4F5F6"
          >
            mdi-chevron-left
          </v-icon>
          <span class="back-to-home"> TORNA ALLA HOME</span>
        </v-btn>
      </v-col>
      <follow-us :align-items="'flex-end'" />
    </v-row>
    <v-col>
      <h4 class="type-of-user">
        STUDENTE
      </h4>
      <h1
        class="student-title mb-5 mt-10"
        :class="{ 'student-title-mobile': $vuetify.breakpoint.smAndDown }"
      >
        chi è un <span class="colored-hacker">hacker?</span>
      </h1>
      <h4 class="student-paragraph">
        È ora di ridare giustizia a questo termine troppo spesso inteso con un'accezione negativa: un hacker originariamente indicava semplicemente una persona creativa e non convenzionale. Una persona capace di vedere porte dove gli altri vedono muri e in grado di costruire ponti dove gli altri vedono ostacoli. 
        Per noi gli hacker sono uomini e donne la cui competenza permette di vedere l’ordine delle cose sotto nuove prospettive dando così forma a nuove prorompenti idee. Pensatori originali e artisti del byte.
        Se sei d’accordo con noi, allora vorremmo provare ad aiutarti nel tuo percorso scolastico.
      </h4>
    </v-col>
    <landing-articles />
  </div>
</template>

<script>
import followUs from "@cmp-landing/FollowUs.vue";
import landingArticles from "@cmp-landing/LandingArticles";

export default {
  name: "LandingStudent",
  components: { followUs, landingArticles },
  data() {
    return {};
  },
};
</script>

<style scoped>
  .landing-student{
    position: relative;
  }
.back-to-home {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.type-of-user {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #f39200;
}

.student-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 49px;
  text-align: center;
  color: #f4f5f6;
  text-transform: uppercase;
}

.student-title-mobile{
  font-size: 43px;
}

.student-paragraph {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #98a6ae;
}

.colored-hacker {
  background: linear-gradient(#f39200, #fcee21);
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.student-link {
  height: min(40px, "min-content") !important;
  background: #f39200 !important;
  border-radius: 12px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between
}

.student-link-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #f4f5f6;
  inline-size: stretch;
  width: stretch;
  overflow-wrap: break-word;
  word-break: break-word;
  text-transform: capitalize;
}

.cpu-icon {
  width: 24px;
  height: 24px;
}
</style>
