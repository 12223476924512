<template>
  <div class="landing-text-area">
    <h3 class="label">
      {{ label }}
    </h3>
    <textarea
      v-model="value"
      class="text-area-field"
      :placeholder="placeHolder"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
export default {
  name: "LandingInput",
  props:{
    label:{
      type:String,
      default: "Email"
    },
    placeHolder:{
      type:String,
      default: "Scrivi qui la tua email"
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  data(){
    return {
      value:""
    }
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  methods: {
    updateValue() {
      this.$emit("onUpdateValue", this.value);
    },
  },
};
</script>

<style scoped>
.label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #C6CED2;
}
.text-area-field{
  padding: 12px 16px;
  border: 1px solid #E8EBED;
  color: #F4F5F6;
  border-radius: 12px;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  width: 100%;
  height: 218px;
}

.text-area-field::placeholder{
  color: #5a6972;
}

.text-area-field:focus{
  border: 1px solid #f39200;
  outline: 0;
  box-shadow: none;
}
</style>
