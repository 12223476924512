<template>
  <div class="login-card register-card mb-8">
    <v-row dense>
      <v-col>
        <v-row dense>
          <h2 class="registration-title pb-6">Aggiungi la tua classe</h2>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 mb-2">
            <v-text-field
              v-model="schoolName"
              label="Nome della scuola"
              outlined
              dark
              background-color="transparent"
              data-cy="input-first-name"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="4" class="mb-8">
            <v-select
              v-model="selectedRegion"
              :items="regions"
              label="Regione"
              hide-details
              outlined
              searchable
              dark
              background-color="transparent"
              data-cy="select-input-region"
              @change="resetSelectedRegion"
            />
          </v-col>
          <v-col cols="12" sm="4" class="mb-8">
            <v-select
              v-model="selectedProvince"
              :items="selectableProvinces"
              :disabled="!selectedRegion"
              label="Provincia"
              hide-details
              outlined
              searchable
              dark
              background-color="transparent"
              data-cy="select-input-province"
              @change="resetSelectedProvince"
            />
          </v-col>
          <v-col cols="12" sm="4" class="mb-8">
            <v-select
              v-model="selectedCity"
              :items="selectableCities"
              :disabled="!selectedProvince"
              label="Città"
              hide-details
              outlined
              searchable
              dark
              background-color="transparent"
              data-cy="select-input-city"
              @change="resetSelectedCity"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="8" class="mb-8">
            <v-text-field
              v-model="address"
              label="Indirizzo"
              hide-details
              outlined
              searchable
              dark
              background-color="transparent"
              data-cy="select-input-region"
            />
          </v-col>
          <v-col cols="12" sm="4" class="mb-8">
            <v-text-field
              v-model="cap"
              label="CAP"
              hide-details
              outlined
              searchable
              dark
              background-color="transparent"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 mb-2">
            <v-text-field
              v-model="schoolType"
              label="Tipo di scuola"
              outlined
              dark
              background-color="transparent"
              data-cy="input-first-name"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 mb-2">
            <v-text-field
              v-model="schoolCode"
              label="Codice scuola *"
              :rules="[rules.required]"
              :error-messages="
                existingSchool
                  ? 'Il codice che hai inserito appartiene ad una scuola già presente nel nostro database.'
                  : ''
              "
              outlined
              dark
              background-color="transparent"
              :class="{ 'error-input': existingSchool }"
              data-cy="input-first-name"
            />
            <v-row v-if="existingSchool" class="warning-message">
              <v-col>
                <p>
                  <span
                    class="text-decoration-underline cursor-pointer"
                    @click="$emit('schoolAdded')"
                    >Cliccando Qui</span
                  >
                  ti riporteremo alla fase di registrazione dove potrai inserire
                  i dati corretti.
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p
          class="white--text font-weight-light text-body-2 text-justify"
          color="#F39200"
        >
          Cliccando su "Conferma" ci invierai i dati inseriti in questa
          schermata al fine di aggiungere la tua scuola al nostro database.
        </p>
      </v-col>

      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          color="#F39200"
          :disabled="!schoolCode || existingSchool"
          dark
          class="register-btn"
          @click="confirm"
        >
          Conferma
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GoBackRegistration from "@cmp-shared/utils/GoBackRegistration.vue";
import { regions } from "@data/regions.js";
import { provinces } from "@data/provinces.js";
import { cities } from "@data/cities.js";

export default {
  components: { GoBackRegistration },
  data() {
    return {
      schoolName: "",
      selectedRegion: "",
      selectedProvince: "",
      selectedCity: "",
      address: "",
      cap: "",
      schoolType: "",
      schoolCode: "",
      category: "scuola",
      rules: {
        required: (value) => {
          if (!value && !this.existingSchool) {
            return "Valore richiesto";
          }
          return true;
        },
      },
      existingSchool: false,
    };
  },
  watch: {
    schoolCode: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.existingSchool = false;
      }
    },
  },
  computed: {
    regions() {
      return regions.map((region) => region.toUpperCase());
    },
    selectableProvinces() {
      return provinces
        .filter(
          (province) =>
            province.region.toUpperCase() == this.selectedRegion.toUpperCase()
        )
        .map((province) => province.name.toUpperCase());
    },
    selectableCities() {
      return cities
        .filter(
          (city) =>
            city.province.toUpperCase() == this.selectedProvince.toUpperCase()
        )
        .map((city) => city.name.toUpperCase());
    },
  },
  methods: {
    ...mapActions("schools", ["getSchoolsByParameter"]),
    async confirm() {
      await this.checkSchool();

      if (this.existingSchool === false) {
        this.$emit("schoolSaved", {
          name: this.schoolName,
          region: this.selectedRegion,
          province: this.selectedProvince,
          city: this.selectedCity,
          address: this.address,
          zip_code: this.cap,
          type: this.schoolType.toUpperCase(),
          code: this.schoolCode.toUpperCase(),
          category: this.category,
        });
        this.$emit("schoolAdded");
      }
    },
    async checkSchool() {
      try {
        const school = await this.getSchoolsByParameter({
          parameter: "code",
          value: this.schoolCode,
        });

        this.existingSchool = true;
      } catch (error) {
        this.existingSchool = false;
      }
    },

    resetSelectedRegion() {
      this.selectedProvince = "";
      this.selectedCity = "";
      this.selectedSchool = "";
    },
    resetSelectedProvince() {
      this.selectedCity = "";
      this.selectedSchool = "";
    },
    resetSelectedCity() {
      this.selectedSchool = "";
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}

h2,
h4,
p {
  text-align: left;
  font-family: "Karasuma Gothic", sans-serif;
  font-size: 1.5em;
}

.no-margin-row {
  margin-left: 0;
  margin-right: 0;
}

.register-btn {
  padding: 8px 12px;
  gap: 12px;
  border-radius: 12px;
}

a {
  color: #00a1ff;
  text-decoration: none;
}

a:visited {
  color: #00a1ff;
  text-decoration: none;
}

a:hover {
  color: #00a1ff;
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.signup-wrapper {
  z-index: 2;
}

.colored {
  color: #f39200;
}

.block {
  display: block;
}

.register-card {
  padding: 24px;
  border-radius: 12px;
}

.registration-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 39.06px;
  line-height: 36px;
  letter-spacing: 1px;
  color: #ffffff;
}

.hg-logo {
  height: 62px;
  max-width: 80%;
  object-fit: contain;
  object-position: center;
}

.warning-message {
  font-size: 10px;
  color: #fc4955;
  font-weight: 400;
  margin: -18px 0px;
  font-size: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

::v-deep .error-input input {
  color: #fc4955;
}
</style>
