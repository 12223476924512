import schoolService from "@services/schools";
import { handleAxiosError } from "@utils/notifications";

const actions = {
  async getSchoolsByParameter({ commit }, { parameter, value }) {
    try {
      const { data } = await schoolService.getSchoolsByParameter(
        parameter,
        value
      );
      return data;
    } catch (e) {
      if (parameter === "code") {
        await handleAxiosError("Nessuna scuola con questo codice", e, false);
      }
      await handleAxiosError("Errore nel caricamento delle scuole", e);
      return null;
    }
  },
};

export default {
  namespaced: true,
  actions,
};
