<template>
  <div>
    <v-row class="mb-2">
      <v-col cols="12">
        <iframe
          id="video"
          ref="videoPlayer"
          src="https://player.vimeo.com/video/765837212"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="3"
        sm="5"
        :class="{ 'd-flex justify-center align-end': $vuetify.breakpoint.xsOnly }"
      >
        <span
          class="stepper-span py-2"
          :class="{ 'stepper-span-clicked': selectedTab === 0 }"
          @click="() => (selectedTab = 0)"
        >Informazioni del corso</span>
        <hr
          v-if="selectedTab === 0"
          class="tab-divider-info"
        >
      </v-col>
      <v-col
        cols="12"
        md="5"
        sm="5"
        :class="{ 'd-flex justify-center align-end mb-2': $vuetify.breakpoint.xsOnly }"
      >
        <span
          class="stepper-span py-2"
          :class="{ 'stepper-span-clicked': selectedTab === 1 }"
          @click="() => (selectedTab = 1)"
        >Domande anonime</span>
        <hr
          v-if="selectedTab === 1"
          class="tab-divider-questions"
        >
      </v-col>
      <v-col
        cols="12"
        md="4"
        xl="4"
        sm="2"
        class="d-flex justify-end"
      >
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              class="resources-button rounded-lg"
              :class="$vuetify.breakpoint.xsOnly ? 'mt-2' : ''"
              :width="$vuetify.breakpoint.xsOnly ? '100%' : ''"
              outlined
              color="#F39200"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>
                mdi-layers-triple-outline
              </v-icon>
              Materiali
            </v-btn>
          </template>
          <span>Accedi o Registrati per seguire il corso</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <template v-if="selectedTab === 0">
      <h1 class="course-title my-2">
        HTML & CSS base- DIV
      </h1>
      <p class="course-paragraph">
        Gli studenti verranno introdotti in live alle funzionalità base dei
        linguaggi di markup HTML e CSS, esplorandone le potenzialità,
        accompagnandoli nella costruzione di un primo progetto web di
        esercitazione e svelando qualche trucco del mestiere. Gli studenti
        verranno introdotti in live alle funzionalità base dei linguaggi di
        markup HTML e CSS, esplorandone le potenzialità.
      </p>
      <v-row>
        <v-col cols="12">
          <h4 class="trainer-name">
            Leonardo Mussato
          </h4>
          <span class="trainer-role">Frontend Developer</span>
        </v-col>
      </v-row>
    </template>
    <DemoMockedComments
      v-if="selectedTab === 1"
      class="mt-2"
    />
  </div>
</template>

<script>
import DemoMockedComments from "./DemoMockedComments.vue"
export default {
  name: "DemoAreaComments",
  components: { DemoMockedComments },
  data: () => ({
    selectedTab: 0,
  }),
};
</script>

<style scoped>
#video {
  background-color: black;
  min-height: 300px;
  height: 100%;
  width: 100%;
  border: none;
}

textarea {
  resize: none;
}

.course-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.course-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #98a6ae;
}

.trainer-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #f4f5f6;
}

.trainer-role {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #98a6ae;
}
.stepper-span {
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
}

.stepper-span-clicked {
  color: #f39200;
  font-weight: 700;
}

.tab-divider-info {
  width: 174.44px;
  position: absolute;
  border: 1px solid #f39200;
}

.tab-divider-questions {
  width: 146.17px;
  position: absolute;
  border: 1px solid #f39200;
}
</style>