<template>
  <div id="app">
    <v-app>
      <router-view />
      <BaseFooter />
    </v-app>
  </div>
</template>

<script>
import BaseFooter from "@cmp-landing/Footer";

export default {
  name: "App",
  components: {
    BaseFooter,
  },
};
</script>

<style>
@font-face {
  font-family: "camingo";
  src: url("./assets/camingocode-regular-webfont.woff");
  font-weight: regular;
}

@font-face {
  font-family: "camingo";
  src: url("./assets/camingocode-bold-webfont.woff");
  font-weight: bold;
}

#nprogress .bar {
  background: rgb(255, 255, 255) !important;
}

h1,
h2,
h3 {
  font-family: "Karasuma Gothic", sans-serif;
}

#app {
  font-family: "Karasuma Gothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bit-text {
  font-family: "Karasuma Gothic", sans-serif;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #8f9092;
}

*::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: #8f9092;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2b2b2b;
}

* {
  scrollbar-width: 10px;
  scrollbar-color: #2b2b2b #8f9092;
}

.glassify {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
}

.transparent {
  background: rgba(0, 0, 0, 0) !important;
}
@supports not (backdrop-filter: blur(1px)) {
  .glassify {
    background: rgba(48, 48, 48, 0.6) !important;
  }
}

.v-alert__icon {
  align-self: center !important;
}

.camingo {
  font-family: "camingo", sans-serif;
}

.no-background-button::before {
  opacity: 0 !important;
}
</style>
