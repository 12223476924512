<template>
  <div class="player-vimeo-container">
    <iframe
      class="player-vimeo"
      :src="videoLink"
      title="Video"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  name: "PlayerVimeo",
  props: {
    videoLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.player-vimeo-container {
  position: relative;
  width: 100%;
  height: 320px;
  border: none;
}

.player-vimeo {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
