<template>
  <div no-gutters class="numbers-displayer pa-5">
    <div v-for="section in sections" :key="section.key" class="counter-section">
      <v-col class="pa-0" align="center">
        <img :src="section.icon" alt="" />
        <h3 class="section-number">+{{ section.counter }}</h3>
        <h4 class="section-title">
          {{ section.title }}
        </h4>
      </v-col>
    </div>
  </div>
</template>

<script>
import studentsIcon from "@assets/users-icon/students.svg";
import usersIcon from "@assets/users-icon/users.svg";
import schoolsIcon from "@assets/users-icon/schools.svg";
// import statsService from "@services/statistics.js"

export default {
  name: "NumbersDisplayer",
  data() {
    return {
      sections: [
        {
          key: "num_students",
          icon: studentsIcon,
          title: "Studenti",
          counter: 6000,
        },
        {
          key: "num_schools",
          icon: schoolsIcon,
          title: "Istituti",
          counter: 100,
        },
        {
          key: "num_users",
          icon: usersIcon,
          title: "Iscritti",
          counter: 7000,
        },
      ],
    };
  },
};
</script>

<style scoped>
.numbers-displayer {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 120px;
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.5) 1.57%,
      rgba(1, 0, 46, 0.5) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.8) 1.57%,
      rgba(196, 196, 196, 0.1) 100%
    );
  backdrop-filter: blur(60px);
  border-radius: 10px;
  max-width: 100%;
}

.counter-section {
  width: 129px;
  position: relative;
}
.counter-section:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 55px;
  background-color: #98a6ae;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.section-number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #f4f5f6;
  margin: 0.2rem 0;
}
.section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98a6ae;
}
</style>
