/**
 * Landing Page
 */
import LandingPage from "@views/LandingPage";

/**
 *
 * Authentication Views
 */

const WaitingApproval = () =>
  import(/*webpackChunkName: "teacher" */ "@views-teacher/WaitingApproval");

import Login from "@views/Login";
import SignUpStudent from "@views/SignUpStudent";
import SignUpTeacher from "@views/SignUpTeacher";
import SignUpGuest from "@views/SignUpGuest";
import LandingStudent from "@cmp-landing/LandingStudent";
import LandingHomePage from "@cmp-landing/LandingHomePage";
import LandingProfessor from "@cmp-landing/LandingProfessor";
import LandingGuest from "@cmp-landing/LandingGuest";
import PreSignUp from "@cmp-landing/PreSignUp";
import ErrorPage from "@views/ErrorPage";

const routes = [
  {
    path: "/",
    children: [
      {
        path: "/",
        name: "home-page",
        component: LandingHomePage,
      },
      {
        path: "/landing-student",
        name: "landing-student",
        component: LandingStudent,
      },
      {
        path: "/landing-professor",
        name: "landing-professor",
        component: LandingProfessor,
      },
      {
        path: "/landing-guest",
        name: "landing-guest",
        component: LandingGuest,
      },
    ],
    component: LandingPage,
  },
  {
    path: "/",
    children: [
      {
        path: "/sign-up-teacher",
        name: "sign-up-teacher-form",
        component: SignUpTeacher,
      },
      {
        path: "/sign-up-guest",
        name: "sign-up-guest-form",
        component: SignUpGuest,
      },
      {
        path: "/sign-up-student/:classId",
        name: "sign-up-student",
        component: SignUpStudent,
      },
      {
        path: "/pre-sign-up",
        name: "pre-sign-up",
        component: PreSignUp,
      },
      {
        path: "/waiting",
        component: WaitingApproval,
        name: "waiting-approval",
      },
    ],
    component: Login,
  },
  {
    path: "*",
    name: "NotFound",
    component: ErrorPage,
  },
];

export default routes;
