// Truncate a username (last 2 characters) from email address and replace the chars with ***

const truncateUsername = (inputString) => {
  const atIndex = inputString.indexOf("@");
  const username = inputString.substring(0, atIndex);
  const asterisks = "***";

  const truncatedUsername = username.substring(0, username.length - 2);

  return truncatedUsername + asterisks + inputString.substring(atIndex);
};

export { truncateUsername };
