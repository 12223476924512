export const cities = [
  {
    name: "Abano Terme",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Abbadia Cerreto",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Abbadia Lariana",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Abbadia San Salvatore",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Abbasanta",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Abbateggio",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Abbiategrasso",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Abetone Cutigliano",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Abriola",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Acate",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Accadia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Acceglio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Accettura",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Acciano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Accumoli",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Acerenza",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Acerno",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Acerra",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Aci Bonaccorsi",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Aci Castello",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Aci Catena",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Aci Sant'Antonio",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Acireale",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Acquafondata",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Acquaformosa",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Acquafredda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Acqualagna",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Acquanegra Cremonese",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Acquanegra sul Chiese",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Acquapendente",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Acquappesa",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Acquaro",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Acquasanta Terme",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Acquasparta",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Acquaviva Collecroce",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Acquaviva Picena",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Acquaviva Platani",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Acquaviva d'Isernia",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Acquaviva delle Fonti",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Acquedolci",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Acqui Terme",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Acri",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Acuto",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Adelfia",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Adrano",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Adrara San Martino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Adrara San Rocco",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Adria",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Adro",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Affi",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Affile",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Afragola",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Africo",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Agazzano",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Agerola",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Aggius",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Agira",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Agliana",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Agliano Terme",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Aglie'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Aglientu",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Agna",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Agnadello",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Agnana Calabra",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Agnone",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Agnosine",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Agordo",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Agosta",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Agra",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Agrate Brianza",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Agrate Conturbia",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Agrigento",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Agropoli",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Agugliano",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Agugliaro",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Aicurzio",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Aidomaggiore",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Aidone",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Aielli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Aiello Calabro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Aiello del Friuli",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Aiello del Sabato",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Aieta",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Ailano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Ailoche",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Airasca",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Airola",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Airole",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Airuno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Aisone",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Ala",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ala di Stura",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ala' dei Sardi",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Alagna",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Alagna Valsesia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Alanno",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Alassio",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Alatri",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Alba Adriatica",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Albagiara",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Albairate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Albanella",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Albano Laziale",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Albano Sant'Alessandro",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Albano Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Albano di Lucania",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Albaredo d'Adige",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Albaredo per San Marco",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Albareto",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Albaretto della Torre",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Albavilla",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Albenga",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Albera Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Alberobello",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Alberona",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Albese con Cassano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Albettone",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Albi",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Albiano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Albiano d'Ivrea",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Albiate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Albidona",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Albignasego",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Albinea",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Albino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Albiolo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Albisola Superiore",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Albissola Marina",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Albizzate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Albonese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Albosaggia",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Albugnano",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Albuzzano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Alcamo",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Alcara li Fusi",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Aldeno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Aldino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Ales",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Alessandria",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Alessandria del Carretto",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Alessandria della Rocca",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Alessano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Alezio",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Alfano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Alfedena",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Alfianello",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Alfiano Natta",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Alfonsine",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Alghero",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Algua",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ali'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Ali' Terme",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Alia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Aliano",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Alice Bel Colle",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Alice Castello",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Alife",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Alimena",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Aliminusa",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Allai",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Alleghe",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Allein",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Allerona",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Alliste",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Allumiere",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Alluvioni Piovera",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Alme'",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Almenno San Bartolomeo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Almenno San Salvatore",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Almese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Alonte",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Alpago",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Alpette",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Alpignano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Alseno",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Alserio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Alta Val Tidone",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Alta Valle Intelvi",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Altamura",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Altare",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Altavalle",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Altavilla Irpina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Altavilla Milicia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Altavilla Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Altavilla Silentina",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Altavilla Vicentina",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Altidona",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Altilia",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Altino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Altissimo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Altivole",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Alto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Alto Reno Terme",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Alto Sermenza",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Altofonte",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Altomonte",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Altopascio",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Altopiano della Vigolana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Alviano",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Alvignano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Alvito",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Alzano Lombardo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Alzano Scrivia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Alzate Brianza",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Amalfi",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Amandola",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Amantea",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Amaro",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Amaroni",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Amaseno",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Amato",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Amatrice",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Ambivere",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Amblar-Don",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ameglia",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Amelia",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Amendolara",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Ameno",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Amorosi",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Ampezzo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Anacapri",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Anagni",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Ancarano",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Ancona",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Andali",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Andalo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Andalo Valtellino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Andezeno",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Andora",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Andorno Micca",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Andrano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Andrate",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Andreis",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Andretta",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Andria",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Andriano",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Anela",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Anfo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Angera",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Anghiari",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Angiari",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Angolo Terme",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Angri",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Angrogna",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Anguillara Sabazia",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Anguillara Veneta",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Annicco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Annone Veneto",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Annone di Brianza",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Anoia",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Antegnate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Anterivo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Antey-Saint-Andre'",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Anticoli Corrado",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Antignano",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Antillo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Antonimina",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Antrodoco",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Antrona Schieranco",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Anversa degli Abruzzi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Anzano del Parco",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Anzano di Puglia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Anzi",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Anzio",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Anzola d'Ossola",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Anzola dell'Emilia",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Aosta",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Apecchio",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Apice",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Apiro",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Apollosa",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Appiano Gentile",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Appiano sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Appignano",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Appignano del Tronto",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Aprica",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Apricale",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Apricena",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Aprigliano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Aprilia",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Aquara",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Aquila d'Arroscia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Aquileia",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Aquilonia",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Aquino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Aradeo",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Aragona",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Aramengo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Arba",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Arborea",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Arborio",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Arbus",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Arcade",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Arce",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Arcene",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Arcevia",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Archi",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Arcidosso",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Arcinazzo Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Arcisate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Arco",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Arcola",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Arcole",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Arconate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Arcore",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Arcugnano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Ardara",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Ardauli",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Ardea",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Ardenno",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Ardesio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ardore",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Arena",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Arena Po",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Arenzano",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Arese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Arezzo",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Argegno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Argelato",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Argenta",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Argentera",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Arguello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Argusto",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Ari",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Ariano Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Ariano nel Polesine",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Ariccia",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Arielli",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Arienzo",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Arignano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Aritzo",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Arizzano",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Arlena di Castro",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Arluno",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Armeno",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Armento",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Armo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Armungia",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Arnad",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Arnara",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Arnasco",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Arnesano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Arola",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Arona",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Arosio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Arpaia",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Arpaise",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Arpino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Arqua' Petrarca",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Arqua' Polesine",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Arquata Scrivia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Arquata del Tronto",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Arre",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Arrone",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Arsago Seprio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Arsie'",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Arsiero",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Arsita",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Arsoli",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Arta Terme",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Artegna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Artena",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Artogne",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Arvier",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Arzachena",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Arzago d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Arzana",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Arzano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Arzergrande",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Arzignano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Ascea",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Asciano",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Ascoli Piceno",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Ascoli Satriano",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Ascrea",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Asiago",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Asigliano Veneto",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Asigliano Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Asola",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Asolo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Assago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Assemini",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Assisi",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Asso",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Assolo",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Assoro",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Asuni",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Ateleta",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Atella",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Atena Lucana",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Atessa",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Atina",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Atrani",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Atri",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Atripalda",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Attigliano",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Attimis",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Atzara",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Augusta",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Auletta",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Aulla",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Aurano",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Aurigo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Auronzo di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Ausonia",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Austis",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Avegno",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Avelengo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Avella",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Avellino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Averara",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Aversa",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Avetrana",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Avezzano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Aviano",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Aviatico",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Avigliana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Avigliano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Avigliano Umbro",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Avio",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Avise",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Avola",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Avolasca",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ayas",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Aymavilles",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Azeglio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Azzanello",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Azzano Decimo",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Azzano Mella",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Azzano San Paolo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Azzano d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Azzate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Azzio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Azzone",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Baceno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Bacoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Badalucco",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Badesi",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Badia",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Badia Calavena",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Badia Pavese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Badia Polesine",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Badia Tedalda",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Badolato",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Bagaladi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Bagheria",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Bagnacavallo",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Bagnara Calabra",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Bagnara di Romagna",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Bagnaria",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Bagnaria Arsa",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Bagnasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bagnatica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bagni di Lucca",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Bagno a Ripoli",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Bagno di Romagna",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Bagnoli Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Bagnoli del Trigno",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Bagnoli di Sopra",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Bagnolo Cremasco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Bagnolo Mella",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bagnolo Piemonte",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bagnolo San Vito",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Bagnolo del Salento",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Bagnolo di Po",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Bagnolo in Piano",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Bagnone",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Bagnoregio",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Bagolino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Baia e Latina",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Baiano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Bairo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Baiso",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Bajardo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Balangero",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Baldichieri d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Baldissero Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Baldissero Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Baldissero d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Balestrate",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Balestrino",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Ballabio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Ballao",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Balme",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Balmuccia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Balocco",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Balsorano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Balvano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Balzola",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Banari",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Banchette",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Bannio Anzino",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Banzi",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Baone",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Baradili",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Baragiano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Baranello",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Barano d'Ischia",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Baranzate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Barasso",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Baratili San Pietro",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Barbania",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Barbara",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Barbarano Mossano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Barbarano Romano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Barbaresco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Barbariga",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Barbata",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Barberino Tavarnelle",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Barberino di Mugello",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Barbianello",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Barbiano",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Barbona",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Barcellona Pozzo di Gotto",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Barcis",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Bard",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Bardello con Malgesso e Bregano",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Bardi",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Bardineto",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Bardolino",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Bardonecchia",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Bareggio",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Barengo",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Baressa",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Barete",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Barga",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Bargagli",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Barge",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Barghe",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bari",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Bari Sardo",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Bariano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Baricella",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Barile",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Barisciano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Barlassina",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Barletta",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Barni",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Barolo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Barone Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Baronissi",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Barrafranca",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Barrali",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Barrea",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Barumini",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Barzago",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Barzana",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Barzano'",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Barzio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Basaluzzo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Bascape'",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Baschi",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Basciano",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Baselga di Pine'",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Baselice",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Basiano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Basico'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Basiglio",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Basiliano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Bassano Bresciano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bassano Romano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Bassano del Grappa",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Bassano in Teverina",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Bassiano",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Bassignana",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Bastia Mondovi'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bastia Umbra",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Bastida Pancarana",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Bastiglia",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Battaglia Terme",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Battifollo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Battipaglia",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Battuda",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Baucina",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Bauladu",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Baunei",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Baveno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Bedero Valcuvia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Bedizzole",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bedollo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Bedonia",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Bedulita",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bee",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Beinasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Beinette",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Belcastro",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Belfiore",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Belforte Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Belforte all'Isauro",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Belforte del Chienti",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Belgioioso",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Belgirate",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Bella",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Bellagio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Bellano",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Bellante",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Bellaria-Igea Marina",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Bellegra",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Bellino",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bellinzago Lombardo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Bellinzago Novarese",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Bellizzi",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Bellona",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Bellosguardo",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Belluno",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Bellusco",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Belmonte Calabro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Belmonte Castello",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Belmonte Mezzagno",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Belmonte Piceno",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Belmonte del Sannio",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Belmonte in Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Belpasso",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Belsito",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Belvedere Langhe",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Belvedere Marittimo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Belvedere Ostrense",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Belvedere di Spinello",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Belveglio",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Belvi'",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Bema",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Bene Lario",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Bene Vagienna",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Benestare",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Benetutti",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Benevello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Benevento",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Benna",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Bentivoglio",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Berbenno",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Berbenno di Valtellina",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Berceto",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Berchidda",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Beregazzo con Figliaro",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Bereguardo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Bergamasco",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Bergamo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bergantino",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Bergeggi",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Bergolo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Berlingo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bernalda",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Bernareggio",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Bernate Ticino",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Bernezzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bertinoro",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Bertiolo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Bertonico",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Berzano di San Pietro",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Berzano di Tortona",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Berzo Demo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Berzo Inferiore",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Berzo San Fermo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Besana in Brianza",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Besano",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Besate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Besenello",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Besenzone",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Besnate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Besozzo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Bessude",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Bettola",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Bettona",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Beura-Cardezza",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Bevagna",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Beverino",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Bevilacqua",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Biancavilla",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Bianchi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Bianco",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Biandrate",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Biandronno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Bianzano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bianze'",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Bianzone",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Biassono",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Bibbiano",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Bibbiena",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Bibbona",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Bibiana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Biccari",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Bicinicco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Bidoni'",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Biella",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Bienno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bieno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Bientina",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Binago",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Binasco",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Binetto",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Bioglio",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Bionaz",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Bione",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Birori",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Bisaccia",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Bisacquino",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Bisceglie",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Bisegna",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Bisenti",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Bisignano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Bistagno",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Bisuschio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Bitetto",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Bitonto",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Bitritto",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Bitti",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Bivona",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Bivongi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Bizzarone",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Bleggio Superiore",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Blello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Blera",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Blessagno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Blevio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Blufi",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Boara Pisani",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Bobbio",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Bobbio Pellice",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Boca",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Bocchigliero",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Boccioleto",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Bocenago",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Bodio Lomnago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Boffalora d'Adda",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Boffalora sopra Ticino",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Bogliasco",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Bognanco",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Bogogno",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Boissano",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Bojano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Bolano",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Bolgare",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bollate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Bollengo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Bologna",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Bolognano",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Bolognetta",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Bolognola",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Bolotana",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Bolsena",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Boltiere",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bolzano",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Bolzano Novarese",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Bolzano Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Bomarzo",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Bomba",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Bompensiere",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Bompietro",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Bomporto",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Bonarcado",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Bonassola",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Bonate Sopra",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bonate Sotto",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bonavigo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Bondeno",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Bondone",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Bonea",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Bonefro",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Bonemerse",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Bonifati",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Bonito",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Bonnanaro",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Bono",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Bonorva",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Bonvicino",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Borbona",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Borca di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Bordano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Bordighera",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Bordolano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Bore",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Boretto",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Borgarello",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Borgaro Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Borgetto",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Borghetto Lodigiano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Borghetto Santo Spirito",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Borghetto d'Arroscia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Borghetto di Borbera",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Borghetto di Vara",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Borghi",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Borgia",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Borgiallo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Borgio Verezzi",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Borgo Chiese",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Borgo Lares",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Borgo Mantovano",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Borgo Pace",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Borgo Priolo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Borgo San Dalmazzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Borgo San Giacomo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Borgo San Giovanni",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Borgo San Lorenzo",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Borgo San Martino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Borgo San Siro",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Borgo Ticino",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Borgo Tossignano",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Borgo Val di Taro",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Borgo Valbelluna",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Borgo Valsugana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Borgo Velino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Borgo Veneto",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Borgo Vercelli",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Borgo Virgilio",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Borgo a Mozzano",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Borgo d'Ale",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Borgo d'Anaunia",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Borgo di Terzo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Borgocarbonara",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Borgofranco d'Ivrea",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Borgolavezzaro",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Borgomale",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Borgomanero",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Borgomaro",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Borgomasino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Borgomezzavalle",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Borgone Susa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Borgonovo Val Tidone",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Borgoratto Alessandrino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Borgoratto Mormorolo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Borgoricco",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Borgorose",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Borgosatollo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Borgosesia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Bormida",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Bormio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Bornasco",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Borno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Boroneddu",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Borore",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Borrello",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Borriana",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Borso del Grappa",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Bortigali",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Bortigiadas",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Borutta",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Borzonasca",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Bosa",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Bosaro",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Boschi Sant'Anna",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Bosco Chiesanuova",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Bosco Marengo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Bosconero",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Boscoreale",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Boscotrecase",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Bosia",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bosio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Bosisio Parini",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Bosnasco",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Bossico",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bossolasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Botricello",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Botrugno",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Bottanuco",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Botticino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bottidda",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Bova",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Bova Marina",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Bovalino",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Bovegno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Boves",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bovezzo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Boville Ernica",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Bovino",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Bovisio-Masciago",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Bovolenta",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Bovolone",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Bozzole",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Bozzolo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Bra",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Bracca",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Bracciano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Bracigliano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Braies",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Brallo di Pregola",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Brancaleone",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Brandico",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Brandizzo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Branzi",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Braone",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Brebbia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Breda di Piave",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Breganze",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Bregnano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Brembate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Brembate di Sopra",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Brembio",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Breme",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Brendola",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Brenna",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Brennero",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Breno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Brenta",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Brentino Belluno",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Brentonico",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Brenzone sul Garda",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Brescello",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Brescia",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Bresimo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Bressana Bottarone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Bressanone",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Bressanvido",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Bresso",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Brezzo di Bedero",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Briaglia",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Briatico",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Bricherasio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Brienno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Brienza",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Briga Alta",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Briga Novarese",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Brignano Gera d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Brignano-Frascata",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Brindisi",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Brindisi Montagna",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Brinzio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Briona",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Brione",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Briosco",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Brisighella",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Brissago-Valtravaglia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Brissogne",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Brittoli",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Brivio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Broccostella",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Brogliano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Brognaturo",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Brolo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Brondello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Broni",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Bronte",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Bronzolo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Brossasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Brosso",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Brovello-Carpugnino",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Brozolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Brugherio",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Brugine",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Brugnato",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Brugnera",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Bruino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Brumano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Brunate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Brunello",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Brunico",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Bruno",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Brusaporto",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Brusasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Brusciano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Brusimpiano",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Brusnengo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Brusson",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Bruzolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Bruzzano Zeffirio",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Bubbiano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Bubbio",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Buccheri",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Bucchianico",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Bucciano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Buccinasco",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Buccino",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Bucine",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Budduso'",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Budoia",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Budoni",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Budrio",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Buggerru",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Buggiano",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Buglio in Monte",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Bugnara",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Buguggiate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Buja",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Bulciago",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Bulgarograsso",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Bultei",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Bulzi",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Buonabitacolo",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Buonalbergo",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Buonconvento",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Buonvicino",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Burago di Molgora",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Burcei",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Burgio",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Burgos",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Buriasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Burolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Buronzo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Busachi",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Busalla",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Busano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Busca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Buscate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Buscemi",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Buseto Palizzolo",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Busnago",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Bussero",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Busseto",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Bussi sul Tirino",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Busso",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Bussolengo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Bussoleno",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Busto Arsizio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Busto Garolfo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Butera",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Buti",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Buttapietra",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Buttigliera Alta",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Buttigliera d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Buttrio",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cabella Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cabiate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cabras",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Caccamo",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Caccuri",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Cadegliano-Viconago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cadelbosco di Sopra",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Cadeo",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Caderzone Terme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cadoneghe",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Cadorago",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cadrezzate con Osmate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Caerano di San Marco",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Cafasse",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Caggiano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Cagli",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Cagliari",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Caglio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cagnano Amiterno",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Cagnano Varano",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Caianello",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Caiazzo",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Caines",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Caino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Caiolo",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Cairano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Cairate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cairo Montenotte",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Caivano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Calabritto",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Calalzo di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Calamandrana",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Calamonaci",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Calangianus",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Calanna",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Calasca-Castiglione",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Calascibetta",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Calascio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Calasetta",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Calatabiano",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Calatafimi-Segesta",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Calcata",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Calceranica al Lago",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Calci",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Calciano",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Calcinaia",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Calcinate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Calcinato",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Calcio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Calco",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Caldaro sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Caldarola",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Calderara di Reno",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Caldes",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Caldiero",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Caldogno",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Caldonazzo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Calendasco",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Calenzano",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Calestano",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Calice Ligure",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Calice al Cornoviglio",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Calimera",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Calitri",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Calizzano",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Callabiana",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Calliano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Calliano Monferrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Calolziocorte",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Calopezzati",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Calosso",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Caloveto",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Caltabellotta",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Caltagirone",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Caltanissetta",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Caltavuturo",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Caltignaga",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Calto",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Caltrano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Calusco d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Caluso",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Calvagese della Riviera",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Calvanico",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Calvatone",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Calvello",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Calvene",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Calvenzano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Calvera",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Calvi",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Calvi Risorta",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Calvi dell'Umbria",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Calvignano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Calvignasco",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Calvisano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Calvizzano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Camagna Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Camaiore",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Camandona",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Camastra",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Cambiago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cambiano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cambiasca",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Camburzano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Camerana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Camerano",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Camerano Casasco",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Camerata Cornello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Camerata Nuova",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Camerata Picena",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Cameri",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Camerino",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Camerota",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Camigliano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Camini",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Camino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Camino al Tagliamento",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Camisano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Camisano Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Cammarata",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Camogli",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Campagna",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Campagna Lupia",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Campagnano di Roma",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Campagnatico",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Campagnola Cremasca",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Campagnola Emilia",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Campana",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Camparada",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Campegine",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Campello sul Clitunno",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Campertogno",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Campi Bisenzio",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Campi Salentina",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Campiglia Cervo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Campiglia Marittima",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Campiglia dei Berici",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Campiglione Fenile",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Campione d'Italia",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Campitello di Fassa",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Campli",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Campo Calabro",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Campo Ligure",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Campo San Martino",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Campo Tures",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Campo di Giove",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Campo di Trens",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Campo nell'Elba",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Campobasso",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Campobello di Licata",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Campobello di Mazara",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Campochiaro",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Campodarsego",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Campodenno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Campodimele",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Campodipietra",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Campodolcino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Campodoro",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Campofelice di Fitalia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Campofelice di Roccella",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Campofilone",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Campofiorito",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Campoformido",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Campofranco",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Campogalliano",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Campolattaro",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Campoli Appennino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Campoli del Monte Taburno",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Campolieto",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Campolongo Maggiore",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Campolongo Tapogliano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Campomaggiore",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Campomarino",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Campomorone",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Camponogara",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Campora",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Camporeale",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Camporgiano",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Camporosso",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Camporotondo Etneo",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Camporotondo di Fiastrone",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Camposampiero",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Camposano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Camposanto",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Campospinoso Albaredo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Campotosto",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Camugnano",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Canal San Bovo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Canale",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Canale Monterano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Canale d'Agordo",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Canaro",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Canazei",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cancellara",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Cancello ed Arnone",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Canda",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Candela",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Candelo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Candia Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Candia Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Candiana",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Candida",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Candidoni",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Candiolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Canegrate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Canelli",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Canepina",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Caneva",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Canicatti'",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Canicattini Bagni",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Canino",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Canischio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Canistro",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Canna",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cannalonga",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Cannara",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Cannero Riviera",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Canneto Pavese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Canneto sull'Oglio",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Cannobio",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Cannole",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Canolo",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Canonica d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Canosa Sannita",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Canosa di Puglia",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Canosio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Canossa",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Cansano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Cantagallo",
    provinceAbbr: "PO",
    province: "Prato",
  },
  {
    name: "Cantalice",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Cantalupa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cantalupo Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cantalupo in Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Cantalupo nel Sannio",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Cantarana",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cantello",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Canterano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cantiano",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Cantoira",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cantu'",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Canzano",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Canzo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Caorle",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Caorso",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Capaccio Paestum",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Capaci",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Capalbio",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Capannoli",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Capannori",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Capena",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Capergnanica",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Capestrano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Capiago Intimiano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Capistrano",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Capistrello",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Capitignano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Capizzi",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Capizzone",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Capo d'Orlando",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Capo di Ponte",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Capodimonte",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Capodrise",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Capoliveri",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Capolona",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Caponago",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Caporciano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Caposele",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Capoterra",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Capovalle",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cappadocia",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Cappella Cantone",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cappella Maggiore",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Cappella de' Picenardi",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cappelle sul Tavo",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Capracotta",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Capraia Isola",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Capraia e Limite",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Capralba",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Capranica",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Capranica Prenestina",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Caprarica di Lecce",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Caprarola",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Caprauna",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Caprese Michelangelo",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Caprezzo",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Capri",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Capri Leone",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Capriana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Capriano del Colle",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Capriata d'Orba",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Capriate San Gervasio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Capriati a Volturno",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Caprie",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Capriglia Irpina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Capriglio",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Caprile",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Caprino Bergamasco",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Caprino Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Capriolo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Capriva del Friuli",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Capua",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Capurso",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Caraffa del Bianco",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Caraffa di Catanzaro",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Caraglio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Caramagna Piemonte",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Caramanico Terme",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Carapelle",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Carapelle Calvisio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Carasco",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Carassai",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Carate Brianza",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Carate Urio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Caravaggio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Caravate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Caravino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Caravonica",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Carbognano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Carbonara Scrivia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Carbonara al Ticino",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Carbonara di Nola",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Carbonate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Carbone",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Carbonera",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Carbonia",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Carcare",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Carcoforo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Cardano al Campo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Carde'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cardedu",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Cardeto",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Cardinale",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Cardito",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Careggine",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Carema",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Carenno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Carentino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Careri",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Caresana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Caresanablot",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Carezzano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Carfizzi",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Cargeghe",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Cariati",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Carife",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Carignano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Carimate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Carinaro",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Carini",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Carinola",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Carisio",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Carisolo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Carlantino",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Carlazzo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Carlentini",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Carlino",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Carloforte",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Carlopoli",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Carmagnola",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Carmiano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Carmignano",
    provinceAbbr: "PO",
    province: "Prato",
  },
  {
    name: "Carmignano di Brenta",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Carnago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Carnate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Carobbio degli Angeli",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Carolei",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Carona",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Caronia",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Caronno Pertusella",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Caronno Varesino",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Carosino",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Carovigno",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Carovilli",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Carpaneto Piacentino",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Carpanzano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Carpegna",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Carpenedolo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Carpeneto",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Carpi",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Carpiano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Carpignano Salentino",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Carpignano Sesia",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Carpineti",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Carpineto Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Carpineto Sinello",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Carpineto della Nora",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Carpino",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Carpinone",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Carrara",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Carre'",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Carrega Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Carro",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Carrodano",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Carrosio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Carru'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Carsoli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Cartigliano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Cartignano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cartoceto",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Cartosio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cartura",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Carugate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Carugo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Carunchio",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Carvico",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Carzano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Casabona",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Casacalenda",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Casacanditella",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Casagiove",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Casal Cermelli",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Casal Velino",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Casal di Principe",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Casalanguida",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Casalattico",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Casalbeltrame",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Casalbordino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Casalbore",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Casalborgone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Casalbuono",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Casalbuttano ed Uniti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Casalciprano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Casalduni",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Casale Corte Cerro",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Casale Cremasco-Vidolasco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Casale Litta",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Casale Marittimo",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Casale Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Casale di Scodosia",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Casale sul Sile",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Casalecchio di Reno",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Casaleggio Boiro",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Casaleggio Novara",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Casaleone",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Casaletto Ceredano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Casaletto Lodigiano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Casaletto Spartano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Casaletto Vaprio",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Casaletto di Sopra",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Casalfiumanese",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Casalgrande",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Casalgrasso",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Casali del Manco",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Casalincontrada",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Casalino",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Casalmaggiore",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Casalmaiocco",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Casalmorano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Casalmoro",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Casalnoceto",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Casalnuovo Monterotaro",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Casalnuovo di Napoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Casaloldo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Casalpusterlengo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Casalromano",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Casalserugo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Casaluce",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Casalvecchio Siculo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Casalvecchio di Puglia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Casalvieri",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Casalvolone",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Casalzuigno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Casamarciano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Casamassima",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Casamicciola Terme",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Casandrino",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Casanova Elvo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Casanova Lerrone",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Casanova Lonati",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Casape",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Casapesenna",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Casapinta",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Casaprota",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Casapulla",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Casarano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Casargo",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Casarile",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Casarsa della Delizia",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Casarza Ligure",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Casasco",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Casatenovo",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Casatisma",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Casavatore",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Casazza",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cascia",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Casciago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Casciana Terme Lari",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Cascina",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Cascinette d'Ivrea",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Casei Gerola",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Caselette",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Casella",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Caselle Landi",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Caselle Lurani",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Caselle Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Caselle in Pittari",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Caserta",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Casier",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Casignana",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Casina",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Casirate d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Caslino d'Erba",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Casnate con Bernate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Casnigo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Casola Valsenio",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Casola di Napoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Casola in Lunigiana",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Casole d'Elsa",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Casoli",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Casorate Primo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Casorate Sempione",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Casorezzo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Casoria",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Casorzo Monferrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Casperia",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Caspoggio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Cassacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cassago Brianza",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cassano Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Cassano Magnago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cassano Spinola",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cassano Valcuvia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cassano all'Ionio",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cassano d'Adda",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cassano delle Murge",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Cassaro",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Cassiglio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cassina Rizzardi",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cassina Valsassina",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cassina de' Pecchi",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cassinasco",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cassine",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cassinelle",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cassinetta di Lugagnano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cassino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Cassola",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Cassolnovo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Castagnaro",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Castagneto Carducci",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Castagneto Po",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Castagnito",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castagnole Monferrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castagnole Piemonte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Castagnole delle Lanze",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castana",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Castano Primo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Casteggio",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Castegnato",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Castegnero",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Castel Baronia",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Castel Boglione",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castel Bolognese",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Castel Campagnano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Castel Castagna",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Castel Condino",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Castel Focognano",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Castel Frentano",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Castel Gabbiano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Castel Gandolfo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Castel Giorgio",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Castel Goffredo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Castel Guelfo di Bologna",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castel Ivano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Castel Madama",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Castel Maggiore",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castel Mella",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Castel Morrone",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Castel Ritaldi",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Castel Rocchero",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castel Rozzone",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Castel San Giorgio",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Castel San Giovanni",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Castel San Lorenzo",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Castel San Niccolo'",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Castel San Pietro Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Castel San Pietro Terme",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castel San Vincenzo",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Castel Sant'Angelo",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Castel Sant'Elia",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Castel Viscardo",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Castel Vittorio",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Castel Volturno",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Castel d'Aiano",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castel d'Ario",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Castel d'Azzano",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Castel del Giudice",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Castel del Monte",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Castel del Piano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Castel del Rio",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castel di Casio",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castel di Ieri",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Castel di Iudica",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Castel di Lama",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Castel di Lucio",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Castel di Sangro",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Castel di Sasso",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Castel di Tora",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Castelbaldo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Castelbelforte",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Castelbellino",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Castelbello-Ciardes",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Castelbianco",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Castelbottaccio",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Castelbuono",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Castelcivita",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Castelcovati",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Castelcucco",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Casteldaccia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Casteldelci",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Casteldelfino",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Casteldidone",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Castelfidardo",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Castelfiorentino",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Castelforte",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Castelfranci",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Castelfranco Emilia",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Castelfranco Piandisco'",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Castelfranco Veneto",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Castelfranco di Sotto",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Castelfranco in Miscano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Castelgerundo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Castelgomberto",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Castelgrande",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Castelguglielmo",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Castelguidone",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Castell'Alfero",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castell'Arquato",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Castell'Azzara",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Castell'Umberto",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Castellabate",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Castellafiume",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Castellalto",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Castellammare del Golfo",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Castellammare di Stabia",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Castellamonte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Castellana Grotte",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Castellana Sicula",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Castellaneta",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Castellania Coppi",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castellanza",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Castellar Guidobono",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castellarano",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Castellaro",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Castellazzo Bormida",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castellazzo Novarese",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Castelleone",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Castelleone di Suasa",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Castellero",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castelletto Cervo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Castelletto Merli",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castelletto Molina",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castelletto Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castelletto Stura",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castelletto Uzzone",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castelletto d'Erro",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castelletto d'Orba",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castelletto di Branduzzo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Castelletto sopra Ticino",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Castelli",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Castelli Calepio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Castellina Marittima",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Castellina in Chianti",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Castellinaldo d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castellino Tanaro",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castellino del Biferno",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Castelliri",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Castello Cabiaglio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Castello Tesino",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Castello d'Agogna",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Castello d'Argile",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castello del Matese",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Castello dell'Acqua",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Castello di Annone",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castello di Brianza",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Castello di Cisterna",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Castello di Godego",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Castello-Molina di Fiemme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Castellucchio",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Castelluccio Inferiore",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Castelluccio Superiore",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Castelluccio Valmaggiore",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Castelluccio dei Sauri",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Castelmagno",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castelmarte",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Castelmassa",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Castelmauro",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Castelmezzano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Castelmola",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Castelnovetto",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Castelnovo Bariano",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Castelnovo del Friuli",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Castelnovo di Sotto",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Castelnovo ne' Monti",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Castelnuovo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Castelnuovo Belbo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castelnuovo Berardenga",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Castelnuovo Bocca d'Adda",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Castelnuovo Bormida",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castelnuovo Bozzente",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Castelnuovo Calcea",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castelnuovo Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Castelnuovo Don Bosco",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Castelnuovo Magra",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Castelnuovo Nigra",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Castelnuovo Parano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Castelnuovo Rangone",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Castelnuovo Scrivia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Castelnuovo del Garda",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Castelnuovo della Daunia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Castelnuovo di Ceva",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castelnuovo di Conza",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Castelnuovo di Farfa",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Castelnuovo di Garfagnana",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Castelnuovo di Porto",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Castelnuovo di Val di Cecina",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Castelpagano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Castelpetroso",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Castelpizzuto",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Castelplanio",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Castelpoto",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Castelraimondo",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Castelrotto",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Castelsantangelo sul Nera",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Castelsaraceno",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Castelsardo",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Castelseprio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Castelsilano",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Castelspina",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Casteltermini",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Castelveccana",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Castelvecchio Calvisio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Castelvecchio Subequo",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Castelvecchio di Rocca Barbena",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Castelvenere",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Castelverde",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Castelverrino",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Castelvetere in Val Fortore",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Castelvetere sul Calore",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Castelvetrano",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Castelvetro Piacentino",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Castelvetro di Modena",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Castelvisconti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Castenaso",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castenedolo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Castiadas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Castiglion Fibocchi",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Castiglion Fiorentino",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Castiglione Chiavarese",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Castiglione Cosentino",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Castiglione Falletto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castiglione Messer Marino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Castiglione Messer Raimondo",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Castiglione Olona",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Castiglione Tinella",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castiglione Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Castiglione a Casauria",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Castiglione d'Adda",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Castiglione d'Orcia",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Castiglione dei Pepoli",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Castiglione del Genovesi",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Castiglione del Lago",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Castiglione della Pescaia",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Castiglione delle Stiviere",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Castiglione di Garfagnana",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Castiglione di Sicilia",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Castiglione in Teverina",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Castignano",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Castilenti",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Castino",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Castione Andevenno",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Castione della Presolana",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Castions di Strada",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Castiraga Vidardo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Casto",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Castorano",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Castrezzato",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Castri di Lecce",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Castrignano de' Greci",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Castrignano del Capo",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Castro",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Castro",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Castro dei Volsci",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Castrocaro Terme e Terra del Sole",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Castrocielo",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Castrofilippo",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Castrolibero",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Castronno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Castronovo di Sicilia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Castronuovo di Sant'Andrea",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Castropignano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Castroreale",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Castroregio",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Castrovillari",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Catania",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Catanzaro",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Catenanuova",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Catignano",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Cattolica",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Cattolica Eraclea",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Caulonia",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Cautano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Cava Manara",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cava de' Tirreni",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Cavaglia'",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Cavaglietto",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Cavaglio d'Agogna",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Cavagnolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cavaion Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Cavalese",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cavallerleone",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cavallermaggiore",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cavallino",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Cavallino-Treporti",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Cavallirio",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Cavareno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cavargna",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cavaria con Premezzo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cavarzere",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Cavaso del Tomba",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Cavasso Nuovo",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Cavatore",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cavazzo Carnico",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cave",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cavedago",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cavedine",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cavenago d'Adda",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Cavenago di Brianza",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Cavernago",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cavezzo",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Cavizzana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cavour",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cavriago",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Cavriana",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Cavriglia",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Cazzago Brabbia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cazzago San Martino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cazzano Sant'Andrea",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cazzano di Tramigna",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Ceccano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Cecima",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cecina",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Cedegolo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cedrasco",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Cefala' Diana",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Cefalu'",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Ceggia",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Ceglie Messapica",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Celano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Celenza Valfortore",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Celenza sul Trigno",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Celico",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cella Dati",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cella Monte",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cellamare",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Cellara",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cellarengo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cellatica",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Celle Enomondo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Celle Ligure",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Celle di Bulgheria",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Celle di Macra",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Celle di San Vito",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Celleno",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Cellere",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Cellino Attanasio",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Cellino San Marco",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Cellio con Breia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Cellole",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Cembra Lisignago",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cenadi",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Cenate Sopra",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cenate Sotto",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cencenighe Agordino",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Cene",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ceneselli",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Cengio",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Centallo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cento",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Centola",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Centrache",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Centro Valle Intelvi",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Centuripe",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Cepagatti",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Ceppaloni",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Ceppo Morelli",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Ceprano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Cerami",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Ceranesi",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Cerano",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Cerano d'Intelvi",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Ceranova",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Ceraso",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Cercemaggiore",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Cercenasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cercepiccola",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Cerchiara di Calabria",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cerchio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Cercino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Cercivento",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cercola",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Cerda",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Cerea",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Ceregnano",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Cerenzia",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Ceres",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ceresara",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Cereseto",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ceresole Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Ceresole Reale",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cerete",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ceretto Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cergnago",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Ceriale",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Ceriana",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Ceriano Laghetto",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Cerignale",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Cerignola",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Cerisano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cermenate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cermes",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Cermignano",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Cernobbio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cernusco Lombardone",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cernusco sul Naviglio",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cerreto Grue",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cerreto Guidi",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Cerreto Laziale",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cerreto Sannita",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Cerreto d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cerreto d'Esi",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Cerreto di Spoleto",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Cerretto Langhe",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cerrina Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cerrione",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Cerro Maggiore",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cerro Tanaro",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cerro Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Cerro al Lambro",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cerro al Volturno",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Cersosimo",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Certaldo",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Certosa di Pavia",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cerva",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Cervara di Roma",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cervarese Santa Croce",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Cervaro",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Cervasca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cervatto",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Cerveno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cervere",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cervesina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cerveteri",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cervia",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Cervicati",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cervignano d'Adda",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Cervignano del Friuli",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cervinara",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Cervino",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Cervo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Cerzeto",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cesa",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Cesana Brianza",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cesana Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cesano Boscone",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cesano Maderno",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Cesara",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Cesaro'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Cesate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cesena",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Cesenatico",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Cesinali",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Cesio",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Cesiomaggiore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Cessalto",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Cessaniti",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Cessapalombo",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Cessole",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cetara",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Ceto",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cetona",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Cetraro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Ceva",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cevo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Challand-Saint-Anselme",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Challand-Saint-Victor",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Chambave",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Chamois",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Champdepraz",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Champorcher",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Charvensod",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Chatillon",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Cherasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cheremule",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Chialamberto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Chiampo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Chianche",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Chianciano Terme",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Chianni",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Chianocco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Chiaramonte Gulfi",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Chiaramonti",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Chiarano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Chiaravalle",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Chiaravalle Centrale",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Chiari",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Chiaromonte",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Chiauci",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Chiavari",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Chiavenna",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Chiaverano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Chienes",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Chieri",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Chies d'Alpago",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Chiesa in Valmalenco",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Chiesanuova",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Chiesina Uzzanese",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Chieti",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Chieuti",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Chieve",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Chignolo Po",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Chignolo d'Isola",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Chioggia",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Chiomonte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Chions",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Chiopris-Viscone",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Chitignano",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Chiuduno",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Chiuppano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Chiuro",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Chiusa",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Chiusa Sclafani",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Chiusa di Pesio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Chiusa di San Michele",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Chiusaforte",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Chiusanico",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Chiusano d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Chiusano di San Domenico",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Chiusavecchia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Chiusdino",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Chiusi",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Chiusi della Verna",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Chivasso",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ciampino",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cianciana",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Cibiana di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Cicagna",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Cicala",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Cicciano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Cicerale",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Ciciliano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cicognolo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Ciconio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cigliano",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Ciglie'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cigognola",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cigole",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cilavegna",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cimadolmo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Cimbergo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cimina'",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Ciminna",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Cimitile",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Cimolais",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Cimone",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cinaglio",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cineto Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Cingia de' Botti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cingoli",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Cinigiano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Cinisello Balsamo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cinisi",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Cino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Cinquefrondi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Cintano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cinte Tesino",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cinto Caomaggiore",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Cinto Euganeo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Cinzano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ciorlano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Cipressa",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Circello",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Cirie'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cirigliano",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Cirimido",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Ciro'",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Ciro' Marina",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Cis",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cisano Bergamasco",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cisano sul Neva",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Ciserano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cislago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cisliano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cison di Valmarino",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Cissone",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cisterna d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cisterna di Latina",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Cisternino",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Citerna",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Citta' Sant'Angelo",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Citta' della Pieve",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Citta' di Castello",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Cittadella",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Cittaducale",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Cittanova",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Cittareale",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Cittiglio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Civate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Civezza",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Civezzano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Civiasco",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Cividale del Friuli",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cividate Camuno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cividate al Piano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Civita",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Civita Castellana",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Civita d'Antino",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Civitacampomarano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Civitaluparella",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Civitanova Marche",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Civitanova del Sannio",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Civitaquana",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Civitavecchia",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Civitella Alfedena",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Civitella Casanova",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Civitella Messer Raimondo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Civitella Paganico",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Civitella Roveto",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Civitella San Paolo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Civitella d'Agliano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Civitella del Tronto",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Civitella di Romagna",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Civitella in Val di Chiana",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Civo",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Claino con Osteno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Claut",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Clauzetto",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Clavesana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Claviere",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cles",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Cleto",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Clivio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Clusone",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Coassolo Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Coazze",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Coazzolo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Coccaglio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cocconato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cocquio-Trevisago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cocullo",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Codevigo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Codevilla",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Codigoro",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Codogne'",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Codogno",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Codroipo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Codrongianos",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Coggiola",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Cogliate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Cogne",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Cogoleto",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Cogollo del Cengio",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Cogorno",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Colazza",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Colceresa",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Colere",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Colfelice",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Coli",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Colico",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Collalto Sabino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Collarmele",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Collazzone",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Colle Brianza",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Colle San Magno",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Colle Sannita",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Colle Santa Lucia",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Colle Umberto",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Colle d'Anchise",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Colle di Tora",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Colle di Val d'Elsa",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Collebeato",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Collecchio",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Collecorvino",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Colledara",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Colledimacine",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Colledimezzo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Colleferro",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Collegiove",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Collegno",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Collelongo",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Collepardo",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Collepasso",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Collepietro",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Colleretto Castelnuovo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Colleretto Giacosa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Collesalvetti",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Collesano",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Colletorto",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Collevecchio",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Colli Verdi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Colli a Volturno",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Colli al Metauro",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Colli del Tronto",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Colli sul Velino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Colliano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Collinas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Collio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Collobiano",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Colloredo di Monte Albano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Colmurano",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Colobraro",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Cologna Veneta",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Cologne",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cologno Monzese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cologno al Serio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Colognola ai Colli",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Colonna",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Colonnella",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Colonno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Colorina",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Colorno",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Colosimi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Colturano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Colverde",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Colzate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Comabbio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Comacchio",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Comano",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Comano Terme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Comazzo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Comeglians",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Comelico Superiore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Comerio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Comezzano-Cizzago",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Comignago",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Comiso",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Comitini",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Comiziano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Commessaggio",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Commezzadura",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Como",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Compiano",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Comun Nuovo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Comunanza",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Cona",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Conca Casale",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Conca dei Marini",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Conca della Campania",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Concamarise",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Concerviano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Concesio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Concordia Sagittaria",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Concordia sulla Secchia",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Concorezzo",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Condofuri",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Condove",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Condro'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Conegliano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Confienza",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Configni",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Conflenti",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Coniolo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Conselice",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Conselve",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Conta'",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Contessa Entellina",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Contigliano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Contrada",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Controguerra",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Controne",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Contursi Terme",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Conversano",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Conza della Campania",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Conzano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Copertino",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Copiano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Copparo",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Corana",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Corato",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Corbara",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Corbetta",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Corbola",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Corchiano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Corciano",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Cordenons",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Cordignano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Cordovado",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Coreglia Antelminelli",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Coreglia Ligure",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Coreno Ausonio",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Corfinio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Cori",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Coriano",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Corigliano d'Otranto",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Corigliano-Rossano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Corinaldo",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Corio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Corleone",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Corleto Monforte",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Corleto Perticara",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Cormano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cormons",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Corna Imagna",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cornalba",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cornale e Bastida",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cornaredo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cornate d'Adda",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Cornedo Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Cornedo all'Isarco",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Cornegliano Laudense",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Corneliano d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Corniglio",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Corno Giovine",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Corno di Rosazzo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cornovecchio",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Cornuda",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Correggio",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Correzzana",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Correzzola",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Corrido",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Corridonia",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Corropoli",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Corsano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Corsico",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Corsione",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cortaccia sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Cortale",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Cortandone",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cortanze",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cortazzone",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Corte Brugnatella",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Corte Franca",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Corte Palasio",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Corte de' Cortesi con Cignone",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Corte de' Frati",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cortemaggiore",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Cortemilia",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Corteno Golgi",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Cortenova",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cortenuova",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Corteolona e Genzone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Cortiglione",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cortina d'Ampezzo",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Cortina sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Cortino",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Cortona",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Corvara",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Corvara in Badia",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Corvino San Quirico",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Corzano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Coseano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Cosenza",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cosio Valtellino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Cosio d'Arroscia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Cosoleto",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Cossano Belbo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cossano Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cossato",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Cosseria",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Cossignano",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Cossogno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Cossoine",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Cossombrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Costa Masnaga",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Costa Serina",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Costa Valle Imagna",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Costa Vescovato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Costa Volpino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Costa de' Nobili",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Costa di Mezzate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Costa di Rovigo",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Costabissara",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Costacciaro",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Costanzana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Costarainera",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Costermano sul Garda",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Costigliole Saluzzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Costigliole d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cotignola",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Cotronei",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Cottanello",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Courmayeur",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Covo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Cozzo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Craco",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Crandola Valsassina",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cravagliana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Cravanzana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Craveggia",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Creazzo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Crecchio",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Credaro",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Credera Rubbiano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Crema",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cremella",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cremenaga",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cremeno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Cremia",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cremolino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Cremona",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cremosano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Crescentino",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Crespadoro",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Crespiatica",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Crespina Lorenzana",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Crespino",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Cressa",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Crevacuore",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Crevalcore",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Crevoladossola",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Crispano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Crispiano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Crissolo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Crocefieschi",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Crocetta del Montello",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Crodo",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Crognaleto",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Cropalati",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Cropani",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Crosia",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Crosio della Valle",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Crotone",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Crotta d'Adda",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Crova",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Croviana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Crucoli",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Cuasso al Monte",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cuccaro Vetere",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Cucciago",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cuceglio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cuggiono",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cugliate-Fabiasco",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cuglieri",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Cugnoli",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Cumiana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cumignano sul Naviglio",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Cunardo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cuneo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Cunico",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Cuorgne'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Cupello",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Cupra Marittima",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Cupramontana",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Cura Carpignano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Curcuris",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Cureggio",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Curiglia con Monteviasco",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Curinga",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Curino",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Curno",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Curon Venosta",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Cursi",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Curtarolo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Curtatone",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Curti",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Cusago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cusano Milanino",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Cusano Mutri",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Cusino",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Cusio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Custonaci",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Cutro",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Cutrofiano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Cuveglio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Cuvio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Dairago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Dalmine",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Dambel",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Danta di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Darfo Boario Terme",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Dasa'",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Davagna",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Daverio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Davoli",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Dazio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Decimomannu",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Decimoputzu",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Decollatura",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Dego",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Deiva Marina",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Delebio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Delia",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Delianuova",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Deliceto",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Dello",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Demonte",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Denice",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Denno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Dernice",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Derovere",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Deruta",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Dervio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Desana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Desenzano del Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Desio",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Desulo",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Diamante",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Diano Arentino",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Diano Castello",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Diano Marina",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Diano San Pietro",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Diano d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Dicomano",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Dignano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Dimaro Folgarida",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Dinami",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Dipignano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Diso",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Divignano",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Dizzasco",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Dobbiaco",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Doberdo' del Lago",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Dogliani",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Dogliola",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Dogna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Dolce'",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Dolceacqua",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Dolcedo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Dolegna del Collio",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Dolianova",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Dolo",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Dolzago",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Domanico",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Domaso",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Domegge di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Domicella",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Domodossola",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Domus de Maria",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Domusnovas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Donato",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Dongo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Donnas",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Donori",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Dorgali",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Dorio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Dormelletto",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Dorno",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Dorzano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Dosolo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Dossena",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Dosso del Liro",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Doues",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Dovadola",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Dovera",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Dozza",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Dragoni",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Drapia",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Drena",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Drenchia",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Dresano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Dro",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Dronero",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Druento",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Druogno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Dualchi",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Dubino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Due Carrare",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Dueville",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Dugenta",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Duino Aurisina",
    provinceAbbr: "TS",
    province: "Trieste",
  },
  {
    name: "Dumenza",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Duno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Durazzano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Duronia",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Dusino San Michele",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Eboli",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Edolo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Egna",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Elice",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Elini",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Ello",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Elmas",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Elva",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Emare'se",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Empoli",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Endine Gaiano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Enego",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Enemonzo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Enna",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Entracque",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Entratico",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Envie",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Episcopia",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Eraclea",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Erba",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Erbe'",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Erbezzo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Erbusco",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Erchie",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Ercolano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Erice",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Erli",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Erto e Casso",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Erula",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Erve",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Esanatoglia",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Escalaplano",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Escolca",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Esine",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Esino Lario",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Esperia",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Esporlatu",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Este",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Esterzili",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Etroubles",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Eupilio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Exilles",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Fabbrica Curone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Fabbriche di Vergemoli",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Fabbrico",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Fabriano",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Fabrica di Roma",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Fabrizia",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Fabro",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Faedis",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Faedo Valtellino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Faenza",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Faeto",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Fagagna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Faggeto Lario",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Faggiano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Fagnano Alto",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Fagnano Castello",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Fagnano Olona",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Fai della Paganella",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Faicchio",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Falcade",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Falciano del Massico",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Falconara Albanese",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Falconara Marittima",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Falcone",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Faleria",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Falerna",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Falerone",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Fallo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Faloppio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Falvaterra",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Falzes",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Fanano",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Fanna",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Fano",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Fano Adriano",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Fara Filiorum Petri",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Fara Gera d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fara Novarese",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Fara Olivana con Sola",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fara San Martino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Fara Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Fara in Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Fardella",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Farigliano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Farindola",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Farini",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Farnese",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Farra d'Isonzo",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Farra di Soligo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Fasano",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Fascia",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Fauglia",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Faule",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Favale di Malvaro",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Favara",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Favignana",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Favria",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Fe'nis",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Feisoglio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Feletto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Felino",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Felitto",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Felizzano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Feltre",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Fenegro'",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Fenestrelle",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ferentillo",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Ferentino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Ferla",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Fermignano",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Fermo",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Ferno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Feroleto Antico",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Feroleto della Chiesa",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Ferrandina",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Ferrara",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Ferrara di Monte Baldo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Ferrazzano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Ferrera Erbognone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Ferrera di Varese",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Ferrere",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Ferriere",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Ferruzzano",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Fiamignano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Fiano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Fiano Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Fiastra",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Fiave'",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ficarazzi",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Ficarolo",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Ficarra",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Ficulle",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Fidenza",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Fie' allo Sciliar",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Fierozzo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Fiesco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Fiesole",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Fiesse",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Fiesso Umbertiano",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Fiesso d'Artico",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Figino Serenza",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Figline Vegliaturo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Figline e Incisa Valdarno",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Filacciano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Filadelfia",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Filago",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Filandari",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Filattiera",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Filettino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Filetto",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Filiano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Filighera",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Filignano",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Filogaso",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Filottrano",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Finale Emilia",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Finale Ligure",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Fino Mornasco",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Fino del Monte",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fiorano Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Fiorano Modenese",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Fiorano al Serio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fiorenzuola d'Arda",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Firenze",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Firenzuola",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Firmo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Fiscaglia",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Fisciano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Fiuggi",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Fiumalbo",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Fiumara",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Fiume Veneto",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Fiumedinisi",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Fiumefreddo Bruzio",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Fiumefreddo di Sicilia",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Fiumicello Villa Vicentina",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Fiumicino",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Fiuminata",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Fivizzano",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Flaibano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Flero",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Floresta",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Floridia",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Florinas",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Flumeri",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Fluminimaggiore",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Flussio",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Fobello",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Foggia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Foglianise",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Fogliano Redipuglia",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Foglizzo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Foiano della Chiana",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Foiano di Val Fortore",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Folgaria",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Folignano",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Foligno",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Follina",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Follo",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Follonica",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Fombio",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Fondachelli-Fantina",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Fondi",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Fonni",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Fontainemore",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Fontana Liri",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Fontanafredda",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Fontanarosa",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Fontanelice",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Fontanella",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fontanellato",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Fontanelle",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Fontaneto d'Agogna",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Fontanetto Po",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Fontanigorda",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Fontanile",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Fontaniva",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Fonte",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Fonte Nuova",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Fontecchio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Fontechiari",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Fontegreca",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Fonteno",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fontevivo",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Fonzaso",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Foppolo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Forano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Force",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Forchia",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Forcola",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Fordongianus",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Forenza",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Foresto Sparso",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Forgaria nel Friuli",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Forino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Forio",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Forli'",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Forli' del Sannio",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Forlimpopoli",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Formazza",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Formello",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Formia",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Formicola",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Formigara",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Formigine",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Formigliana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Fornace",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Fornelli",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Forni Avoltri",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Forni di Sopra",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Forni di Sotto",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Forno Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Fornovo San Giovanni",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fornovo di Taro",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Forte dei Marmi",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Fortezza",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Fortunago",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Forza d'Agro'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Fosciandora",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Fosdinovo",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Fossa",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Fossacesia",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Fossalta di Piave",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Fossalta di Portogruaro",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Fossalto",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Fossano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Fossato Serralta",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Fossato di Vico",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Fosso'",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Fossombrone",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Foza",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Frabosa Soprana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Frabosa Sottana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Fraconalto",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Fragagnano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Fragneto Monforte",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Fragneto l'Abate",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Fraine",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Framura",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Francavilla Angitola",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Francavilla Bisio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Francavilla Fontana",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Francavilla Marittima",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Francavilla al Mare",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Francavilla d'Ete",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Francavilla di Sicilia",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Francavilla in Sinni",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Francica",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Francofonte",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Francolise",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Frascaro",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Frascarolo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Frascati",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Frascineto",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Frassilongo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Frassinelle Polesine",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Frassinello Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Frassineto Po",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Frassinetto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Frassino",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Frassinoro",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Frasso Sabino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Frasso Telesino",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Fratta Polesine",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Fratta Todina",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Frattamaggiore",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Frattaminore",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Fratte Rosa",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Frazzano'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Fregona",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Fresagrandinaria",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Fresonara",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Frigento",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Frignano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Frinco",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Frisa",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Frisanco",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Front",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Frontino",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Frontone",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Frosinone",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Frosolone",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Frossasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Frugarolo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Fubine Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Fucecchio",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Fuipiano Valle Imagna",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Fumane",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Fumone",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Funes",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Furci",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Furci Siculo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Furnari",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Furore",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Furtei",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Fuscaldo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Fusignano",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Fusine",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Futani",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Gabbioneta-Binanuova",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Gabiano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Gabicce Mare",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Gaby",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Gadesco-Pieve Delmona",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Gadoni",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Gaeta",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Gaggi",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Gaggiano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Gaggio Montano",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Gaglianico",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Gagliano Aterno",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Gagliano Castelferrato",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Gagliano del Capo",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Gagliato",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Gagliole",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Gaiarine",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Gaiba",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Gaiola",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Gaiole in Chianti",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Gairo",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Gais",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Galati Mamertino",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Galatina",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Galatone",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Galatro",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Galbiate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Galeata",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Galgagnano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Gallarate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gallese",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Galliate",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Galliate Lombardo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Galliavola",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Gallicano",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Gallicano nel Lazio",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Gallicchio",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Galliera",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Galliera Veneta",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Gallinaro",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Gallio",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Gallipoli",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Gallo Matese",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Gallodoro",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Galluccio",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Galtelli'",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Galzignano Terme",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Gamalero",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Gambara",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Gambarana",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Gambasca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Gambassi Terme",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Gambatesa",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Gambellara",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Gamberale",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Gambettola",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Gambolo'",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Gandellino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gandino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gandosso",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gangi",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Garaguso",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Garbagna",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Garbagna Novarese",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Garbagnate Milanese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Garbagnate Monastero",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Garda",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Gardone Riviera",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Gardone Val Trompia",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Garessio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Gargallo",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Gargazzone",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Gargnano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Garlasco",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Garlate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Garlenda",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Garniga Terme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Garzeno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Garzigliana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Gasperina",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Gassino Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Gattatico",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Gatteo",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Gattico-Veruno",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Gattinara",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Gavardo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Gavello",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Gaverina Terme",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gavi",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Gavignano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Gavirate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gavoi",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Gavorrano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Gazoldo degli Ippoliti",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Gazzada Schianno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gazzaniga",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gazzo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Gazzo Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Gazzola",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Gazzuolo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Gela",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Gemmano",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Gemona del Friuli",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Gemonio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Genazzano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Genga",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Genivolta",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Genola",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Genoni",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Genova",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Genuri",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Genzano di Lucania",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Genzano di Roma",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Gera Lario",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Gerace",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Geraci Siculo",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Gerano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Gerenzago",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Gerenzano",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gergei",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Germagnano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Germagno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Germignaga",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gerocarne",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Gerola Alta",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Gerre de' Caprioli",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Gesico",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Gessate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Gessopalena",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Gesturi",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Gesualdo",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Ghedi",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Ghemme",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Ghiffa",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Ghilarza",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Ghisalba",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ghislarengo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Giacciano con Baruchella",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Giaglione",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Gianico",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Giano Vetusto",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Giano dell'Umbria",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Giardinello",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Giardini-Naxos",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Giarole",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Giarratana",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Giarre",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Giave",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Giaveno",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Giavera del Montello",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Giba",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Gibellina",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Gifflenga",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Giffone",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Giffoni Sei Casali",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Giffoni Valle Piana",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Gignese",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Gignod",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Gildone",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Gimigliano",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Ginestra",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Ginestra degli Schiavoni",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Ginosa",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Gioi",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Gioia Sannitica",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Gioia Tauro",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Gioia dei Marsi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Gioia del Colle",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Gioiosa Ionica",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Gioiosa Marea",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Giove",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Giovinazzo",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Giovo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Girasole",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Girifalco",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Gissi",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Giuggianello",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Giugliano in Campania",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Giuliana",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Giuliano Teatino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Giuliano di Roma",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Giulianova",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Giungano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Giurdignano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Giussago",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Giussano",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Giustenice",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Giustino",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Giusvalla",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Givoletto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Gizzeria",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Glorenza",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Godega di Sant'Urbano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Godiasco Salice Terme",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Godrano",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Goito",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Golasecca",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Golferenzo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Golfo Aranci",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Gombito",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Gonars",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Goni",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Gonnesa",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Gonnoscodina",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Gonnosfanadiga",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Gonnosno'",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Gonnostramatza",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Gonzaga",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Gordona",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Gorga",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Gorgo al Monticano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Gorgoglione",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Gorgonzola",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Goriano Sicoli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Gorizia",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Gorla Maggiore",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gorla Minore",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gorlago",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gorle",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gornate Olona",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Gorno",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Goro",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Gorreto",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Gorzegno",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Gosaldo",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Gossolengo",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Gottasecca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Gottolengo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Govone",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Gozzano",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Gradara",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Gradisca d'Isonzo",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Grado",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Gradoli",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Graffignana",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Graffignano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Graglia",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Gragnano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Gragnano Trebbiense",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Grammichele",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Grana Monferrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Granarolo dell'Emilia",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Grandate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Grandola ed Uniti",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Graniti",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Granozzo con Monticello",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Grantola",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Grantorto",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Granze",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Grassano",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Grassobbio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Gratteri",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Gravedona ed Uniti",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Gravellona Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Gravellona Toce",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Gravere",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Gravina di Catania",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Gravina in Puglia",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Grazzanise",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Grazzano Badoglio",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Greccio",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Greci",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Greggio",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Gremiasco",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Gressan",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Gressoney-La-Trinite'",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Gressoney-Saint-Jean",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Greve in Chianti",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Grezzago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Grezzana",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Griante",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Gricignano di Aversa",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Grignasco",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Grigno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Grimacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Grimaldi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Grinzane Cavour",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Grisignano di Zocco",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Grisolia",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Grizzana Morandi",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Grognardo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Gromo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Grondona",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Grone",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Grontardo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Gropello Cairoli",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Gropparello",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Groscavallo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Grosio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Grosotto",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Grosseto",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Grosso",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Grottaferrata",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Grottaglie",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Grottaminarda",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Grottammare",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Grottazzolina",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Grotte",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Grotte di Castro",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Grotteria",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Grottole",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Grottolella",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Gruaro",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Grugliasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Grumello Cremonese ed Uniti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Grumello del Monte",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Grumento Nova",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Grumo Appula",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Grumo Nevano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Grumolo delle Abbadesse",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Guagnano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Gualdo",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Gualdo Cattaneo",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Gualdo Tadino",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Gualtieri",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Gualtieri Sicamino'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Guamaggiore",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Guanzate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Guarcino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Guarda Veneta",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Guardabosone",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Guardamiglio",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Guardavalle",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Guardea",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Guardia Lombardi",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Guardia Perticara",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Guardia Piemontese",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Guardia Sanframondi",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Guardiagrele",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Guardialfiera",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Guardiaregia",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Guardistallo",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Guarene",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Guasila",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Guastalla",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Guazzora",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Gubbio",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Gudo Visconti",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Guglionesi",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Guidizzolo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Guidonia Montecelio",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Guiglia",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Guilmi",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Gurro",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Guspini",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Gussago",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Gussola",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Hône",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Idro",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Iglesias",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Igliano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Ilbono",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Illasi",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Illorai",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Imbersago",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Imer",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Imola",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Imperia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Impruneta",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Inarzo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Incisa Scapaccino",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Incudine",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Induno Olona",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Ingria",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Intragna",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Introbio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Introd",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Introdacqua",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Inverigo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Inverno e Monteleone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Inverso Pinasca",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Inveruno",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Invorio",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Inzago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Ionadi",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Irgoli",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Irma",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Irsina",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Isasca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Isca sullo Ionio",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Ischia",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Ischia di Castro",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Ischitella",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Iseo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Isera",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Isernia",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Isili",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Isnello",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Isola Dovarese",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Isola Rizza",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Isola Sant'Antonio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Isola Vicentina",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Isola d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Isola del Cantone",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Isola del Giglio",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Isola del Gran Sasso d'Italia",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Isola del Liri",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Isola del Piano",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Isola della Scala",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Isola delle Femmine",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Isola di Capo Rizzuto",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Isola di Fondra",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Isolabella",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Isolabona",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Isole Tremiti",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Isorella",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Ispani",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Ispica",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Ispra",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Issiglio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Issime",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Isso",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Issogne",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Istrana",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Itala",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Itri",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Ittireddu",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Ittiri",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Ivrea",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Izano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Jacurso",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Jelsi",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Jenne",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Jerago con Orago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Jerzu",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Jesi",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Jesolo",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Jolanda di Savoia",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Joppolo",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Joppolo Giancaxio",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Jovençan",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "L'Aquila",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "La Cassa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "La Loggia",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "La Maddalena",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "La Magdeleine",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "La Morra",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "La Salle",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "La Spezia",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "La Thuile",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "La Valle",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "La Valle Agordina",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "La Valletta Brianza",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Labico",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Labro",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Lacchiarella",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Lacco Ameno",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Lacedonia",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Laces",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Laconi",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Ladispoli",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Laerru",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Laganadi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Laghi",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Laglio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lagnasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Lago",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Lagonegro",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Lagosanto",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Lagundo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Laigueglia",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Lainate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Laino",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Laino Borgo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Laino Castello",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Laion",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Laives",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Lajatico",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Lallio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Lama Mocogno",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Lama dei Peligni",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Lambrugo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lamezia Terme",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Lamon",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Lampedusa e Linosa",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Lamporecchio",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Lamporo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Lana",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Lanciano",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Landiona",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Landriano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Langhirano",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Langosco",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Lanusei",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Lanuvio",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Lanzada",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Lanzo Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lapedona",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Lapio",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Lappano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Larciano",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Lardirago",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Lariano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Larino",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Las Plassas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Lasa",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Lascari",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Lasnigo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lastebasse",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Lastra a Signa",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Latera",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Laterina Pergine Valdarno",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Laterza",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Latiano",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Latina",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Latisana",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Latronico",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Lattarico",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Lauco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Laureana Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Laureana di Borrello",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Lauregno",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Laurenzana",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Lauria",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Lauriano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Laurino",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Laurito",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Lauro",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Lavagna",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Lavagno",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Lavarone",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Lavello",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Lavena Ponte Tresa",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Laveno-Mombello",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Lavenone",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Laviano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Lavis",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Lazise",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Lazzate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Lecce",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Lecce nei Marsi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Lecco",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Ledro",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Leffe",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Leggiuno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Legnago",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Legnano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Legnaro",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Lei",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Leini",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Leivi",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Lemie",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lendinara",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Leni",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Lenna",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Leno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Lenola",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Lenta",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Lentate sul Seveso",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Lentella",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Lentini",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Leonessa",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Leonforte",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Leporano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Lequile",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Lequio Berria",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Lequio Tanaro",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Lercara Friddi",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Lerici",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Lerma",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Lesa",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Lesegno",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Lesignano de' Bagni",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Lesina",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Lesmo",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Lessolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lessona",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Lestizza",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Letino",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Letojanni",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Lettere",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Lettomanoppello",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Lettopalena",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Levanto",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Levate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Leverano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Levice",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Levico Terme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Levone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lezzeno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Liberi",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Librizzi",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Licata",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Licciana Nardi",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Licenza",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Licodia Eubea",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Lierna",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Lignana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Lignano Sabbiadoro",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Lillianes",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Limana",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Limatola",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Limbadi",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Limbiate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Limena",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Limido Comasco",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Limina",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Limone Piemonte",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Limone sul Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Limosano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Linarolo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Linguaglossa",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Lioni",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Lipari",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Lipomo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lirio",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Liscate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Liscia",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Lisciano Niccone",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Lisio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Lissone",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Liveri",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Livigno",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Livinallongo del Col di Lana",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Livo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Livo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Livorno",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Livorno Ferraris",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Livraga",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Lizzanello",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Lizzano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Lizzano in Belvedere",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Loano",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Loazzolo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Locana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Locate Varesino",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Locate di Triulzi",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Locatello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Loceri",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Locorotondo",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Locri",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Loculi",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Lode'",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Lodi",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Lodi Vecchio",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Lodine",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Lodrino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Lograto",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Loiano",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Loiri Porto San Paolo",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Lomagna",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Lomazzo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lombardore",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lombriasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lomello",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Lona-Lases",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Lonate Ceppino",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Lonate Pozzolo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Lonato del Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Londa",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Longano",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Longare",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Longarone",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Longhena",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Longi",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Longiano",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Longobardi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Longobucco",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Longone Sabino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Longone al Segrino",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lonigo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Loranze'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Loreggia",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Loreglia",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Lorenzago di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Loreo",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Loreto",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Loreto Aprutino",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Loria",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Loro Ciuffenna",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Loro Piceno",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Lorsica",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Losine",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Lotzorai",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Lovere",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Lovero",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Lozio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Lozza",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Lozzo Atestino",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Lozzo di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Lozzolo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Lu e Cuccaro Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Lubriano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Lucca",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Lucca Sicula",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Lucera",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Lucignano",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Lucinasco",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Lucito",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Luco dei Marsi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Lucoli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Lugagnano Val d'Arda",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Lugnano in Teverina",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Lugo",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Lugo di Vicenza",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Luino",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Luisago",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lula",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Lumarzo",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Lumezzane",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Lunamatrona",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Lunano",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Lungavilla",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Lungro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Luni",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Luogosano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Luogosanto",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Lupara",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Lurago Marinone",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lurago d'Erba",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lurano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Luras",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Lurate Caccivio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Lusciano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Luserna",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Luserna San Giovanni",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lusernetta",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Lusevera",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Lusia",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Lusiana Conco",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Lusiglie'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Luson",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Lustra",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Luvinate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Luzzana",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Luzzara",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Luzzi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Maccagno con Pino e Veddasca",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Maccastorna",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Macchia Valfortore",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Macchia d'Isernia",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Macchiagodena",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Macello",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Macerata",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Macerata Campania",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Macerata Feltria",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Macherio",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Maclodio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Macomer",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Macra",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Macugnaga",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Maddaloni",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Madesimo",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Madignano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Madone",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Madonna del Sasso",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Madruzzo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Maenza",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Mafalda",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Magasa",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Magenta",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Maggiora",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Magherno",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Magione",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Magisano",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Magliano Alfieri",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Magliano Alpi",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Magliano Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Magliano Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Magliano Vetere",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Magliano de' Marsi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Magliano di Tenna",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Magliano in Toscana",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Maglie",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Magliolo",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Maglione",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Magnacavallo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Magnago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Magnano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Magnano in Riviera",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Magomadas",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Magre' sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Magreglio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Maida",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Maiera'",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Maierato",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Maiolati Spontini",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Maiolo",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Maiori",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Mairago",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Mairano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Maissana",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Majano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Malagnino",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Malalbergo",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Malborghetto Valbruna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Malcesine",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Male'",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Malegno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Maleo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Malesco",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Maletto",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Malfa",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Malgrate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Malito",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Mallare",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Malles Venosta",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Malnate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Malo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Malonno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Maltignano",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Malvagna",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Malvicino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Malvito",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Mammola",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Mamoiada",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Manciano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Mandanici",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Mandas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Mandatoriccio",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Mandela",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Mandello Vitta",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Mandello del Lario",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Manduria",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Manerba del Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Manerbio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Manfredonia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Mango",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mangone",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Maniace",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Maniago",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Manocalzati",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Manoppello",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Mansue'",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Manta",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mantello",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Mantova",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Manzano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Manziana",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Mapello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Mappano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Mara",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Maracalagonis",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Maranello",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Marano Equo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Marano Lagunare",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Marano Marchesato",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Marano Principato",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Marano Ticino",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Marano Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Marano di Napoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Marano di Valpolicella",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Marano sul Panaro",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Maranzana",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Maratea",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Marcallo con Casone",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Marcaria",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Marcedusa",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Marcellina",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Marcellinara",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Marcetelli",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Marcheno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Marchirolo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Marciana",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Marciana Marina",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Marcianise",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Marciano della Chiana",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Marcignago",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Marcon",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Marebbe",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Marene",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mareno di Piave",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Marentino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Maretto",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Margarita",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Margherita di Savoia",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Margno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Mariana Mantovana",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Mariano Comense",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Mariano del Friuli",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Marianopoli",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Mariglianella",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Marigliano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Marina di Gioiosa Ionica",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Marineo",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Marino",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Marlengo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Marliana",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Marmentino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Marmirolo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Marmora",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Marnate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Marone",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Maropati",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Marostica",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Marradi",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Marrubiu",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Marsaglia",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Marsala",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Marsciano",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Marsico Nuovo",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Marsicovetere",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Marta",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Martano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Martellago",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Martello",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Martignacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Martignana di Po",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Martignano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Martina Franca",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Martinengo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Martiniana Po",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Martinsicuro",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Martirano",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Martirano Lombardo",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Martis",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Martone",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Marudo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Maruggio",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Marzabotto",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Marzano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Marzano Appio",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Marzano di Nola",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Marzi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Marzio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Masainas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Masate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Mascali",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Mascalucia",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Maschito",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Masciago Primo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Maser",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Masera",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Masera' di Padova",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Maserada sul Piave",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Masi",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Masi Torello",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Masio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Maslianico",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Masone",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Massa",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Massa Fermana",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Massa Lombarda",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Massa Lubrense",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Massa Marittima",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Massa Martana",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Massa d'Albe",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Massa di Somma",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Massa e Cozzile",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Massafra",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Massalengo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Massanzago",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Massarosa",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Massazza",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Massello",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Masserano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Massignano",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Massimeno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Massimino",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Massino Visconti",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Massiola",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Masullas",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Matelica",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Matera",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Mathi",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Matino",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Matrice",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Mattie",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Mattinata",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Mazara del Vallo",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Mazzano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Mazzano Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Mazzarino",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Mazzarra' Sant'Andrea",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Mazzarrone",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Mazze'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Mazzin",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Mazzo di Valtellina",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Meana Sardo",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Meana di Susa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Meda",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Mede",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Medea",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Medesano",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Medicina",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Mediglia",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Medolago",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Medole",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Medolla",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Meduna di Livenza",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Meduno",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Megliadino San Vitale",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Meina",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Melara",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Melazzo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Meldola",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Mele",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Melegnano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Melendugno",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Meleti",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Melfi",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Melicucca'",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Melicucco",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Melilli",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Melissa",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Melissano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Melito Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Melito di Napoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Melito di Porto Salvo",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Melizzano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Melle",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mello",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Melpignano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Meltina",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Melzo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Menaggio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Menconico",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Mendatica",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Mendicino",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Menfi",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Mentana",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Meolo",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Merana",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Merano",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Merate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Mercallo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Mercatello sul Metauro",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Mercatino Conca",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Mercato San Severino",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Mercato Saraceno",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Mercenasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Mercogliano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Mereto di Tomba",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Mergo",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Mergozzo",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Meri'",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Merlara",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Merlino",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Merone",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Mesagne",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Mese",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Mesenzana",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Mesero",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Mesola",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Mesoraca",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Messina",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Mestrino",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Meta",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Mezzago",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Mezzana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Mezzana Bigli",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Mezzana Mortigliengo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Mezzana Rabattone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Mezzane di Sotto",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Mezzanego",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Mezzanino",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Mezzano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Mezzenile",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Mezzocorona",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Mezzojuso",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Mezzoldo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Mezzolombardo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Mezzomerico",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Miagliano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Miane",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Miasino",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Miazzina",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Micigliano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Miggiano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Miglianico",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Miglierina",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Miglionico",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Mignanego",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Mignano Monte Lungo",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Milano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Milazzo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Milena",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Mileto",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Milis",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Militello Rosmarino",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Militello in Val di Catania",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Millesimo",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Milo",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Milzano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Mineo",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Minerbe",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Minerbio",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Minervino Murge",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Minervino di Lecce",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Minori",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Minturno",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Minucciano",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Mioglia",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Mira",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Mirabella Eclano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Mirabella Imbaccari",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Mirabello Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Mirabello Sannitico",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Miradolo Terme",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Miranda",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Mirandola",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Mirano",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Mirto",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Misano Adriatico",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Misano di Gera d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Misiliscemi",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Misilmeri",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Misinto",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Missaglia",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Missanello",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Misterbianco",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Mistretta",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Moasca",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Moconesi",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Modena",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Modica",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Modigliana",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Modolo",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Modugno",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Moena",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Moggio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Moggio Udinese",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Moglia",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Mogliano",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Mogliano Veneto",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Mogorella",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Mogoro",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Moiano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Moimacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Moio Alcantara",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Moio de' Calvi",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Moio della Civitella",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Moiola",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mola di Bari",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Molare",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Molazzana",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Molfetta",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Molina Aterno",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Molinara",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Molinella",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Molini di Triora",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Molino dei Torti",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Molise",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Moliterno",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Mollia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Molochio",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Molteno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Moltrasio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Molveno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Mombaldone",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Mombarcaro",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mombaroccio",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Mombaruzzo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Mombasiglio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mombello Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Mombello di Torino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Mombercelli",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Momo",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Mompantero",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Mompeo",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Momperone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Monacilioni",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Monale",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Monasterace",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Monastero Bormida",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Monastero di Lanzo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Monastero di Vasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monasterolo Casotto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monasterolo del Castello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Monasterolo di Savigliano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monastier di Treviso",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Monastir",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Moncalieri",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Moncalvo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Moncenisio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Moncestino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Monchiero",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monchio delle Corti",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Moncrivello",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Moncucco Torinese",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Mondaino",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Mondavio",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Mondolfo",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Mondovi'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Mondragone",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Moneglia",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Monesiglio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monfalcone",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Monforte San Giorgio",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Monforte d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monfumo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Mongardino",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Monghidoro",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Mongiana",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Mongiardino Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Mongiuffi Melia",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Mongrando",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Mongrassano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Monguelfo-Tesido",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Monguzzo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Moniga del Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Monleale",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Monno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Monopoli",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Monreale",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Monrupino",
    provinceAbbr: "TS",
    province: "Trieste",
  },
  {
    name: "Monsampietro Morico",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monsampolo del Tronto",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Monsano",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Monselice",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Monserrato",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Monsummano Terme",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Monta'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Montabone",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Montacuto",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Montafia",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Montagano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Montagna in Valtellina",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Montagna sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Montagnana",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Montagnareale",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Montaguto",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montaione",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Montalbano Elicona",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Montalbano Jonico",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Montalcino",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Montaldeo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Montaldo Bormida",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Montaldo Roero",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Montaldo Scarampi",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Montaldo Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Montaldo di Mondovi'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Montale",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Montalenghe",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Montallegro",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Montalto Carpasio",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Montalto Dora",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Montalto Pavese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Montalto Uffugo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Montalto delle Marche",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Montalto di Castro",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Montanaro",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Montanaso Lombardo",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Montanera",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Montano Antilia",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Montano Lucino",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Montappone",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montaquila",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Montasola",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Montauro",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Montazzoli",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Monte Argentario",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Monte Castello di Vibio",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Monte Cavallo",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Monte Cerignone",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Monte Compatri",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Monte Cremasco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Monte Giberto",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monte Grimano Terme",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Monte Isola",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Monte Marenzo",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Monte Porzio",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Monte Porzio Catone",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Monte Rinaldo",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monte Roberto",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Monte Romano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Monte San Biagio",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Monte San Giacomo",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Monte San Giovanni Campano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Monte San Giovanni in Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Monte San Giusto",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Monte San Martino",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Monte San Pietrangeli",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monte San Pietro",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Monte San Savino",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Monte San Vito",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Monte Sant'Angelo",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Monte Santa Maria Tiberina",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Monte Urano",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monte Vidon Combatte",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monte Vidon Corrado",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monte di Malo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Monte di Procida",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Montebello Jonico",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Montebello Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Montebello della Battaglia",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Montebello di Bertona",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Montebello sul Sangro",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Montebelluna",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Montebruno",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Montebuono",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Montecalvo Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montecalvo Versiggia",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Montecalvo in Foglia",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Montecarlo",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Montecarotto",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Montecassiano",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Montecastello",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Montecastrilli",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Montecatini Val di Cecina",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Montecatini-Terme",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Montecchia di Crosara",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Montecchio",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Montecchio Emilia",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Montecchio Maggiore",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Montecchio Precalcino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Montechiaro d'Acqui",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Montechiaro d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Montechiarugolo",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Montecilfone",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Montecopiolo",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Montecorice",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Montecorvino Pugliano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Montecorvino Rovella",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Montecosaro",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Montecrestese",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Montecreto",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Montedinove",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Montedoro",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Montefalcione",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montefalco",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Montefalcone Appennino",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montefalcone di Val Fortore",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Montefalcone nel Sannio",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Montefano",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Montefelcino",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Monteferrante",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Montefiascone",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Montefino",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Montefiore Conca",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Montefiore dell'Aso",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Montefiorino",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Monteflavio",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Monteforte Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Monteforte Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Monteforte d'Alpone",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Montefortino",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montefranco",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Montefredane",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montefusco",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montegabbione",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Montegalda",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Montegaldella",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Montegallo",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Montegioco",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Montegiordano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Montegiorgio",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montegranaro",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montegridolfo",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Montegrino Valtravaglia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Montegrosso Pian Latte",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Montegrosso d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Montegrotto Terme",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Monteiasi",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Montelabbate",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Montelanico",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Montelapiano",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Monteleone Rocca Doria",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Monteleone Sabino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Monteleone d'Orvieto",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Monteleone di Fermo",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Monteleone di Puglia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Monteleone di Spoleto",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Montelepre",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Montelibretti",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Montella",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Montelongo",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Montelparo",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montelupo Albese",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Montelupo Fiorentino",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Montelupone",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Montemaggiore Belsito",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Montemagno Monferrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Montemale di Cuneo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Montemarano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montemarciano",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Montemarzino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Montemesola",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Montemezzo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Montemignaio",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Montemiletto",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montemilone",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Montemitro",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Montemonaco",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Montemurlo",
    provinceAbbr: "PO",
    province: "Prato",
  },
  {
    name: "Montemurro",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Montenars",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Montenero Sabino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Montenero Val Cocchiara",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Montenero di Bisaccia",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Montenerodomo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Monteodorisio",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Montepaone",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Monteparano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Monteprandone",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Montepulciano",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Monterchi",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Montereale",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Montereale Valcellina",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Monterenzio",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Monteriggioni",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Monteroduni",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Monteroni d'Arbia",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Monteroni di Lecce",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Monterosi",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Monterosso Almo",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Monterosso Calabro",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Monterosso Grana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monterosso al Mare",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Monterotondo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Monterotondo Marittimo",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Monterubbiano",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montesano Salentino",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Montesano sulla Marcellana",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Montesarchio",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Montescaglioso",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Montescano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Montescheno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Montescudaio",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Montescudo-Monte Colombo",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Montese",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Montesegale",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Montesilvano",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Montespertoli",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Monteu Roero",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monteu da Po",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Montevago",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Montevarchi",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Montevecchia",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Monteverde",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Monteverdi Marittimo",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Monteviale",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Montezemolo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Monti",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Montiano",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Monticelli Brusati",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Monticelli Pavese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Monticelli d'Ongina",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Monticello Brianza",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Monticello Conte Otto",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Monticello d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Montichiari",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Monticiano",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Montieri",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Montiglio Monferrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Montignoso",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Montirone",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Montjovet",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Montodine",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Montoggio",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Montone",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Montopoli di Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Montopoli in Val d'Arno",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Montorfano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Montorio Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Montorio al Vomano",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Montorio nei Frentani",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Montoro",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Montorso Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Montottone",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Montresta",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Montu' Beccaria",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Monvalle",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Monza",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Monzambano",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Monzuno",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Morano Calabro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Morano sul Po",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Moransengo-Tonengo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Moraro",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Morazzone",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Morbegno",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Morbello",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Morciano di Leuca",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Morciano di Romagna",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Morcone",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Mordano",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Morengo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Mores",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Moresco",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Moretta",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Morfasso",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Morgano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Morgex",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Morgongiori",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Mori",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Moriago della Battaglia",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Moricone",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Morigerati",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Morimondo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Morino",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Moriondo Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Morlupo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Mormanno",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Mornago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Mornese",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Mornico Losana",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Mornico al Serio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Morolo",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Morozzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Morra De Sanctis",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Morro Reatino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Morro d'Alba",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Morro d'Oro",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Morrone del Sannio",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Morrovalle",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Morsano al Tagliamento",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Morsasco",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Mortara",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Mortegliano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Morterone",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Moruzzo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Moscazzano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Moschiano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Mosciano Sant'Angelo",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Moscufo",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Moso in Passiria",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Mossa",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Motta Baluffi",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Motta Camastra",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Motta Montecorvino",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Motta San Giovanni",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Motta Sant'Anastasia",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Motta Santa Lucia",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Motta Visconti",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Motta d'Affermo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Motta de' Conti",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Motta di Livenza",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Mottafollone",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Mottalciata",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Motteggiana",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Mottola",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Mozzagrogna",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Mozzanica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Mozzate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Mozzecane",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Mozzo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Muccia",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Muggia",
    provinceAbbr: "TS",
    province: "Trieste",
  },
  {
    name: "Muggio'",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Mugnano del Cardinale",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Mugnano di Napoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Mulazzano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Mulazzo",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Mura",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Muravera",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Murazzano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Murello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Murialdo",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Murisengo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Murlo",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Muro Leccese",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Muro Lucano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Muros",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Muscoline",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Musei",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Musile di Piave",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Musso",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Mussolente",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Mussomeli",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Muzzana del Turgnano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Muzzano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Nago-Torbole",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Nalles",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Nanto",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Napoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Narbolia",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Narcao",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Nardo'",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Nardodipace",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Narni",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Naro",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Narzole",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Nasino",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Naso",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Naturno",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Nave",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Navelli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Naz-Sciaves",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Nazzano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Ne",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Nebbiuno",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Negrar di Valpolicella",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Neirone",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Neive",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Nembro",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Nemi",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Nemoli",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Neoneli",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Nepi",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Nereto",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Nerola",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Nervesa della Battaglia",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Nerviano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Nespolo",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Nesso",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Netro",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Nettuno",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Neviano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Neviano degli Arduini",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Neviglie",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Niardo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Nibbiola",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Nibionno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Nichelino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Nicolosi",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Nicorvo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Nicosia",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Nicotera",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Niella Belbo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Niella Tanaro",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Nimis",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Niscemi",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Nissoria",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Nizza Monferrato",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Nizza di Sicilia",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Noale",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Noasca",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Nocara",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Nocciano",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Nocera Inferiore",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Nocera Superiore",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Nocera Terinese",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Nocera Umbra",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Noceto",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Noci",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Nociglia",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Noepoli",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Nogara",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Nogaredo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Nogarole Rocca",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Nogarole Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Noicattaro",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Nola",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Nole",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Noli",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Nomaglio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Nomi",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Nonantola",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "None",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Nonio",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Noragugume",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Norbello",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Norcia",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Norma",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Nosate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Notaresco",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Noto",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Nova Levante",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Nova Milanese",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Nova Ponente",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Nova Siri",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Novafeltria",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Novaledo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Novalesa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Novara",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Novara di Sicilia",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Novate Mezzola",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Novate Milanese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Nove",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Novedrate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Novella",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Novellara",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Novello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Noventa Padovana",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Noventa Vicentina",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Noventa di Piave",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Novi Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Novi Velia",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Novi di Modena",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Noviglio",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Novoli",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Nucetto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Nughedu San Nicolo'",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Nughedu Santa Vittoria",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Nule",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Nulvi",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Numana",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Nuoro",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Nurachi",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Nuragus",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Nurallao",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Nuraminis",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Nureci",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Nurri",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Nus",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Nusco",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Nuvolento",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Nuvolera",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Nuxis",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Occhieppo Inferiore",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Occhieppo Superiore",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Occhiobello",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Occimiano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ocre",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Odalengo Grande",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Odalengo Piccolo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Oderzo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Odolo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Ofena",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Offagna",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Offanengo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Offida",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Offlaga",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Oggebbio",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Oggiona con Santo Stefano",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Oggiono",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Oglianico",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ogliastro Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Olbia",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Olcenengo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Oldenico",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Oleggio",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Oleggio Castello",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Olevano Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Olevano di Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Olevano sul Tusciano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Olgiate Comasco",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Olgiate Molgora",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Olgiate Olona",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Olginate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Oliena",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Oliva Gessi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Olivadi",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Oliveri",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Oliveto Citra",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Oliveto Lario",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Oliveto Lucano",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Olivetta San Michele",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Olivola",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ollastra",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Ollolai",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Ollomont",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Olmedo",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Olmeneta",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Olmo Gentile",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Olmo al Brembo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Oltre il Colle",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Oltressenda Alta",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Oltrona di San Mamette",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Olzai",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Ome",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Omegna",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Omignano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Onani'",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Onano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Oncino",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Oneta",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Onifai",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Oniferi",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Ono San Pietro",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Onore",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Onzo",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Opera",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Opi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Oppeano",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Oppido Lucano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Oppido Mamertina",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Ora",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Orani",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Oratino",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Orbassano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Orbetello",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Orciano Pisano",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Orco Feglino",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Ordona",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Orero",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Orgiano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Orgosolo",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Oria",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Oricola",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Origgio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Orino",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Orio Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Orio Litta",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Orio al Serio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Oriolo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Oriolo Romano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Oristano",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Ormea",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Ormelle",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Ornago",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Ornavasso",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Ornica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Orosei",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Orotelli",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Orria",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Orroli",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Orsago",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Orsara Bormida",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Orsara di Puglia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Orsenigo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Orsogna",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Orsomarso",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Orta Nova",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Orta San Giulio",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Orta di Atella",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Ortacesus",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Orte",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Ortelle",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Ortezzano",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Ortignano Raggiolo",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Ortisei",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Ortona",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Ortona dei Marsi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Ortovero",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Ortucchio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Ortueri",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Orune",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Orvieto",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Orvinio",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Orzinuovi",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Orzivecchi",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Osasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Osasio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Oschiri",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Osidda",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Osiglia",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Osilo",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Osimo",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Osini",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Osio Sopra",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Osio Sotto",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Osnago",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Osoppo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Ospedaletti",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Ospedaletto",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ospedaletto Euganeo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Ospedaletto Lodigiano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Ospedaletto d'Alpinolo",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Ospitale di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Ospitaletto",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Ossago Lodigiano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Ossana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ossi",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Ossimo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Ossona",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Ostana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Ostellato",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Ostiano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Ostiglia",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Ostra",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Ostra Vetere",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Ostuni",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Otranto",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Otricoli",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Ottana",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Ottati",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Ottaviano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Ottiglio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ottobiano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Ottone",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Oulx",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ovada",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ovaro",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Oviglio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ovindoli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Ovodda",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Oyace",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Ozegna",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ozieri",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Ozzano Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ozzano dell'Emilia",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Ozzero",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Pabillonis",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Pace del Mela",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Paceco",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Pacentro",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Pachino",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Paciano",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Padenghe sul Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Paderna",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Paderno Dugnano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Paderno Franciacorta",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Paderno Ponchielli",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Paderno d'Adda",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Padova",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Padria",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Padru",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Padula",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Paduli",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Paesana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Paese",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Pagani",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Paganico Sabino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Pagazzano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pagliara",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Paglieta",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Pagnacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pagno",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pagnona",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Pago Veiano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Pago del Vallo di Lauro",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Paisco Loveno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Paitone",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Paladina",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Palagano",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Palagianello",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Palagiano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Palagonia",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Palaia",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Palanzano",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Palata",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Palau",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Palazzago",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Palazzo Adriano",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Palazzo Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Palazzo Pignano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Palazzo San Gervasio",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Palazzolo Acreide",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Palazzolo Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Palazzolo dello Stella",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Palazzolo sull'Oglio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Palazzuolo sul Senio",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Palena",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Palermiti",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Palermo",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Palestrina",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Palestro",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Paliano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Palizzi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Pallagorio",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Pallanzeno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Pallare",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Palma Campania",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Palma di Montechiaro",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Palmanova",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Palmariggi",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Palmas Arborea",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Palmi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Palmiano",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Palmoli",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Palo del Colle",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Palombara Sabina",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Palombaro",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Palomonte",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Palosco",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Palu'",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Palu' del Fersina",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Paludi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Paluzza",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pamparato",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pancalieri",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pancarana",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Panchia'",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Pandino",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Panettieri",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Panicale",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Pannarano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Panni",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Pantelleria",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Pantigliate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Paola",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Paolisi",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Papasidero",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Papozze",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Parabiago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Parabita",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Paratico",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Parcines",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Parella",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Parenti",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Parete",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Pareto",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Parghelia",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Parlasco",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Parma",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Parodi Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Paroldo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Parolise",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Parona",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Parrano",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Parre",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Partanna",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Partinico",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Paruzzaro",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Parzanica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pasian di Prato",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pasiano di Pordenone",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Paspardo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Passerano Marmorito",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Passignano sul Trasimeno",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Passirano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pastena",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Pastorano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Pastrengo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Pasturana",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pasturo",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Paterno",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Paterno Calabro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Paterno'",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Paternopoli",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Patrica",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Pattada",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Patti",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Patu'",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Pau",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Paularo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pauli Arbarei",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Paulilatino",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Paullo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Paupisi",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Pavarolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pavia",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Pavia di Udine",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pavone Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pavone del Mella",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pavullo nel Frignano",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Pazzano",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Peccioli",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Pecetto Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pecetto di Valenza",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pedara",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Pedaso",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Pedavena",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Pedemonte",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Pederobba",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Pedesina",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Pedivigliano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Pedrengo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Peglio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Peglio",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Pegognaga",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Peia",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Peio",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Pelago",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Pella",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Pellegrino Parmense",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Pellezzano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pellizzano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Pelugo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Penango",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Penna San Giovanni",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Penna Sant'Andrea",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Penna in Teverina",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Pennabilli",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Pennadomo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Pennapiedimonte",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Penne",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Pentone",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Perano",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Perarolo di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Perca",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Percile",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Perdasdefogu",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Perdaxius",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Perdifumo",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pereto",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Perfugas",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Pergine Valsugana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Pergola",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Perinaldo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Perito",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Perledo",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Perletto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Perlo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Perloz",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Pernumia",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Pero",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Perosa Argentina",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Perosa Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Perrero",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Persico Dosimo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pertengo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Pertica Alta",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pertica Bassa",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pertosa",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pertusio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Perugia",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Pesaro",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Pescaglia",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Pescantina",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Pescara",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Pescarolo ed Uniti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pescasseroli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Pescate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Pesche",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Peschici",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Peschiera Borromeo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Peschiera del Garda",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Pescia",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Pescina",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Pesco Sannita",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Pescocostanzo",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Pescolanciano",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Pescopagano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Pescopennataro",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Pescorocchiano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Pescosansonesco",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Pescosolido",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Pessano con Bornago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Pessina Cremonese",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pessinetto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Petacciato",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Petilia Policastro",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Petina",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Petralia Soprana",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Petralia Sottana",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Petrella Salto",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Petrella Tifernina",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Petriano",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Petriolo",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Petritoli",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Petrizzi",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Petrona'",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Petrosino",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Petruro Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Pettenasco",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Pettinengo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Pettineo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Pettoranello del Molise",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Pettorano sul Gizio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Pettorazza Grimani",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Peveragno",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pezzana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Pezzaze",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pezzolo Valle Uzzone",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Piacenza",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Piacenza d'Adige",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Piadena Drizzona",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Piaggine",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pian Camuno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Piana Crixia",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Piana degli Albanesi",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Piana di Monte Verna",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Piancastagnaio",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Piancogno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Piandimeleto",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Piane Crati",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Pianella",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Pianello Val Tidone",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Pianello del Lario",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Pianengo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pianezza",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pianezze",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Pianfei",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pianico",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pianiga",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Piano di Sorrento",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Pianopoli",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Pianoro",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Piansano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Piantedo",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Piario",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Piasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Piateda",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Piatto",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Piazza Armerina",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Piazza Brembana",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Piazza al Serchio",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Piazzatorre",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Piazzola sul Brenta",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Piazzolo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Picciano",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Picerno",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Picinisco",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Pico",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Piea",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Piedicavallo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Piedimonte Etneo",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Piedimonte Matese",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Piedimonte San Germano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Piedimulera",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Piegaro",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Pienza",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Pieranica",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pietra Ligure",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Pietra Marazzi",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pietra de' Giorgi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Pietrabbondante",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Pietrabruna",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Pietracamela",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Pietracatella",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Pietracupa",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Pietradefusi",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Pietraferrazzana",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Pietrafitta",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Pietragalla",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Pietralunga",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Pietramelara",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Pietramontecorvino",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Pietranico",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Pietrapaola",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Pietrapertosa",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Pietraperzia",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Pietraporzio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pietraroja",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Pietrarubbia",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Pietrasanta",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Pietrastornina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Pietravairano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Pietrelcina",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Pieve Albignola",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Pieve Emanuele",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Pieve Fissiraga",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Pieve Fosciana",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Pieve Ligure",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Pieve Porto Morone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Pieve San Giacomo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pieve Santo Stefano",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Pieve Tesino",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Pieve Torina",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Pieve Vergonte",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Pieve a Nievole",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Pieve d'Olmi",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pieve del Cairo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Pieve del Grappa",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Pieve di Bono-Prezzo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Pieve di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Pieve di Cento",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Pieve di Soligo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Pieve di Teco",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Pievepelago",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Piglio",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Pigna",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Pignataro Interamna",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Pignataro Maggiore",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Pignola",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Pignone",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Pigra",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Pila",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Pimentel",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Pimonte",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Pinarolo Po",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Pinasca",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pincara",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Pinerolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pineto",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Pino Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pino d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Pinzano al Tagliamento",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Pinzolo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Piobbico",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Piobesi Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Piobesi d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Piode",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Pioltello",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Piombino",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Piombino Dese",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Pioraco",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Piossasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Piova' Massaia",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Piove di Sacco",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Piovene Rocchette",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Piozzano",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Piozzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Piraino",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Pisa",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Pisano",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Piscina",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Piscinas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Pisciotta",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pisogne",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pisoniano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Pisticci",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Pistoia",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Pitigliano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Piubega",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Piuro",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Piverone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pizzale",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Pizzighettone",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pizzo",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Pizzoferrato",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Pizzoli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Pizzone",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Pizzoni",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Placanica",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Plataci",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Platania",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Plati'",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Plaus",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Plesio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Ploaghe",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Plodio",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Pocapaglia",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pocenia",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Podenzana",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Podenzano",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Pofi",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Poggiardo",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Poggibonsi",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Poggio Bustone",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Poggio Catino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Poggio Imperiale",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Poggio Mirteto",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Poggio Moiano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Poggio Nativo",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Poggio Picenze",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Poggio Renatico",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Poggio Rusco",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Poggio San Lorenzo",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Poggio San Marcello",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Poggio San Vicino",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Poggio Sannita",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Poggio Torriana",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Poggio a Caiano",
    provinceAbbr: "PO",
    province: "Prato",
  },
  {
    name: "Poggiodomo",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Poggiofiorito",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Poggiomarino",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Poggioreale",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Poggiorsini",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Poggiridenti",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Pogliano Milanese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Pognana Lario",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Pognano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pogno",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Poirino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pojana Maggiore",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Polaveno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Polcenigo",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Polesella",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Polesine Zibello",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Poli",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Polia",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Policoro",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Polignano a Mare",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Polinago",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Polino",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Polistena",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Polizzi Generosa",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Polla",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pollein",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Pollena Trocchia",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Pollenza",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Pollica",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pollina",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Pollone",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Pollutri",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Polonghera",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Polpenazze del Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Polverara",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Polverigi",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Pomarance",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Pomaretto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pomarico",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Pomaro Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pomarolo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Pombia",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Pomezia",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Pomigliano d'Arco",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Pompei",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Pompeiana",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Pompiano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pomponesco",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Pompu",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Poncarale",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Ponderano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Ponna",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Ponsacco",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Ponso",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Pont Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pont-Saint-Martin",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Pontassieve",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Pontboset",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Ponte",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Ponte Buggianese",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Ponte Gardena",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Ponte Lambro",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Ponte Nizza",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Ponte Nossa",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ponte San Nicolo'",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Ponte San Pietro",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ponte dell'Olio",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Ponte di Legno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Ponte di Piave",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Ponte in Valtellina",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Ponte nelle Alpi",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Pontebba",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pontecagnano Faiano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pontecchio Polesine",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Pontechianale",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pontecorvo",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Pontecurone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pontedassio",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Pontedera",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Pontelandolfo",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Pontelatone",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Pontelongo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Pontenure",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Ponteranica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pontestura",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pontevico",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pontey",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Ponti",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ponti sul Mincio",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Pontida",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pontinia",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Pontinvrea",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Pontirolo Nuovo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pontoglio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pontremoli",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Ponza",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Ponzano Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Ponzano Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Ponzano Veneto",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Ponzano di Fermo",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Ponzone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Popoli Terme",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Poppi",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Porano",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Porcari",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Porcia",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Pordenone",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Porlezza",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Pornassio",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Porpetto",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Portacomaro",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Portalbera",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Porte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Porte di Rendena",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Portici",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Portico di Caserta",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Portico e San Benedetto",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Portigliola",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Porto Azzurro",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Porto Ceresio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Porto Cesareo",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Porto Empedocle",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Porto Mantovano",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Porto Recanati",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Porto San Giorgio",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Porto Sant'Elpidio",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Porto Tolle",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Porto Torres",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Porto Valtravaglia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Porto Viro",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Portobuffole'",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Portocannone",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Portoferraio",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Portofino",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Portogruaro",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Portomaggiore",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Portopalo di Capo Passero",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Portoscuso",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Portovenere",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Portula",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Posada",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Posina",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Positano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Possagno",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Posta",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Posta Fibreno",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Postal",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Postalesio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Postiglione",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Postua",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Potenza",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Potenza Picena",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Pove del Grappa",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Povegliano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Povegliano Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Poviglio",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Povoletto",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pozzaglia Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Pozzaglio ed Uniti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Pozzallo",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Pozzilli",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Pozzo d'Adda",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Pozzol Groppo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pozzolengo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Pozzoleone",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Pozzolo Formigaro",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Pozzomaggiore",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Pozzonovo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Pozzuoli",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Pozzuolo Martesana",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Pozzuolo del Friuli",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pradalunga",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pradamano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pradleves",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pragelato",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Praia a Mare",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Praiano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Pralboino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Prali",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pralormo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Pralungo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Pramaggiore",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Pramollo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Prarolo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Prarostino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Prasco",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Prascorsano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Prata Camportaccio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Prata Sannita",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Prata d'Ansidonia",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Prata di Pordenone",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Prata di Principato Ultra",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Pratella",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Pratiglione",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Prato",
    provinceAbbr: "PO",
    province: "Prato",
  },
  {
    name: "Prato Carnico",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Prato Sesia",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Prato allo Stelvio",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Pratola Peligna",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Pratola Serra",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Pratovecchio Stia",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Pravisdomini",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Pray",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Prazzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Pre'-Saint-Didier",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Precenicco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Preci",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Predaia",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Predappio",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Predazzo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Predoi",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Predore",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Predosa",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Preganziol",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Pregnana Milanese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Prela'",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Premana",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Premariacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Premeno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Premia",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Premilcuore",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Premolo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Premosello-Chiovenda",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Preone",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Prepotto",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Preseglie",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Presenzano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Presezzo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Presicce-Acquarica",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Pressana",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Pretoro",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Prevalle",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Prezza",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Priero",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Prignano Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Prignano sulla Secchia",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Primaluna",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Primiero San Martino di Castrozza",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Priocca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Priola",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Priolo Gargallo",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Priverno",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Prizzi",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Proceno",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Procida",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Propata",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Proserpio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Prossedi",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Provaglio Val Sabbia",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Provaglio d'Iseo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Proves",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Provvidenti",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Prunetto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Puegnago del Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Puglianello",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Pula",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Pulfero",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Pulsano",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Pumenengo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Pusiano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Putifigari",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Putignano",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Quadrelle",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Quadri",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Quagliuzzo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Qualiano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Quaranti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Quaregna Cerreto",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Quargnento",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Quarna Sopra",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Quarna Sotto",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Quarona",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Quarrata",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Quart",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Quarto",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Quarto d'Altino",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Quartu Sant'Elena",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Quartucciu",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Quassolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Quattordio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Quattro Castella",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Quiliano",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Quincinetto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Quindici",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Quingentole",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Quintano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Quinto Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Quinto Vicentino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Quinto di Treviso",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Quinzano d'Oglio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Quistello",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Rabbi",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Racale",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Racalmuto",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Racconigi",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Raccuja",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Racines",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Radda in Chianti",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Raddusa",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Radicofani",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Radicondoli",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Raffadali",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Ragalna",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Ragogna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Ragusa",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Raiano",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Ramacca",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Rancio Valcuvia",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Ranco",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Randazzo",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Ranica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ranzanico",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Ranzo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Rapagnano",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Rapallo",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Rapino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Rapolano Terme",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Rapolla",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Rapone",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Rassa",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Rasun-Anterselva",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Rasura",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Ravanusa",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Ravarino",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Ravascletto",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Ravello",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Ravenna",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Raveo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Raviscanina",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Re",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Rea",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Realmonte",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Reana del Rojale",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Reano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Recale",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Recanati",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Recco",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Recetto",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Recoaro Terme",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Redavalle",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Redondesco",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Refrancore",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Refrontolo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Regalbuto",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Reggello",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Reggio Emilia",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Reggio di Calabria",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Reggiolo",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Reino",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Reitano",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Remanzacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Remedello",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Renate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Rende",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Renon",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Resana",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Rescaldina",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Resia",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Resiutta",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Resuttano",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Retorbido",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Revello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Revigliasco d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Revine Lago",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Rezzago",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Rezzato",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Rezzo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Rezzoaglio",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Rho",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Rhêmes-Notre-Dame",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Rhêmes-Saint-Georges",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Riace",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Rialto",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Riano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Riardo",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Ribera",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Ribordone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ricadi",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Ricaldone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Riccia",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Riccione",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Ricco' del Golfo di Spezia",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Ricengo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Ricigliano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Riese Pio X",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Riesi",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Rieti",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Rifiano",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Rifreddo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rignano Flaminio",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Rignano Garganico",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Rignano sull'Arno",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Rigolato",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Rimella",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Rimini",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Rio",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Rio Saliceto",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Rio di Pusteria",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Riofreddo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Riola Sardo",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Riolo Terme",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Riolunato",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Riomaggiore",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Rionero Sannitico",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Rionero in Vulture",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Ripa Teatina",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Ripabottoni",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Ripacandida",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Ripalimosani",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Ripalta Arpina",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Ripalta Cremasca",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Ripalta Guerina",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Riparbella",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Ripatransone",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Ripe San Ginesio",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Ripi",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Riposto",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Rittana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Riva Ligure",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Riva del Garda",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Riva del Po",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Riva di Solto",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Riva presso Chieri",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rivalba",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rivalta Bormida",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Rivalta di Torino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rivamonte Agordino",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Rivanazzano Terme",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Rivara",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rivarolo Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rivarolo Mantovano",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Rivarolo del Re ed Uniti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Rivarone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Rivarossa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rive",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Rive d'Arcano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Rivello",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Rivergaro",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Rivignano Teor",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Rivisondoli",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Rivodutri",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Rivoli",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rivoli Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Rivolta d'Adda",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Rizziconi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Roana",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Roaschia",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roascio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roasio",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Roatto",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Robassomero",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Robbiate",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Robbio",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Robecchetto con Induno",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Robecco Pavese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Robecco d'Oglio",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Robecco sul Naviglio",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Robella",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Robilante",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roburent",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rocca Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rocca Canterano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Rocca Ciglie'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rocca Grimalda",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Rocca Imperiale",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Rocca Massima",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Rocca Pia",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Rocca Pietore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Rocca Priora",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Rocca San Casciano",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Rocca San Felice",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Rocca San Giovanni",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Rocca Santa Maria",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Rocca Santo Stefano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Rocca Sinibalda",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Rocca Susella",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Rocca d'Arazzo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Rocca d'Arce",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Rocca d'Evandro",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Rocca de' Baldi",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rocca de' Giorgi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Rocca di Botte",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Rocca di Cambio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Rocca di Cave",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Rocca di Mezzo",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Rocca di Neto",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Rocca di Papa",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Roccabascerana",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Roccabernarda",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Roccabianca",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Roccabruna",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roccacasale",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Roccadaspide",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Roccafiorita",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Roccafluvione",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Roccaforte Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Roccaforte Mondovi'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roccaforte del Greco",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Roccaforzata",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Roccafranca",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Roccagiovine",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Roccagloriosa",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Roccagorga",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Roccalbegna",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Roccalumera",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Roccamandolfi",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Roccamena",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Roccamonfina",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Roccamontepiano",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Roccamorice",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Roccanova",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Roccantica",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Roccapalumba",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Roccapiemonte",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Roccarainola",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Roccaraso",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Roccaromana",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Roccascalegna",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Roccasecca",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Roccasecca dei Volsci",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Roccasicura",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Roccasparvera",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roccaspinalveti",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Roccastrada",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Roccavaldina",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Roccaverano",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Roccavignale",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Roccavione",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roccavivara",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Roccella Ionica",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Roccella Valdemone",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Rocchetta Belbo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rocchetta Ligure",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Rocchetta Nervina",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Rocchetta Palafea",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Rocchetta Sant'Antonio",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Rocchetta Tanaro",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Rocchetta a Volturno",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Rocchetta di Vara",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Rocchetta e Croce",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Rodano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Roddi",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Roddino",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rodello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rodengo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Rodengo Saiano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Rodero",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Rodi Garganico",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Rodi' Milici",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Rodigo",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Roe' Volciano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Rofrano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Rogeno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Roggiano Gravina",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Roghudi",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Rogliano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Rognano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Rogno",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Rogolo",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Roiate",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Roio del Sangro",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Roisan",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Roletto",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rolo",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Roma",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Romagnano Sesia",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Romagnano al Monte",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Romagnese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Romana",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Romanengo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Romano Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Romano d'Ezzelino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Romano di Lombardia",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Romans d'Isonzo",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Rombiolo",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Romeno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Romentino",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Rometta",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Ronca'",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Roncade",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Roncadelle",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Roncaro",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Roncegno Terme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Roncello",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Ronchi Valsugana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ronchi dei Legionari",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Ronchis",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Ronciglione",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Ronco Biellese",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Ronco Briantino",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Ronco Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ronco Scrivia",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Ronco all'Adige",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Roncobello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Roncoferraro",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Roncofreddo",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Roncola",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Rondanina",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Rondissone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ronsecco",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Ronzo-Chienis",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ronzone",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Roppolo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Rora'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rosa'",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Rosarno",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Rosasco",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Rosate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Rosazza",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Rosciano",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Roscigno",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Rose",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Rosello",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Roseto Capo Spulico",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Roseto Valfortore",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Roseto degli Abruzzi",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Rosignano Marittimo",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Rosignano Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Rosolina",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Rosolini",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Rosora",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Rossa",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Rossana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Rossano Veneto",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Rossiglione",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Rosta",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rota Greca",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Rota d'Imagna",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Rotella",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Rotello",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Rotonda",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Rotondella",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Rotondi",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Rottofreno",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Rotzo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Roure",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rovasenda",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Rovato",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Rovegno",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Rovellasca",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Rovello Porro",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Roverbella",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Roverchiara",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Rovere' Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Rovere' della Luna",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Roveredo di Gua'",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Roveredo in Piano",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Rovereto",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Rovescala",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Rovetta",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Roviano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Rovigo",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Rovito",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Rovolon",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Rozzano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Rubano",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Rubiana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Rubiera",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Ruda",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Rudiano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Rueglio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ruffano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Ruffia",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Ruffre'-Mendola",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Rufina",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Ruinas",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Rumo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ruoti",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Russi",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Rutigliano",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Rutino",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Ruviano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Ruvo del Monte",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Ruvo di Puglia",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Sabaudia",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Sabbio Chiese",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sabbioneta",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Sacco",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Saccolongo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Sacile",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Sacrofano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Sadali",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sagama",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sagliano Micca",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Sagrado",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Sagron Mis",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Saint-Christophe",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Saint-Denis",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Saint-Marcel",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Saint-Nicolas",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Saint-Oyen",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Saint-Pierre",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Saint-Rhe'my-en-Bosses",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Saint-Vincent",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Sala Baganza",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Sala Biellese",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Sala Bolognese",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Sala Comacina",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Sala Consilina",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sala Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Salandra",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Salaparuta",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Salara",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Salasco",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Salassa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Salbertrand",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Salcedo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Salcito",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Sale",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Sale Marasino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sale San Giovanni",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Sale delle Langhe",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Salemi",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Salento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Salerano Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Salerano sul Lambro",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Salerno",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Salgareda",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Sali Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Salice Salentino",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Saliceto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Salisano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Salizzole",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Salle",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Salmour",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Salo'",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Salorno sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Salsomaggiore Terme",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Saltrio",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Saludecio",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Saluggia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Salussola",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Saluzzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Salve",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Salvirola",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Salvitelle",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Salza Irpina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Salza di Pinerolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Salzano",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Samarate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Samassi",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Samatzai",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sambuca Pistoiese",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Sambuca di Sicilia",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Sambuci",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Sambuco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Sammichele di Bari",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Samo",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Samolaco",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Samone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Samone",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Sampeyre",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Samugheo",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "San Bartolomeo Val Cavargna",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "San Bartolomeo al Mare",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "San Bartolomeo in Galdo",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Basile",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Basilio",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "San Bassano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "San Bellino",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "San Benedetto Belbo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "San Benedetto Po",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "San Benedetto Ullano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Benedetto Val di Sambro",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "San Benedetto dei Marsi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "San Benedetto del Tronto",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "San Benedetto in Perillis",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "San Benigno Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Bernardino Verbano",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "San Biagio Platani",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "San Biagio Saracinisco",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "San Biagio della Cima",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "San Biagio di Callalta",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "San Biase",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Bonifacio",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Buono",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "San Calogero",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "San Candido",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "San Canzian d'Isonzo",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "San Carlo Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Casciano dei Bagni",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "San Casciano in Val di Pesa",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "San Cassiano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "San Cataldo",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "San Cesareo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "San Cesario di Lecce",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "San Cesario sul Panaro",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "San Chirico Nuovo",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "San Chirico Raparo",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "San Cipirello",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "San Cipriano Picentino",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Cipriano Po",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "San Cipriano d'Aversa",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Clemente",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "San Colombano Belmonte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Colombano Certenoli",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "San Colombano al Lambro",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "San Cono",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "San Cosmo Albanese",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Costantino Albanese",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "San Costantino Calabro",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "San Costanzo",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "San Cristoforo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "San Damiano Macra",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "San Damiano al Colle",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "San Damiano d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "San Daniele Po",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "San Daniele del Friuli",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "San Demetrio Corone",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Demetrio ne' Vestini",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "San Didero",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Dona' di Piave",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "San Donaci",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "San Donato Milanese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "San Donato Val di Comino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "San Donato di Lecce",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "San Donato di Ninea",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Dorligo della Valle",
    provinceAbbr: "TS",
    province: "Trieste",
  },
  {
    name: "San Fele",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "San Felice Circeo",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "San Felice a Cancello",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Felice del Benaco",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "San Felice del Molise",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Felice sul Panaro",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "San Ferdinando",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Ferdinando di Puglia",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "San Fermo della Battaglia",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "San Fili",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Filippo del Mela",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "San Fior",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "San Fiorano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "San Floriano del Collio",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "San Floro",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "San Francesco al Campo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Fratello",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "San Gavino Monreale",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "San Gemini",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "San Genesio Atesino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "San Genesio ed Uniti",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "San Gennaro Vesuviano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "San Germano Chisone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Germano Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "San Gervasio Bresciano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "San Giacomo Filippo",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "San Giacomo Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "San Giacomo degli Schiavoni",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Giacomo delle Segnate",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "San Gillio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Gimignano",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "San Ginesio",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "San Giorgio Albanese",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Giorgio Bigarello",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "San Giorgio Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Giorgio Ionico",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "San Giorgio La Molara",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Giorgio Lucano",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "San Giorgio Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "San Giorgio Morgeto",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Giorgio Piacentino",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "San Giorgio Scarampi",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "San Giorgio a Cremano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "San Giorgio a Liri",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "San Giorgio del Sannio",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Giorgio della Richinvelda",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "San Giorgio delle Pertiche",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "San Giorgio di Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "San Giorgio di Nogaro",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "San Giorgio di Piano",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "San Giorgio in Bosco",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "San Giorgio su Legnano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "San Giorio di Susa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Giovanni Bianco",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "San Giovanni Gemini",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "San Giovanni Ilarione",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Giovanni Incarico",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "San Giovanni Lipioni",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "San Giovanni Lupatoto",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Giovanni Rotondo",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "San Giovanni Suergiu",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "San Giovanni Teatino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "San Giovanni Valdarno",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "San Giovanni a Piro",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Giovanni al Natisone",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "San Giovanni del Dosso",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "San Giovanni di Fassa",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "San Giovanni di Gerace",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Giovanni in Croce",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "San Giovanni in Fiore",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Giovanni in Galdo",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Giovanni in Marignano",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "San Giovanni in Persiceto",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "San Giovanni la Punta",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "San Giuliano Milanese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "San Giuliano Terme",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "San Giuliano del Sannio",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Giuliano di Puglia",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Giuseppe Jato",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "San Giuseppe Vesuviano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "San Giustino",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "San Giusto Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Godenzo",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "San Gregorio Magno",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Gregorio Matese",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Gregorio d'Ippona",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "San Gregorio da Sassola",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "San Gregorio di Catania",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "San Gregorio nelle Alpi",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "San Lazzaro di Savena",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "San Leo",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "San Leonardo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "San Leonardo in Passiria",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "San Leucio del Sannio",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Lorenzello",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Lorenzo",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Lorenzo Bellizzi",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Lorenzo Dorsino",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "San Lorenzo Isontino",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "San Lorenzo Maggiore",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Lorenzo Nuovo",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "San Lorenzo al Mare",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "San Lorenzo del Vallo",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Lorenzo di Sebato",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "San Lorenzo in Campo",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "San Luca",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Lucido",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Lupo",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Mango Piemonte",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Mango d'Aquino",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "San Mango sul Calore",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "San Marcellino",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Marcello",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "San Marcello Piteglio",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "San Marco Argentano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Marco Evangelista",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Marco d'Alunzio",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "San Marco dei Cavoti",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Marco in Lamis",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "San Marco la Catola",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "San Martino Alfieri",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "San Martino Buon Albergo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Martino Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Martino Sannita",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Martino Siccomario",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "San Martino Valle Caudina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "San Martino al Tagliamento",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "San Martino d'Agri",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "San Martino dall'Argine",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "San Martino del Lago",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "San Martino di Finita",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Martino di Lupari",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "San Martino di Venezze",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "San Martino in Badia",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "San Martino in Passiria",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "San Martino in Pensilis",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Martino in Rio",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "San Martino in Strada",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "San Martino sulla Marrucina",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "San Marzano Oliveto",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "San Marzano di San Giuseppe",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "San Marzano sul Sarno",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Massimo",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Maurizio Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Maurizio d'Opaglio",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "San Mauro Castelverde",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "San Mauro Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Mauro Forte",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "San Mauro Marchesato",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "San Mauro Pascoli",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "San Mauro Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Mauro di Saline",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Mauro la Bruca",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Michele Mondovi'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "San Michele Salentino",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "San Michele al Tagliamento",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "San Michele all'Adige",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "San Michele di Ganzaria",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "San Michele di Serino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "San Miniato",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "San Nazzaro",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Nazzaro Sesia",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "San Nazzaro Val Cavargna",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "San Nicandro Garganico",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "San Nicola Arcella",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Nicola Baronia",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "San Nicola Manfredi",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Nicola da Crissa",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "San Nicola dell'Alto",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "San Nicola la Strada",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Nicolo' Gerrei",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "San Nicolo' d'Arcidano",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "San Nicolo' di Comelico",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "San Pancrazio",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "San Pancrazio Salentino",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "San Paolo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "San Paolo Albanese",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "San Paolo Bel Sito",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "San Paolo Solbrito",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "San Paolo d'Argon",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "San Paolo di Civitate",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "San Paolo di Jesi",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "San Pellegrino Terme",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "San Pier Niceto",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "San Pier d'Isonzo",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "San Piero Patti",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "San Pietro Apostolo",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "San Pietro Avellana",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "San Pietro Clarenza",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "San Pietro Infine",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Pietro Mosezzo",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "San Pietro Mussolino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "San Pietro Val Lemina",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Pietro Vernotico",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "San Pietro Viminario",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "San Pietro a Maida",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "San Pietro al Natisone",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "San Pietro al Tanagro",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Pietro di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "San Pietro di Carida'",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Pietro di Feletto",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "San Pietro di Morubio",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Pietro in Amantea",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Pietro in Cariano",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Pietro in Casale",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "San Pietro in Cerro",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "San Pietro in Gu",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "San Pietro in Guarano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Pietro in Lama",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "San Pio delle Camere",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "San Polo Matese",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "San Polo d'Enza",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "San Polo dei Cavalieri",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "San Polo di Piave",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "San Ponso",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Possidonio",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "San Potito Sannitico",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Potito Ultra",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "San Prisco",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Procopio",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Prospero",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "San Quirico d'Orcia",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "San Quirino",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "San Raffaele Cimena",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Roberto",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "San Rocco al Porto",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "San Romano in Garfagnana",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "San Rufo",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Salvatore Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "San Salvatore Telesino",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "San Salvatore di Fitalia",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "San Salvo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "San Sebastiano Curone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "San Sebastiano al Vesuvio",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "San Sebastiano da Po",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Secondo Parmense",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "San Secondo di Pinerolo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "San Severino Lucano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "San Severino Marche",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "San Severo",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "San Siro",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "San Sossio Baronia",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "San Sostene",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "San Sosti",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Sperate",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "San Stino di Livenza",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "San Tammaro",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "San Teodoro",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "San Teodoro",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "San Tomaso Agordino",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "San Valentino Torio",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "San Valentino in Abruzzo Citeriore",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "San Venanzo",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "San Vendemiano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "San Vero Milis",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "San Vincenzo",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "San Vincenzo La Costa",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "San Vincenzo Valle Roveto",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "San Vitaliano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "San Vito",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "San Vito Chietino",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "San Vito Lo Capo",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "San Vito Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "San Vito al Tagliamento",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "San Vito al Torre",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "San Vito dei Normanni",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "San Vito di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "San Vito di Fagagna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "San Vito di Leguzzano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "San Vito sullo Ionio",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "San Vittore Olona",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "San Vittore del Lazio",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "San Zeno Naviglio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "San Zeno di Montagna",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "San Zenone al Lambro",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "San Zenone al Po",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "San Zenone degli Ezzelini",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Sanarica",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Sandigliano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Sandrigo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Sanfre'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Sanfront",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Sangano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Sangiano",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Sangineto",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Sanguinetto",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Sanluri",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sannazzaro de' Burgondi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Sannicandro di Bari",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Sannicola",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Sanremo",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Sansepolcro",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Sant'Agapito",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Sant'Agata Bolognese",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Sant'Agata Feltria",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Sant'Agata Fossili",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Sant'Agata de' Goti",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Sant'Agata del Bianco",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Sant'Agata di Esaro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Sant'Agata di Militello",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Sant'Agata di Puglia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Sant'Agata li Battiati",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Sant'Agata sul Santerno",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Sant'Agnello",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Sant'Albano Stura",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Sant'Alessio Siculo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Sant'Alessio con Vialone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Sant'Alessio in Aspromonte",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Sant'Alfio",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Sant'Ambrogio di Torino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Sant'Ambrogio di Valpolicella",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Sant'Ambrogio sul Garigliano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Sant'Anastasia",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Sant'Anatolia di Narco",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Sant'Andrea Apostolo dello Ionio",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Sant'Andrea Frius",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sant'Andrea del Garigliano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Sant'Andrea di Conza",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Sant'Angelo Le Fratte",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Sant'Angelo Limosano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Sant'Angelo Lodigiano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Sant'Angelo Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Sant'Angelo Muxaro",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Sant'Angelo Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Sant'Angelo a Cupolo",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Sant'Angelo a Fasanella",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sant'Angelo a Scala",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Sant'Angelo all'Esca",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Sant'Angelo d'Alife",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Sant'Angelo dei Lombardi",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Sant'Angelo del Pesco",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Sant'Angelo di Brolo",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Sant'Angelo di Piove di Sacco",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Sant'Angelo in Pontano",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Sant'Angelo in Vado",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Sant'Anna Arresi",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sant'Anna d'Alfaedo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Sant'Antimo",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Sant'Antioco",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sant'Antonino di Susa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Sant'Antonio Abate",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Sant'Antonio di Gallura",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Sant'Apollinare",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Sant'Arcangelo",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Sant'Arcangelo Trimonte",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Sant'Arpino",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Sant'Arsenio",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sant'Egidio alla Vibrata",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Sant'Egidio del Monte Albino",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sant'Elena",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Sant'Elena Sannita",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Sant'Elia Fiumerapido",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Sant'Elia a Pianisi",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Sant'Elpidio a Mare",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Sant'Eufemia a Maiella",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Sant'Eufemia d'Aspromonte",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Sant'Eusanio Forconese",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Sant'Eusanio del Sangro",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Sant'Ilario d'Enza",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Sant'Ilario dello Ionio",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Sant'Ippolito",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Sant'Olcese",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Sant'Omero",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Sant'Omobono Terme",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sant'Onofrio",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Sant'Oreste",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Sant'Orsola Terme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Sant'Urbano",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Santa Brigida",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Santa Caterina Albanese",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Santa Caterina Villarmosa",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Santa Caterina d'Este",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Santa Caterina dello Ionio",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Santa Cesarea Terme",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Santa Cristina Gela",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Santa Cristina Valgardena",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Santa Cristina d'Aspromonte",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Santa Cristina e Bissone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Santa Croce Camerina",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Santa Croce del Sannio",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Santa Croce di Magliano",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Santa Croce sull'Arno",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Santa Domenica Talao",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Santa Domenica Vittoria",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Santa Elisabetta",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Santa Fiora",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Santa Flavia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Santa Giuletta",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Santa Giusta",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Santa Giustina",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Santa Giustina in Colle",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Santa Luce",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Santa Lucia del Mela",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Santa Lucia di Piave",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Santa Lucia di Serino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Santa Margherita Ligure",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Santa Margherita di Belice",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Santa Margherita di Staffora",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Santa Maria Capua Vetere",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Santa Maria Coghinas",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Santa Maria Hoe'",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Santa Maria Imbaro",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Santa Maria Maggiore",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Santa Maria Nuova",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Santa Maria a Monte",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Santa Maria a Vico",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Santa Maria del Cedro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Santa Maria del Molise",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Santa Maria della Versa",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Santa Maria di Licodia",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Santa Maria di Sala",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Santa Maria la Carita'",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Santa Maria la Fossa",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Santa Maria la Longa",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Santa Marina",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Santa Marina Salina",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Santa Marinella",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Santa Ninfa",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Santa Paolina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Santa Severina",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Santa Sofia",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Santa Sofia d'Epiro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Santa Teresa Gallura",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Santa Teresa di Riva",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Santa Venerina",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Santa Vittoria d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Santa Vittoria in Matenano",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Santadi",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Santarcangelo di Romagna",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Sante Marie",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Santena",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Santeramo in Colle",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Santhia'",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Santi Cosma e Damiano",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Santo Stefano Belbo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Santo Stefano Lodigiano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Santo Stefano Quisquina",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Santo Stefano Roero",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Santo Stefano Ticino",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Santo Stefano al Mare",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Santo Stefano d'Aveto",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Santo Stefano del Sole",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Santo Stefano di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Santo Stefano di Camastra",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Santo Stefano di Magra",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Santo Stefano di Rogliano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Santo Stefano di Sessanio",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Santo Stefano in Aspromonte",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Santomenna",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Santopadre",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Santorso",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Santu Lussurgiu",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sanza",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sanzeno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Saonara",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Saponara",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Sappada",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Sapri",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Saracena",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Saracinesco",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Sarcedo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Sarconi",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Sardara",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sardigliano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Sarego",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Sarentino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Sarezzano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Sarezzo",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sarmato",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Sarmede",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Sarnano",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Sarnico",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sarno",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sarnonico",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Saronno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Sarre",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Sarroch",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Sarsina",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Sarteano",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Sartirana Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Sarule",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Sarzana",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Sassano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sassari",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Sassello",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Sassetta",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Sassinoro",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Sasso Marconi",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Sasso di Castalda",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Sassocorvaro Auditore",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Sassofeltrio",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Sassoferrato",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Sassuolo",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Satriano",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Satriano di Lucania",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Sauris",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Sauze d'Oulx",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Sauze di Cesana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Sava",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Savelli",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Saviano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Savigliano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Savignano Irpino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Savignano sul Panaro",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Savignano sul Rubicone",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Savignone",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Saviore dell'Adamello",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Savoca",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Savogna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Savogna d'Isonzo",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Savoia di Lucania",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Savona",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Scafa",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Scafati",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Scagnello",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Scala",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Scala Coeli",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Scaldasole",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Scalea",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Scalenghe",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Scaletta Zanclea",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Scampitella",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Scandale",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Scandiano",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Scandicci",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Scandolara Ravara",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Scandolara Ripa d'Oglio",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Scandriglia",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Scanno",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Scano di Montiferro",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Scansano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Scanzano Jonico",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Scanzorosciate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Scapoli",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Scarlino",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Scarmagno",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Scarnafigi",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Scarperia e San Piero",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Scena",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Scerni",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Scheggia e Pascelupo",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Scheggino",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Schiavi di Abruzzo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Schiavon",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Schignano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Schilpario",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Schio",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Schivenoglia",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Sciacca",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Sciara",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Scicli",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Scido",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Scigliano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Scilla",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Scillato",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Sciolze",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Scisciano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Sclafani Bagni",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Scontrone",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Scopa",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Scopello",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Scoppito",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Scordia",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Scorrano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Scorze'",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Scurcola Marsicana",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Scurelle",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Scurzolengo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Seborga",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Secinaro",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Secli'",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Secugnago",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Sedegliano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Sedico",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Sedilo",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sedini",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Sedriano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Sedrina",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sefro",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Segariu",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Seggiano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Segni",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Segonzano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Segrate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Segusino",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Selargius",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Selci",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Selegas",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sella Giudicarie",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Sellano",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Sellero",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sellia",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Sellia Marina",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Selva dei Molini",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Selva di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Selva di Progno",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Selva di Val Gardena",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Selvazzano Dentro",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Selvino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Semestene",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Semiana",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Seminara",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Semproniano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Senago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Senale-San Felice",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Senales",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Seneghe",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Senerchia",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Seniga",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Senigallia",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Senis",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Senise",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Senna Comasco",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Senna Lodigiana",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Sennariolo",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sennori",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Senorbi'",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sepino",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Sequals",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Seravezza",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Serdiana",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Seregno",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Seren del Grappa",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Sergnano",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Seriate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Serina",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Serino",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Serle",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sermide e Felonica",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Sermoneta",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Sernaglia della Battaglia",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Sernio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Serole",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Serra Ricco'",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Serra San Bruno",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Serra San Quirico",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Serra Sant'Abbondio",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Serra d'Aiello",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Serra de' Conti",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Serracapriola",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Serradifalco",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Serralunga d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Serralunga di Crea",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Serramanna",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Serramazzoni",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Serramezzana",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Serramonacesca",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Serrapetrona",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Serrara Fontana",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Serrastretta",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Serrata",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Serravalle Langhe",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Serravalle Pistoiese",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Serravalle Scrivia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Serravalle Sesia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Serravalle a Po",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Serravalle di Chienti",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Serre",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Serrenti",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Serri",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Serrone",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Sersale",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Servigliano",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Sessa Aurunca",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Sessa Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Sessame",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Sessano del Molise",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Sesta Godano",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Sestino",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Sesto",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Sesto Calende",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Sesto Campano",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Sesto Fiorentino",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Sesto San Giovanni",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Sesto al Reghena",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Sesto ed Uniti",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Sestola",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Sestri Levante",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Sestriere",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Sestu",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Settala",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Settefrati",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Setteville",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Settime",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Settimo Milanese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Settimo Rottaro",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Settimo San Pietro",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Settimo Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Settimo Vittone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Settingiano",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Setzu",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Seui",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Seulo",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Seveso",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Sezzadio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Sezze",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Sfruz",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Sgonico",
    provinceAbbr: "TS",
    province: "Trieste",
  },
  {
    name: "Sgurgola",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Siamaggiore",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Siamanna",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Siano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Siapiccia",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sicignano degli Alburni",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Siculiana",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Siddi",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Siderno",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Siena",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Sigillo",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Signa",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Silandro",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Silanus",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Silea",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Siligo",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Siliqua",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Silius",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Sillano Giuncugnano",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Sillavengo",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Silvano Pietra",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Silvano d'Orba",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Silvi",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Simala",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Simaxis",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Simbario",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Simeri Crichi",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Sinagra",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Sinalunga",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Sindia",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Sini",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sinio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Siniscola",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Sinnai",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Sinopoli",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Siracusa",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Sirignano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Siris",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sirmione",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sirolo",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Sirone",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Sirtori",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Sissa Trecasali",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Siurgus Donigala",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Siziano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Sizzano",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Sluderno",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Smerillo",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Soave",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Socchieve",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Soddi'",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sogliano Cavour",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Sogliano al Rubicone",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Soglio",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Soiano del Lago",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Solagna",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Solarino",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Solaro",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Solarolo",
    provinceAbbr: "RA",
    province: "Ravenna",
  },
  {
    name: "Solarolo Rainerio",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Solarussa",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Solbiate Arno",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Solbiate Olona",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Solbiate con Cagno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Soldano",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Soleminis",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Solero",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Solesino",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Soleto",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Solferino",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Soliera",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Solignano",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Solofra",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Solonghello",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Solopaca",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Solto Collina",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Solza",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Somaglia",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Somano",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Somma Lombardo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Somma Vesuviana",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Sommacampagna",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Sommariva Perno",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Sommariva del Bosco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Sommatino",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Sommo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Sona",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Soncino",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Sondalo",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Sondrio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Songavazzo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sonico",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sonnino",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Sora",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Soraga di Fassa",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Soragna",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Sorano",
    provinceAbbr: "GR",
    province: "Grosseto",
  },
  {
    name: "Sorbo San Basile",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Sorbo Serpico",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Sorbolo Mezzani",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Sordevolo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Sordio",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Soresina",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Sorga'",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Sorgono",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Sori",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Sorianello",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Soriano Calabro",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Soriano nel Cimino",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Sorico",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Soriso",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Sorisole",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sormano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Sorradile",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Sorrento",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Sorso",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Sortino",
    provinceAbbr: "SR",
    province: "Siracusa",
  },
  {
    name: "Sospiro",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Sospirolo",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Sossano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Sostegno",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Sotto il Monte Giovanni XXIII",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sover",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Soverato",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Sovere",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Soveria Mannelli",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Soveria Simeri",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Soverzene",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Sovicille",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Sovico",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Sovizzo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Sovramonte",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Sozzago",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Spadafora",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Spadola",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Sparanise",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Sparone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Specchia",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Spello",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Sperlinga",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Sperlonga",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Sperone",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Spessa",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Spezzano Albanese",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Spezzano della Sila",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Spiazzo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Spigno Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Spigno Saturnia",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Spilamberto",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Spilimbergo",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Spilinga",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Spinadesco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Spinazzola",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Spinea",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Spineda",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Spinete",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Spineto Scrivia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Spinetoli",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Spino d'Adda",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Spinone al Lago",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Spinoso",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Spirano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Spoleto",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Spoltore",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Spongano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Spormaggiore",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Sporminore",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Spotorno",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Spresiano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Spriana",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Squillace",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Squinzano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Staffolo",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Stagno Lombardo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Staiti",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Staletti'",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Stanghella",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Staranzano",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Statte",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Stazzano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Stazzema",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Stazzona",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Stefanaconi",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Stella",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Stella Cilento",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Stellanello",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Stelvio",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Stenico",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Sternatia",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Stezzano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Stienta",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Stigliano",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Stignano",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Stilo",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Stimigliano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Stintino",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Stio",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Stornara",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Stornarella",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Storo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Stra",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Stradella",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Strambinello",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Strambino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Strangolagalli",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Stregna",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Strembo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Stresa",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Strevi",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Striano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Strona",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Stroncone",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Strongoli",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Stroppiana",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Stroppo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Strozza",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sturno",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Suardi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Subbiano",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Subiaco",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Succivo",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Sueglio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Suelli",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Suello",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Suisio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Sulbiate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Sulmona",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Sulzano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Sumirago",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Summonte",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Suni",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Suno",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Supersano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Supino",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Surano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Surbo",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Susa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Susegana",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Sustinente",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Sutera",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Sutri",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Sutrio",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Suvereto",
    provinceAbbr: "LI",
    province: "Livorno",
  },
  {
    name: "Suzzara",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Taceno",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Tadasuni",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Taggia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Tagliacozzo",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Taglio di Po",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Tagliolo Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Taibon Agordino",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Taino",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Taipana",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Talamello",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Talamona",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Talana",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Taleggio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Talla",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Talmassons",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Tambre",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Taormina",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Tarano",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Taranta Peligna",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Tarantasca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Taranto",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Tarcento",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Tarquinia",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Tarsia",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Tartano",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Tarvisio",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Tarzo",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Tassarolo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Taurano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Taurasi",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Taurianova",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Taurisano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Tavagnacco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Tavagnasco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Tavazzano con Villavesco",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Tavenna",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Taverna",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Tavernerio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Tavernola Bergamasca",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Tavernole sul Mella",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Taviano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Tavigliano",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Tavoleto",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Tavullia",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Teana",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Teano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Teggiano",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Teglio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Teglio Veneto",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Telese Terme",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Telgate",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Telti",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Telve",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Telve di Sopra",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Tempio Pausania",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Temu'",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Tenna",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Tenno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Teolo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Teora",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Teramo",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Terdobbiate",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Terelle",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Terento",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Terenzo",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Tergu",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Terlano",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Terlizzi",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Terme Vigliatore",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Termeno sulla strada del vino",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Termini Imerese",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Termoli",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Ternate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Ternengo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Terni",
    provinceAbbr: "TR",
    province: "Terni",
  },
  {
    name: "Terno d'Isola",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Terracina",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Terragnolo",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Terralba",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Terranova Sappo Minulio",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Terranova da Sibari",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Terranova dei Passerini",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Terranova di Pollino",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Terranuova Bracciolini",
    provinceAbbr: "AR",
    province: "Arezzo",
  },
  {
    name: "Terrasini",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Terrassa Padovana",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Terravecchia",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Terrazzo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Terre Roveresche",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Terre d'Adige",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Terre del Reno",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Terricciola",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Terruggia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Tertenia",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Terzigno",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Terzo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Terzo d'Aquileia",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Terzolas",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Terzorio",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Tesero",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Tesimo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Tessennano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Testico",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Teti",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Teulada",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Teverola",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Tezze sul Brenta",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Thiene",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Thiesi",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Tiana",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Ticengo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Ticineto",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Tiggiano",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Tiglieto",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Tigliole",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Tignale",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Tinnura",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Tione degli Abruzzi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Tione di Trento",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Tirano",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Tires",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Tiriolo",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Tirolo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Tissi",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Tito",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Tivoli",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Tizzano Val Parma",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Toano",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Tocco Caudio",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Tocco da Casauria",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Toceno",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Todi",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Toffia",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Toirano",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Tolentino",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Tolfa",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Tollegno",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Tollo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Tolmezzo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Tolve",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Tombolo",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Ton",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Tonara",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Tonco",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Tonezza del Cimone",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Tora e Piccilli",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Torano Castello",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Torano Nuovo",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Torbole Casaglia",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Torcegno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Torchiara",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Torchiarolo",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Torella dei Lombardi",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Torella del Sannio",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Torgiano",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Torgnon",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Torino",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Torino di Sangro",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Toritto",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Torlino Vimercati",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Tornaco",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Tornareccio",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Tornata",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Tornimparte",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Torno",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Tornolo",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Toro",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Torpe'",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Torraca",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Torralba",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Torrazza Coste",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Torrazza Piemonte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Torrazzo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Torre Annunziata",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Torre Beretti e Castellaro",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Torre Boldone",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Torre Bormida",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Torre Cajetani",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Torre Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Torre Le Nocelle",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Torre Mondovi'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Torre Orsaia",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Torre Pallavicina",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Torre Pellice",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Torre San Giorgio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Torre San Patrizio",
    provinceAbbr: "FM",
    province: "Fermo",
  },
  {
    name: "Torre Santa Susanna",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Torre d'Arese",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Torre d'Isola",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Torre de' Busi",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Torre de' Negri",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Torre de' Passeri",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Torre de' Picenardi",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Torre de' Roveri",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Torre del Greco",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Torre di Mosto",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Torre di Ruggiero",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Torre di Santa Maria",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Torreano",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Torrebelvicino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Torrebruna",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Torrecuso",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Torreglia",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Torregrotta",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Torremaggiore",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Torrenova",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Torresina",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Torretta",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Torrevecchia Pia",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Torrevecchia Teatina",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Torri del Benaco",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Torri di Quartesolo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Torri in Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Torrice",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Torricella",
    provinceAbbr: "TA",
    province: "Taranto",
  },
  {
    name: "Torricella Peligna",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Torricella Sicura",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Torricella Verzate",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Torricella del Pizzo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Torricella in Sabina",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Torriglia",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Torrile",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Torrioni",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Torrita Tiberina",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Torrita di Siena",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Tortoli'",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Tortona",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Tortora",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Tortorella",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Tortoreto",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Tortorici",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Torviscosa",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Toscolano-Maderno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Tossicia",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Tovo San Giacomo",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Tovo di Sant'Agata",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Trabia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Tradate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Tramatza",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Trambileno",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Tramonti",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Tramonti di Sopra",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Tramonti di Sotto",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Tramutola",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Trana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Trani",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Traona",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Trapani",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Trappeto",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Trarego Viggiona",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Trasacco",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Trasaghis",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Trasquera",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Tratalias",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Travaco' Siccomario",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Travagliato",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Travedona-Monate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Traversella",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Traversetolo",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Traves",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Travesio",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Travo",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Tre Ville",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Trebaseleghe",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Trebisacce",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Trecase",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Trecastagni",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Trecastelli",
    provinceAbbr: "AN",
    province: "Ancona",
  },
  {
    name: "Trecate",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Trecchina",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Trecenta",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Tredozio",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Treglio",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Tregnago",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Treia",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Treiso",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Tremestieri Etneo",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Tremezzina",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Tremosine sul Garda",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Trentinara",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Trento",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Trentola Ducenta",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Trenzano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Treppo Grande",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Treppo Ligosullo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Trepuzzi",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Trequanda",
    provinceAbbr: "SI",
    province: "Siena",
  },
  {
    name: "Tresana",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Trescore Balneario",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Trescore Cremasco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Tresignana",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Tresivio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Tresnuraghes",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Trevenzuolo",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Trevi",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Trevi nel Lazio",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Trevico",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Treviglio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Trevignano",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Trevignano Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Treville",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Treviolo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Treviso",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Treviso Bresciano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Trezzano Rosa",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Trezzano sul Naviglio",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Trezzo Tinella",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Trezzo sull'Adda",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Trezzone",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Tribano",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Tribiano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Tribogna",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Tricarico",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Tricase",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Tricerro",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Tricesimo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Triei",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Trieste",
    provinceAbbr: "TS",
    province: "Trieste",
  },
  {
    name: "Triggiano",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Trigolo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Trinita'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Trinita' d'Agultu e Vignola",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Trinitapoli",
    provinceAbbr: "BT",
    province: "Barletta-Andria-Trani",
  },
  {
    name: "Trino",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Triora",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Tripi",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Trisobbio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Trissino",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Triuggio",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Trivento",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Trivigliano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Trivignano Udinese",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Trivigno",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Trivolzio",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Trodena nel parco naturale",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Trofarello",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Troia",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Troina",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Tromello",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Trontano",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Tronzano Lago Maggiore",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Tronzano Vercellese",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Tropea",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Trovo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Truccazzano",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Tubre",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Tufara",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Tufillo",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Tufino",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Tufo",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Tuglie",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Tuili",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Tula",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Tuoro sul Trasimeno",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Turania",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Turano Lodigiano",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Turate",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Turbigo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Turi",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Turri",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Turriaco",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Turrivalignani",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Tursi",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Tusa",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Tuscania",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Ubiale Clanezzo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Uboldo",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Ucria",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Udine",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Ugento",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Uggiano la Chiesa",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Uggiate con Ronago",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Ula' Tirso",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Ulassai",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Ultimo",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Umbertide",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Umbriatico",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Urago d'Oglio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Uras",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Urbana",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Urbania",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Urbe",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Urbino",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Urbisaglia",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Urgnano",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Uri",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Ururi",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Urzulei",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Uscio",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Usellus",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Usini",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Usmate Velate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Ussana",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Ussaramanna",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Ussassai",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Usseaux",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Usseglio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Ussita",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Ustica",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Uta",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Uzzano",
    provinceAbbr: "PT",
    province: "Pistoia",
  },
  {
    name: "Vaccarizzo Albanese",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Vacone",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Vacri",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Vadena",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Vado Ligure",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Vagli Sotto",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Vaglia",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Vaglio Basilicata",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Vaglio Serra",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Vaiano",
    provinceAbbr: "PO",
    province: "Prato",
  },
  {
    name: "Vaiano Cremasco",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Vaie",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vailate",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Vairano Patenora",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Vajont",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Val Brembilla",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Val Liona",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Val Masino",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Val Rezzo",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Val della Torre",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Val di Chy",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Val di Nizza",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Val di Vizze",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Val di Zoldo",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Valbondione",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Valbrembo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Valbrenta",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Valbrevenna",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Valbrona",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Valchiusa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Valdagno",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Valdaone",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Valdaora",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Valdastico",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Valdengo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Valderice",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Valdidentro",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Valdieri",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Valdilana",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Valdina",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Valdisotto",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Valdobbiadene",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Valduggia",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Valeggio",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Valeggio sul Mincio",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Valentano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Valenza",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Valenzano",
    provinceAbbr: "BA",
    province: "Bari",
  },
  {
    name: "Valera Fratta",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Valfabbrica",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Valfenera",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Valfloriana",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Valfornace",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Valfurva",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Valganna",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Valgioie",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Valgoglio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Valgrana",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Valgreghentino",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Valgrisenche",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Valguarnera Caropepe",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Vallada Agordina",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Vallanzengo",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Vallarsa",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Vallata",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Valle Agricola",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Valle Aurina",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Valle Cannobina",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Valle Castellana",
    provinceAbbr: "TE",
    province: "Teramo",
  },
  {
    name: "Valle Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Valle Salimbene",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Valle San Nicolao",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Valle dell'Angelo",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Valle di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Valle di Casies",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Valle di Maddaloni",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Vallebona",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Vallecorsa",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Vallecrosia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Valledolmo",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Valledoria",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Vallefiorita",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Vallefoglia",
    provinceAbbr: "PU",
    province: "Pesaro e Urbino",
  },
  {
    name: "Vallelaghi",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Vallelonga",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Vallelunga Pratameno",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Vallemaio",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Vallepietra",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Vallerano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Vallermosa",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Vallerotonda",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Vallesaccarda",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Valleve",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Valli del Pasubio",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Vallinfreda",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Vallio Terme",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Vallo Torinese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vallo della Lucania",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Vallo di Nera",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Valloriate",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Valmacca",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Valmadrera",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Valmontone",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Valmorea",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Valmozzola",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Valnegra",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Valpelline",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Valperga",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Valprato Soana",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Valsamoggia",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Valsavarenche",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Valsinni",
    provinceAbbr: "MT",
    province: "Matera",
  },
  {
    name: "Valsolda",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Valstrona",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Valtopina",
    provinceAbbr: "PG",
    province: "Perugia",
  },
  {
    name: "Valtorta",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Valtournenche",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Valva",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Valvarrone",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Valvasone Arzene",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Valverde",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Valvestino",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Vandoies",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Vanzaghello",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vanzago",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vanzone con San Carlo",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Vaprio d'Adda",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vaprio d'Agogna",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Varallo",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Varallo Pombia",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Varano Borghi",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Varano de' Melegari",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Varapodio",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Varazze",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Varco Sabino",
    provinceAbbr: "RI",
    province: "Rieti",
  },
  {
    name: "Varedo",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Varenna",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Varese",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Varese Ligure",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Varisella",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Varmo",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Varna",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Varsi",
    provinceAbbr: "PR",
    province: "Parma",
  },
  {
    name: "Varzi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Varzo",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Vasanello",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Vasia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Vasto",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Vastogirardi",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Vauda Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vazzano",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Vazzola",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Vecchiano",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Vedano Olona",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Vedano al Lambro",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Vedelago",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Vedeseta",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Veduggio con Colzano",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Veggiano",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Veglie",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Veglio",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Vejano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Veleso",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Velezzo Lomellina",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Velletri",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Vellezzo Bellini",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Velo Veronese",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Velo d'Astico",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Velturno",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Venafro",
    provinceAbbr: "IS",
    province: "Isernia",
  },
  {
    name: "Venaria Reale",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Venarotta",
    provinceAbbr: "AP",
    province: "Ascoli Piceno",
  },
  {
    name: "Venasca",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Venaus",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vendone",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Venegono Inferiore",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Venegono Superiore",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Venetico",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Venezia",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Veniano",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Venosa",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Ventasso",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Venticano",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Ventimiglia",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Ventimiglia di Sicilia",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Ventotene",
    provinceAbbr: "LT",
    province: "Latina",
  },
  {
    name: "Venzone",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Verano",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Verano Brianza",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Verbania",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Verbicaro",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Vercana",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Verceia",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Vercelli",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Vercurago",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Verdellino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Verdello",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Verderio",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Verduno",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vergato",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Verghereto",
    provinceAbbr: "FC",
    province: "Forli'-Cesena",
  },
  {
    name: "Vergiate",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Vermezzo con Zelo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vermiglio",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Vernante",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vernasca",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Vernate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vernazza",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Vernio",
    provinceAbbr: "PO",
    province: "Prato",
  },
  {
    name: "Vernole",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Verolanuova",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Verolavecchia",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Verolengo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Veroli",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Verona",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Veronella",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Verrayes",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Verre's",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Verretto",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Verrone",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Verrua Po",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Verrua Savoia",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vertemate con Minoprio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Vertova",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Verucchio",
    provinceAbbr: "RN",
    province: "Rimini",
  },
  {
    name: "Vervio",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Verzegnis",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Verzino",
    provinceAbbr: "KR",
    province: "Crotone",
  },
  {
    name: "Verzuolo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vescovana",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Vescovato",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Vesime",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Vespolate",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Vessalico",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Vestenanova",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Vestigne'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vestone",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Vetralla",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Vetto",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Vezza d'Alba",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vezza d'Oglio",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Vezzano Ligure",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Vezzano sul Crostolo",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Vezzi Portio",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Viadana",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Viadanica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Viagrande",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Viale",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Vialfre'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Viano",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Viareggio",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Viarigi",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Vibo Valentia",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Vibonati",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Vicalvi",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Vicari",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Vicchio",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Vicenza",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Vico Equense",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Vico del Gargano",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Vico nel Lazio",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Vicoforte",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vicoli",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Vicolungo",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Vicopisano",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Vicovaro",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Viddalba",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Vidigulfo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Vidor",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Vidracco",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vieste",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Vietri di Potenza",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Vietri sul Mare",
    provinceAbbr: "SA",
    province: "Salerno",
  },
  {
    name: "Vigano San Martino",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Vigano'",
    provinceAbbr: "LC",
    province: "Lecco",
  },
  {
    name: "Vigarano Mainarda",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Vigasio",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Vigevano",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Viggianello",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Viggiano",
    provinceAbbr: "PZ",
    province: "Potenza",
  },
  {
    name: "Viggiu'",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Vigliano Biellese",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Vigliano d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Vignale Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Vignanello",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Vignate",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vignola",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Vignola-Falesina",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Vignole Borbera",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Vignolo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vignone",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Vigo di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Vigodarzere",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Vigolo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Vigolzone",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Vigone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vigonovo",
    provinceAbbr: "VE",
    province: "Venezia",
  },
  {
    name: "Vigonza",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Viguzzolo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Villa Bartolomea",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Villa Basilica",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Villa Biscossi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Villa Carcina",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Villa Castelli",
    provinceAbbr: "BR",
    province: "Brindisi",
  },
  {
    name: "Villa Celiera",
    provinceAbbr: "PE",
    province: "Pescara",
  },
  {
    name: "Villa Collemandina",
    provinceAbbr: "LU",
    province: "Lucca",
  },
  {
    name: "Villa Cortese",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Villa Estense",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Villa Faraldi",
    provinceAbbr: "IM",
    province: "Imperia",
  },
  {
    name: "Villa Guardia",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Villa Lagarina",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Villa Latina",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Villa Literno",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Villa Minozzo",
    provinceAbbr: "RE",
    province: "Reggio Emilia",
  },
  {
    name: "Villa San Giovanni",
    provinceAbbr: "RC",
    province: "Reggio Calabria",
  },
  {
    name: "Villa San Giovanni in Tuscia",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Villa San Pietro",
    provinceAbbr: "CA",
    province: "Cagliari",
  },
  {
    name: "Villa San Secondo",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Villa Sant'Angelo",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Villa Sant'Antonio",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Villa Santa Lucia",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Villa Santa Lucia degli Abruzzi",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Villa Santa Maria",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Villa Santina",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Villa Santo Stefano",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Villa Verde",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Villa d'Adda",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Villa d'Alme'",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Villa d'Ogna",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Villa del Bosco",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Villa del Conte",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Villa di Briano",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Villa di Chiavenna",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Villa di Serio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Villa di Tirano",
    provinceAbbr: "SO",
    province: "Sondrio",
  },
  {
    name: "Villabassa",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Villabate",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Villachiara",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Villacidro",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villadeati",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Villadose",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Villadossola",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Villafalletto",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Villafranca Padovana",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Villafranca Piemonte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villafranca Sicula",
    provinceAbbr: "AG",
    province: "Agrigento",
  },
  {
    name: "Villafranca Tirrena",
    provinceAbbr: "ME",
    province: "Messina",
  },
  {
    name: "Villafranca d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Villafranca di Verona",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Villafranca in Lunigiana",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Villafrati",
    provinceAbbr: "PA",
    province: "Palermo",
  },
  {
    name: "Villaga",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Villagrande Strisaili",
    provinceAbbr: "NU",
    province: "Nuoro",
  },
  {
    name: "Villalago",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Villalba",
    provinceAbbr: "CL",
    province: "Caltanissetta",
  },
  {
    name: "Villalfonsina",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Villalvernia",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Villamagna",
    provinceAbbr: "CH",
    province: "Chieti",
  },
  {
    name: "Villamaina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Villamar",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villamarzana",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Villamassargia",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villamiroglio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Villandro",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Villanova Biellese",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Villanova Canavese",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villanova Marchesana",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Villanova Mondovi'",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Villanova Monferrato",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Villanova Monteleone",
    provinceAbbr: "SS",
    province: "Sassari",
  },
  {
    name: "Villanova Solaro",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Villanova Truschedu",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Villanova Tulo",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villanova d'Albenga",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Villanova d'Ardenghi",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Villanova d'Asti",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Villanova del Battista",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Villanova del Ghebbo",
    provinceAbbr: "RO",
    province: "Rovigo",
  },
  {
    name: "Villanova del Sillaro",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Villanova di Camposampiero",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Villanova sull'Arda",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Villanovaforru",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villanovafranca",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villanterio",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Villanuova sul Clisi",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Villaperuccio",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villapiana",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Villaputzu",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villar Dora",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villar Focchiardo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villar Pellice",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villar Perosa",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villar San Costanzo",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Villarbasse",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villarboit",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Villareggia",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villaricca",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Villaromagnano",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Villarosa",
    provinceAbbr: "EN",
    province: "Enna",
  },
  {
    name: "Villasalto",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villasanta",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Villasimius",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villasor",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villaspeciosa",
    provinceAbbr: "SU",
    province: "Sud Sardegna",
  },
  {
    name: "Villastellone",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Villata",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Villaurbana",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Villavallelonga",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Villaverla",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Ville d'Anaunia",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Ville di Fiemme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Villeneuve",
    provinceAbbr: "AO",
    province: "Aosta",
  },
  {
    name: "Villesse",
    provinceAbbr: "GO",
    province: "Gorizia",
  },
  {
    name: "Villetta Barrea",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Villette",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Villimpenta",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Villongo",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Villorba",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Vilminore di Scalve",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Vimercate",
    provinceAbbr: "MB",
    province: "Monza e della Brianza",
  },
  {
    name: "Vimodrone",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vinadio",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vinchiaturo",
    provinceAbbr: "CB",
    province: "Campobasso",
  },
  {
    name: "Vinchio",
    provinceAbbr: "AT",
    province: "Asti",
  },
  {
    name: "Vinci",
    provinceAbbr: "FI",
    province: "Firenze",
  },
  {
    name: "Vinovo",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vinzaglio",
    provinceAbbr: "NO",
    province: "Novara",
  },
  {
    name: "Viola",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Vione",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Vipiteno",
    provinceAbbr: "BZ",
    province: "Bolzano",
  },
  {
    name: "Virle Piemonte",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Visano",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Vische",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Visciano",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Visco",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Visone",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Visso",
    provinceAbbr: "MC",
    province: "Macerata",
  },
  {
    name: "Vistarino",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Vistrorio",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vita",
    provinceAbbr: "TP",
    province: "Trapani",
  },
  {
    name: "Viterbo",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Viticuso",
    provinceAbbr: "FR",
    province: "Frosinone",
  },
  {
    name: "Vito d'Asio",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Vitorchiano",
    provinceAbbr: "VT",
    province: "Viterbo",
  },
  {
    name: "Vittoria",
    provinceAbbr: "RG",
    province: "Ragusa",
  },
  {
    name: "Vittorio Veneto",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Vittorito",
    provinceAbbr: "AQ",
    province: "L'Aquila",
  },
  {
    name: "Vittuone",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vitulano",
    provinceAbbr: "BN",
    province: "Benevento",
  },
  {
    name: "Vitulazio",
    provinceAbbr: "CE",
    province: "Caserta",
  },
  {
    name: "Viu'",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vivaro",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Vivaro Romano",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Viverone",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Vizzini",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Vizzola Ticino",
    provinceAbbr: "VA",
    province: "Varese",
  },
  {
    name: "Vizzolo Predabissi",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Vo'",
    provinceAbbr: "PD",
    province: "Padova",
  },
  {
    name: "Vobarno",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Vobbia",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Vocca",
    provinceAbbr: "VC",
    province: "Vercelli",
  },
  {
    name: "Vodo Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Voghera",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Voghiera",
    provinceAbbr: "FE",
    province: "Ferrara",
  },
  {
    name: "Vogogna",
    provinceAbbr: "VB",
    province: "Verbano-Cusio-Ossola",
  },
  {
    name: "Volano",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Volla",
    provinceAbbr: "NA",
    province: "Napoli",
  },
  {
    name: "Volongo",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Volpago del Montello",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Volpara",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Volpedo",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Volpeglino",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Volpiano",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Volta Mantovana",
    provinceAbbr: "MN",
    province: "Mantova",
  },
  {
    name: "Voltaggio",
    provinceAbbr: "AL",
    province: "Alessandria",
  },
  {
    name: "Voltago Agordino",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Volterra",
    provinceAbbr: "PI",
    province: "Pisa",
  },
  {
    name: "Voltido",
    provinceAbbr: "CR",
    province: "Cremona",
  },
  {
    name: "Volturara Appula",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Volturara Irpina",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Volturino",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Volvera",
    provinceAbbr: "TO",
    province: "Torino",
  },
  {
    name: "Vottignasco",
    provinceAbbr: "CN",
    province: "Cuneo",
  },
  {
    name: "Zaccanopoli",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Zafferana Etnea",
    provinceAbbr: "CT",
    province: "Catania",
  },
  {
    name: "Zagarise",
    provinceAbbr: "CZ",
    province: "Catanzaro",
  },
  {
    name: "Zagarolo",
    provinceAbbr: "RM",
    province: "Roma",
  },
  {
    name: "Zambrone",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
  {
    name: "Zandobbio",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Zane'",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Zanica",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Zapponeta",
    provinceAbbr: "FG",
    province: "Foggia",
  },
  {
    name: "Zavattarello",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Zeccone",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Zeddiani",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Zelbio",
    provinceAbbr: "CO",
    province: "Como",
  },
  {
    name: "Zelo Buon Persico",
    provinceAbbr: "LO",
    province: "Lodi",
  },
  {
    name: "Zeme",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Zenevredo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Zenson di Piave",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Zerba",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Zerbo",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Zerbolo'",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Zerfaliu",
    provinceAbbr: "OR",
    province: "Oristano",
  },
  {
    name: "Zeri",
    provinceAbbr: "MS",
    province: "Massa-Carrara",
  },
  {
    name: "Zermeghedo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Zero Branco",
    provinceAbbr: "TV",
    province: "Treviso",
  },
  {
    name: "Zevio",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Ziano Piacentino",
    provinceAbbr: "PC",
    province: "Piacenza",
  },
  {
    name: "Ziano di Fiemme",
    provinceAbbr: "TN",
    province: "Trento",
  },
  {
    name: "Zibido San Giacomo",
    provinceAbbr: "MI",
    province: "Milano",
  },
  {
    name: "Zignago",
    provinceAbbr: "SP",
    province: "La Spezia",
  },
  {
    name: "Zimella",
    provinceAbbr: "VR",
    province: "Verona",
  },
  {
    name: "Zimone",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Zinasco",
    provinceAbbr: "PV",
    province: "Pavia",
  },
  {
    name: "Zoagli",
    provinceAbbr: "GE",
    province: "Genova",
  },
  {
    name: "Zocca",
    provinceAbbr: "MO",
    province: "Modena",
  },
  {
    name: "Zogno",
    provinceAbbr: "BG",
    province: "Bergamo",
  },
  {
    name: "Zola Predosa",
    provinceAbbr: "BO",
    province: "Bologna",
  },
  {
    name: "Zollino",
    provinceAbbr: "LE",
    province: "Lecce",
  },
  {
    name: "Zone",
    provinceAbbr: "BS",
    province: "Brescia",
  },
  {
    name: "Zoppe' di Cadore",
    provinceAbbr: "BL",
    province: "Belluno",
  },
  {
    name: "Zoppola",
    provinceAbbr: "PN",
    province: "Pordenone",
  },
  {
    name: "Zovencedo",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Zubiena",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Zuccarello",
    provinceAbbr: "SV",
    province: "Savona",
  },
  {
    name: "Zugliano",
    provinceAbbr: "VI",
    province: "Vicenza",
  },
  {
    name: "Zuglio",
    provinceAbbr: "UD",
    province: "Udine",
  },
  {
    name: "Zumaglia",
    provinceAbbr: "BI",
    province: "Biella",
  },
  {
    name: "Zumpano",
    provinceAbbr: "CS",
    province: "Cosenza",
  },
  {
    name: "Zungoli",
    provinceAbbr: "AV",
    province: "Avellino",
  },
  {
    name: "Zungri",
    provinceAbbr: "VV",
    province: "Vibo Valentia",
  },
];
