<template>
  <v-footer
    app
    absolute
    dark
    color="rgba(0, 0, 0, 0.5)"
  >
    <v-col class="text-center">
      {{ new Date().getFullYear() }} —
      <a
        id="footer-link"
        href="https://www.sorint.com/we-are-sorint/"
        target="_blank"
        rel="noopener"
        @click="() => trackEvent('LandingPage', 'ClickOnSorintLabLink', 'https://www.sorint.com/we-are-sorint/')"
      >
        <strong> SORINT.lab </strong></a>
    </v-col>
  </v-footer>
</template>

<script>
import matomoTracker from "@mixins/matomoTracker";
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Footer",
  mixins:[matomoTracker]
}
</script>

<style scoped>
#footer-link {
  color: white;
  text-decoration: none;
}

#footer-link:visited {
  text-decoration: none;
}

#footer-link:hover {
  color: white;
  text-decoration: underline;
}

#footer-link:active {
  text-decoration: underline;
}
</style>
