import api from "@services/axiosInstance";
import orderBy from "lodash/orderBy";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Schools/get_schools
 */
function getAllSchools() {
  return api.get("/schools");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Professor/put_schools_my
 */
function updateTeacherSchool(schoolCode) {
  return api.put(`/schools/my?school_code=${schoolCode}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Schools/get_schools_get_schools_by_parameter
 */
function getSchoolsByParameter(parameter, value) {
  return api.get(
    `/schools/get-schools-by-parameter?parameter=${parameter}&value=${value}`
  );
}

export default {
  getAllSchools,
  updateTeacherSchool,
  getSchoolsByParameter,
};
