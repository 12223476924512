<template>
  <div class="schools-carousel">
    <v-row no-gutters class="grey--text lighten-5 main" align="center">
      <v-col
        align="center"
        :cols="12/columns"
        v-for="(column, index) in columns"
        :key="index"
        class="school-column"
        :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}"
      >
        <v-row no-gutters justify="center" class="text-subtitle-1">
          {{ schools.at(prog + index).name }}
        </v-row>
        <v-row no-gutters justify="center" class="text-caption">
          {{ schools.at(prog + index).city }}
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "SchoolsCarousel",
  data() {
    return {
      prog: 0,
      currentOffset: 0,
      duration: 3000,
      startTime: null
    }
  },
  props: {
    schools: {
      type: Array,
      default: [{
        name: '',
        city: ''
      }]
    },
    columns: {
      type: Number,
      default: 3
    }
  },
  created() {
    window.requestAnimationFrame(this.moveSlider);
  },
  methods: {
    moveSlider(timestamp) {
      try {
        if (!this.startTime) {
          this.startTime = timestamp + 1;
        }

        if ((timestamp - this.startTime) >= this.duration) {
          this.currentOffset = 0;
          this.startTime = timestamp + 1;
          this.prog = (this.prog < this.schools.length - this.columns) ? this.prog + 1 : -(this.columns) + 1 ;
        }
        this.currentOffset = (-1) * (document.getElementsByClassName('school-column')[0].offsetWidth) * ((timestamp - this.startTime) / this.duration);
        window.requestAnimationFrame(this.moveSlider);
      } catch(err) {
        console.log(err);
      }
    }
  }
};
</script>

<style scoped>
.schools-carousel {
  width: 100%;
  overflow: hidden;
}

.main {
  width: 125%;
  min-height: 80px;
  overflow: hidden;
}
</style>