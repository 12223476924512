<template>
  <div class="landing-user-card d-flex justify-space-between mb-3"
    :class="{ 'my-min-width-300': $vuetify.breakpoint.lgAndUp }">
    <div class="d-flex flex-column justify-space-between">
      <h3 class="user-card-title">
        {{ title }}
      </h3>
      <h3 class="user-card-subtitle">
        {{ subtitle }}
      </h3>
    </div>
    <div class="d-flex align-center">
      <v-btn rounded elevation="0" min-width="44px" width="44px" height="44px" color="transparent" @click="changeRoute">
        <v-icon color="#F39200">
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "LandingUserCard",
  mixins: [matomoTracker],
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: "",
    },
    page: {
      type: String,
      default: "",
    },
  },
  methods: {
    async changeRoute() {
      await this.trackEvent("LandingPage", `ClickOnSelectTypeOfUser${this.page.toUpperCase()}`)
      this.$router.push({ name: `landing-${this.page}` })
    }
  }
};
</script>

<style scoped>
.landing-user-card {
  padding: 12px 16px;
  height: 71px;
  /* min-width: 300px; */
  max-width: 500px;
  width: 100%;
  background: #1d1c4c;
  border-radius: 12px;
}

.user-card-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #f39200;
}

.user-card-subtitle {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f4f5f6;
}

.user-card-btn {
  border-radius: 12px;
  text-transform: none;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f39200;
}

.my-min-width-300 {
  min-width: 300px;
}
</style>
