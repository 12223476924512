import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import routes from "./routes";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

Vue.use(Meta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "metaInfo",
  refreshOnceOnNavigation: true,
});

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

/* eslint-disable consistent-return */
/* eslint-disable complexity */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.accessible)) {
    next();
  }
  next();
});

export default router;
