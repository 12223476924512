<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    app
    color="#1D1C4C"
    width="100%"
    height="min(85vh,fit-content)"
    :hide-overlay="true"
    overlay-opacity="0%"
    class="custom-navigation-drawer"
  >
    <v-col class="px-5 pt-5">
      <v-row no-gutters justify="space-between">
        <v-img
          src="@/assets/landingPage/HG_LOGO.webp"
          max-height="20px"
          max-width="175px"
        />
        <v-img
          src="@/assets/landingPage/mobile-menu/x.svg"
          max-height="24px"
          max-width="24px"
          @click="closeDrawer"
        />
      </v-row>
      <v-list nav dense>
        <v-list-item-group
          v-model="currentSelection"
          active-class="selected-mobile-menu-tile"
        >
          <v-list-item
            class="mobile-menu-tile"
            color="#f39200"
            :to="{ path: '/' }"
          >
            <v-list-item-title class="menu-text"> Home </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="mobile-menu-tile"
            color="#f39200"
            target="_blank"
            @click="() => gotoCV()"
          >
            <v-list-item-title class="menu-text" target="_blank">
              Il tuo cv
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="homePageRoute"
            class="mobile-menu-tile"
            color="#f39200"
          >
            <v-list-item-title class="menu-text" @click="goToSection('corsi')">
              Corsi
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            color="#f39200"
            no-action
            active-class="selected-mobile-menu-tile"
            class="mobile-menu-tile group-tile"
          >
            <template #activator>
              <h4 class="menu-text">Altre offerte</h4>
            </template>

            <v-list-item
              v-for="(link, index) in links"
              :key="index"
              class="mobile-inner-menu-tile"
              color="#f39200"
              :href="link.url"
            >
              <v-list-item-title class="menu-text">
                {{ link.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
      <v-row no-gutters class="mb-2" align="center">
        <v-col cols="6" class="pa-0">
          <v-btn
            class="login-btn"
            rounded
            text
            data-cy="signin"
            @click="() => gotoSignin()"
          >
            Accedi
          </v-btn>
        </v-col>
        <v-col cols="6" class="pa-0" align="end">
          <v-btn
            class="register-btn"
            height="48px"
            text
            data-cy="signup"
            @click="() => gotoRegister()"
          >
            Registrati
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { pushAuthUrl, pushFormsUrl } from "@/utils/externalRouter";

export default {
  name: "MobileMenu",
  emits: ["onCloseDrawer"],
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { drawer: true, currentSelection: "" };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    homePageRoute() {
      return this.$route.name === `home-page`;
    },
  },
  watch: {
    drawer(newVal) {
      if (newVal === false) {
        this.closeDrawer();
      }
    },
  },
  methods: {
    closeDrawer() {
      this.$emit("onCloseDrawer");
    },
    goToSection(_id) {
      document
        .getElementById(_id)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    gotoSignin() {
      pushAuthUrl();
    },
    gotoRegister() {
      pushAuthUrl("/register");
    },
    gotoCV() {
      pushFormsUrl("/upload-cv");
    },
  },
};
</script>

<style scoped>
.menu-text {
  flex: 1;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #f4f5f6;
  text-transform: uppercase;
  opacity: 1 !important;
}

.selected-mobile-menu-tile {
  background: #f39200;
  border-radius: 12px;
  overflow: hidden;
}
.mobile-menu-tile {
  padding: 6px 12px;
}
.mobile-inner-menu-tile {
  padding: 3px 20px !important;
}
.login-btn {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
  padding-left: 20px !important;
}
.register-btn {
  padding: 10px 16px;
  border: 2px solid #f39200;
  border-radius: 8px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}
</style>
