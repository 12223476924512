var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-our-offer mt-15"},[_c('h2',{staticClass:"our-offer-title"},[_vm._v(" corsi in evidenza ")]),_c('v-expansion-panels',{staticClass:"mt-5",attrs:{"dark":"","flat":"","value":0}},_vm._l((_vm.courses.slice(0,6)),function(course,courseIndex){return _c('v-expansion-panel',{key:course.title,staticClass:"our-offer-panel mb-5",attrs:{"readonly":_vm.showDemo}},[_c('v-expansion-panel-header',{on:{"click":() => _vm.trackEvent('LandingPage', 'ClickOnCourse', course.title)}},[_vm._v(" "+_vm._s(course.title)+" ")]),_c('v-expansion-panel-content',[(_vm.showDemo)?_c('DemoArea',{attrs:{"show-demo-area":_vm.showDemoArea}}):_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3","lg":"2","align":"center"}},[_c('v-img',{staticClass:"course-img",class:{
                'course-img-mobile':
                  _vm.$vuetify.breakpoint.smAndDown,
              },attrs:{"src":_vm.getImg(course.imgLink),"alt":"course image"}})],1),_c('v-col',{staticClass:"info-container",class:_vm.$vuetify.breakpoint.smAndUp ? 'pl-5' : 'pt-2',attrs:{"cols":"12","sm":"7","md":"9","lg":"10"}},[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"course-content mt-3",class:{ 'mt-4': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" "+_vm._s(course.content)+" ")])])],1),_c('v-row',{staticClass:"bottom-container",attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',{attrs:{"align":"end"}},_vm._l((course.teachers.slice()),function(teacher){return _c('v-col',{key:teacher.name,attrs:{"cols":"12","md":"4"}},[_c('p',{staticClass:"teacher-name mb-0"},[_vm._v(" "+_vm._s(teacher.name)+" ")]),_c('p',{staticClass:"teacher-role mb-0"},[_vm._v(" "+_vm._s(teacher.role)+" ")])])}),1)],1),(_vm.$vuetify.breakpoint.mdAndUp && courseIndex === 0)?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"rounded-lg text-tansform-none",attrs:{"color":"#F39200"},on:{"click":_vm.openDemoArea}},[_vm._v(" Prova la demo ")])],1):_vm._e()],1)],1)],1)],1)],1)}),1),_vm._l((_vm.courses.slice(6)),function(course){return _c('v-expand-transition',{key:course.title,staticClass:"mt-5"},[_c('v-expansion-panels',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMoreCourses),expression:"showMoreCourses"}],attrs:{"dark":"","flat":""}},[_c('v-expansion-panel',{staticClass:"our-offer-panel mb-5",attrs:{"readonly":_vm.showDemo}},[_c('v-expansion-panel-header',{on:{"click":() => _vm.trackEvent('LandingPage', 'ClickOnCourse', course.title)}},[_vm._v(" "+_vm._s(course.title)+" ")]),_c('v-expansion-panel-content',[(_vm.showDemo)?_c('DemoArea',{attrs:{"show-demo-area":_vm.showDemoArea}}):_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3","lg":"2","align":"center"}},[_c('v-img',{staticClass:"course-img",class:{
                  'course-img-mobile':
                    _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"src":_vm.getImg(course.imgLink),"alt":"course image"}})],1),_c('v-col',{staticClass:"info-container",class:_vm.$vuetify.breakpoint.smAndUp ? 'pl-5' : 'pt-2',attrs:{"cols":"12","sm":"7","md":"9","lg":"10"}},[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"course-content mt-3",class:{ 'mt-4': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" "+_vm._s(course.content)+" ")])])],1),_c('v-row',{staticClass:"bottom-container",attrs:{"align":"end"}},_vm._l((course.teachers),function(teacher){return _c('v-col',{key:teacher.name,attrs:{"cols":"12","md":"4"}},[_c('p',{staticClass:"teacher-name mb-0"},[_vm._v(" "+_vm._s(teacher.name)+" ")]),_c('p',{staticClass:"teacher-role mb-0"},[_vm._v(" "+_vm._s(teacher.role)+" ")])])}),1)],1)],1)],1)],1)],1)],1)}),_c('v-btn',{staticClass:"show-more-btn",attrs:{"color":"#F39200","outlined":""},on:{"click":function($event){_vm.showMoreCourses = !_vm.showMoreCourses}}},[_vm._v(" "+_vm._s(_vm.showMoreCourses ? 'Vedi meno corsi' : 'Scopri tutti i corsi')+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }