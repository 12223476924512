<template>
  <div
    class="follow-us-section"
    :style="{'align-items': alignItems }"
  >
    <h4 class="follow-us-text">
      Seguici su:
    </h4>
    <div class="social-wrapper mt-2">
      <a
        v-for="social in socialLinks"
        :key="social.name"
        :href="social.url"
        target="_blank"
        @click="() => trackEvent('LandingPage', `ClickOnSocialIcon${social.name.toUpperCase()}`)"
      >
        <img
          class="social-icon"
          :src="require(`@assets/landingPage/social/${social.name}.svg`)"
          :alt="social.name"
        >
      </a>
    </div>
  </div>
</template>

<script>
import { socialLinks } from "@data/socialLinks.js"
import matomoTracker from "@mixins/matomoTracker";

export default {
  name:"FollowUs",
  mixins:[matomoTracker],
  props:{
    alignItems:{
      type:String,
      default:"flex-start"
    }
  },
  data() {
    return {
      socialLinks
    };
  }
}
</script>

<style scoped>
.follow-us-section{
    display: flex;
    flex-direction: column;
}
.follow-us-text{
font-family: 'Karasuma Gothic';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 28px;
text-align: right;
color: #F4F5F6;
}
.social-wrapper{
    display: flex;
    flex-direction: row;
}
.social-wrapper>:not(:last-child){
    margin-right: 30px;
}
.social-icon{
    height: 24px;
    width: 24px;
    object-fit: contain;
    object-position: center;
}
</style>