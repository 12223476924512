var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.messages.length > 0)?_c('v-system-bar',{staticClass:"px-0 mx-0",attrs:{"app":"","height":36,"color":'#F39200'}},[_c('v-container',{staticClass:"bell-wrapper"},[_c('div',{ref:"containerWrapper",staticClass:"container-wrapper"},[(_vm.containerWrapperWidth)?_c('div',{class:_vm.messages.length > 1 ? 'justify-space-between' : 'justify-start',attrs:{"id":"marquee-row","no-gutters":""}},[_c('img',{staticClass:"bell-icon mr-2",class:{ 'bell-animation': _vm.showAnimation },attrs:{"src":require("@assets/landingPage/bell_icon.svg"),"alt":"icon bell"}}),_c('div',{style:({
            width: `${
              _vm.containerWrapperWidth -
              (_vm.$vuetify.breakpoint.mdAndUp ? 252 : 36) -
              23 -
              10
            }px`,
            position: 'relative',
          })},[_c('transition-group',{staticClass:"transition-container",attrs:{"name":"slide-fade","tag":"div"}},_vm._l((_vm.selectedMessage),function(message){return _c('MarqueeText',{key:message.text + _vm.$vuetify.breakpoint.name,staticClass:"message-box marquee-container",attrs:{"duration":15,"repeat":_vm.$vuetify.breakpoint.mdAndUp ? 1 : 10,"paused":_vm.$vuetify.breakpoint.mdAndUp}},[_c('a',{key:message.text,staticClass:"custom-marquee mr-10",style:({ pointerEvents: message.link ? 'auto' : 'none' }),attrs:{"href":message.link,"target":"_blank"},on:{"click":() =>
                    _vm.trackEvent(
                      'LandingPage',
                      'ClickOnLandingBellText',
                      message.link
                    )}},[_vm._v(" "+_vm._s(message.text)+" ")])])}),1)],1),(_vm.messages.length > 1)?_c('v-btn',{staticClass:"marquee-btn",attrs:{"text":_vm.$vuetify.breakpoint.mdAndUp,"icon":!_vm.$vuetify.breakpoint.mdAndUp},on:{"click":_vm.updateBellContent}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? "Annuncio successivo" : "")+" "),_c('v-icon',{attrs:{"right":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" mdi-chevron-right ")])],1):_vm._e()],1):_vm._e()])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }