<template>
  <div class="social-section mt-15">
    <h2 class="social-title">
      Seguici sui nostri social
    </h2>
    <v-row
      no-gutters
      class="mt-5"
      justify="center"
    >
      <a
        v-for="social in socialLinks" 
        :key="social.name"
        :href="social.url"
        target="_blank"
        @click="() => trackEvent('LandingPage', `ClickOnSocialIcon${social.name.toUpperCase()}`)"
      >
        <img
          class="mx-3"
          :class="{ 'social-icon': $vuetify.breakpoint.xsOnly }"
          :src="require(`@assets/landingPage/social/${social.name}.svg`)"
          :alt="social.name"
        >
      </a>
    </v-row>
  </div>
</template>

<script>
import { socialLinks } from "@data/socialLinks.js";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "SocialSection",
  mixins:[matomoTracker],
  data() {
    return {
      socialLinks,
    };
  },
};
</script>

<style scoped>
.social-section {
  background: rgba(0, 0, 0, 0.4);
  border: 2px solid #f39200;
  border-radius: 12px;
  padding: 20px 0;
}
.social-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #f4f5f6;
  text-transform: uppercase;
}

.social-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
}
</style>
