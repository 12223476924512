import services from "@services/bellMessages";
import { handleAxiosError } from "@utils/notifications";

const actions = {
  async getBellMessages() {
    try {
      const { data } = await services.getBellMessages();
      console.log(data);
      return data;
    } catch (err) {
      await handleAxiosError("errore nel caricamento dei messaggi", err);
      return null;
    }
  },
};

export default {
  namespaced: true,
  actions,
};
