export const provinces = [
  {
    name: "Agrigento",
    abbr: "AG",
    region: "Sicilia",
  },
  {
    name: "Alessandria",
    abbr: "AL",
    region: "Piemonte",
  },
  {
    name: "Ancona",
    abbr: "AN",
    region: "Marche",
  },
  {
    name: "Aosta",
    abbr: "AO",
    region: "Valle d'Aosta",
  },
  {
    name: "Arezzo",
    abbr: "AR",
    region: "Toscana",
  },
  {
    name: "Ascoli Piceno",
    abbr: "AP",
    region: "Marche",
  },
  {
    name: "Asti",
    abbr: "AT",
    region: "Piemonte",
  },
  {
    name: "Avellino",
    abbr: "AV",
    region: "Campania",
  },
  {
    name: "Bari",
    abbr: "BA",
    region: "Puglia",
  },
  {
    name: "Barletta-Andria-Trani",
    abbr: "BT",
    region: "Puglia",
  },
  {
    name: "Belluno",
    abbr: "BL",
    region: "Veneto",
  },
  {
    name: "Benevento",
    abbr: "BN",
    region: "Campania",
  },
  {
    name: "Bergamo",
    abbr: "BG",
    region: "Lombardia",
  },
  {
    name: "Biella",
    abbr: "BI",
    region: "Piemonte",
  },
  {
    name: "Bologna",
    abbr: "BO",
    region: "Emilia-Romagna",
  },
  {
    name: "Bolzano",
    abbr: "BZ",
    region: "Trentino-Alto Adige",
  },
  {
    name: "Brescia",
    abbr: "BS",
    region: "Lombardia",
  },
  {
    name: "Brindisi",
    abbr: "BR",
    region: "Puglia",
  },
  {
    name: "Cagliari",
    abbr: "CA",
    region: "Sardegna",
  },
  {
    name: "Caltanissetta",
    abbr: "CL",
    region: "Sicilia",
  },
  {
    name: "Campobasso",
    abbr: "CB",
    region: "Molise",
  },
  {
    name: "Caserta",
    abbr: "CE",
    region: "Campania",
  },
  {
    name: "Catania",
    abbr: "CT",
    region: "Sicilia",
  },
  {
    name: "Catanzaro",
    abbr: "CZ",
    region: "Calabria",
  },
  {
    name: "Chieti",
    abbr: "CH",
    region: "Abruzzo",
  },
  {
    name: "Como",
    abbr: "CO",
    region: "Lombardia",
  },
  {
    name: "Cosenza",
    abbr: "CS",
    region: "Calabria",
  },
  {
    name: "Cremona",
    abbr: "CR",
    region: "Lombardia",
  },
  {
    name: "Crotone",
    abbr: "KR",
    region: "Calabria",
  },
  {
    name: "Cuneo",
    abbr: "CN",
    region: "Piemonte",
  },
  {
    name: "Enna",
    abbr: "EN",
    region: "Sicilia",
  },
  {
    name: "Fermo",
    abbr: "FM",
    region: "Marche",
  },
  {
    name: "Ferrara",
    abbr: "FE",
    region: "Emilia-Romagna",
  },
  {
    name: "Firenze",
    abbr: "FI",
    region: "Toscana",
  },
  {
    name: "Foggia",
    abbr: "FG",
    region: "Puglia",
  },
  {
    name: "Forli'-Cesena",
    abbr: "FC",
    region: "Emilia-Romagna",
  },
  {
    name: "Frosinone",
    abbr: "FR",
    region: "Lazio",
  },
  {
    name: "Genova",
    abbr: "GE",
    region: "Liguria",
  },
  {
    name: "Gorizia",
    abbr: "GO",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Grosseto",
    abbr: "GR",
    region: "Toscana",
  },
  {
    name: "Imperia",
    abbr: "IM",
    region: "Liguria",
  },
  {
    name: "Isernia",
    abbr: "IS",
    region: "Molise",
  },
  {
    name: "L'Aquila",
    abbr: "AQ",
    region: "Abruzzo",
  },
  {
    name: "La Spezia",
    abbr: "SP",
    region: "Liguria",
  },
  {
    name: "Latina",
    abbr: "LT",
    region: "Lazio",
  },
  {
    name: "Lecce",
    abbr: "LE",
    region: "Puglia",
  },
  {
    name: "Lecco",
    abbr: "LC",
    region: "Lombardia",
  },
  {
    name: "Livorno",
    abbr: "LI",
    region: "Toscana",
  },
  {
    name: "Lodi",
    abbr: "LO",
    region: "Lombardia",
  },
  {
    name: "Lucca",
    abbr: "LU",
    region: "Toscana",
  },
  {
    name: "Macerata",
    abbr: "MC",
    region: "Marche",
  },
  {
    name: "Mantova",
    abbr: "MN",
    region: "Lombardia",
  },
  {
    name: "Massa-Carrara",
    abbr: "MS",
    region: "Toscana",
  },
  {
    name: "Matera",
    abbr: "MT",
    region: "Basilicata",
  },
  {
    name: "Messina",
    abbr: "ME",
    region: "Sicilia",
  },
  {
    name: "Milano",
    abbr: "MI",
    region: "Lombardia",
  },
  {
    name: "Modena",
    abbr: "MO",
    region: "Emilia-Romagna",
  },
  {
    name: "Monza e della Brianza",
    abbr: "MB",
    region: "Lombardia",
  },
  {
    name: "Napoli",
    abbr: "NA",
    region: "Campania",
  },
  {
    name: "Novara",
    abbr: "NO",
    region: "Piemonte",
  },
  {
    name: "Nuoro",
    abbr: "NU",
    region: "Sardegna",
  },
  {
    name: "Oristano",
    abbr: "OR",
    region: "Sardegna",
  },
  {
    name: "Padova",
    abbr: "PD",
    region: "Veneto",
  },
  {
    name: "Palermo",
    abbr: "PA",
    region: "Sicilia",
  },
  {
    name: "Parma",
    abbr: "PR",
    region: "Emilia-Romagna",
  },
  {
    name: "Pavia",
    abbr: "PV",
    region: "Lombardia",
  },
  {
    name: "Perugia",
    abbr: "PG",
    region: "Umbria",
  },
  {
    name: "Pesaro e Urbino",
    abbr: "PU",
    region: "Marche",
  },
  {
    name: "Pescara",
    abbr: "PE",
    region: "Abruzzo",
  },
  {
    name: "Piacenza",
    abbr: "PC",
    region: "Emilia-Romagna",
  },
  {
    name: "Pisa",
    abbr: "PI",
    region: "Toscana",
  },
  {
    name: "Pistoia",
    abbr: "PT",
    region: "Toscana",
  },
  {
    name: "Pordenone",
    abbr: "PN",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Potenza",
    abbr: "PZ",
    region: "Basilicata",
  },
  {
    name: "Prato",
    abbr: "PO",
    region: "Toscana",
  },
  {
    name: "Ragusa",
    abbr: "RG",
    region: "Sicilia",
  },
  {
    name: "Ravenna",
    abbr: "RA",
    region: "Emilia-Romagna",
  },
  {
    name: "Reggio Calabria",
    abbr: "RC",
    region: "Calabria",
  },
  {
    name: "Reggio Emilia",
    abbr: "RE",
    region: "Emilia-Romagna",
  },
  {
    name: "Rieti",
    abbr: "RI",
    region: "Lazio",
  },
  {
    name: "Rimini",
    abbr: "RN",
    region: "Emilia-Romagna",
  },
  {
    name: "Roma",
    abbr: "RM",
    region: "Lazio",
  },
  {
    name: "Rovigo",
    abbr: "RO",
    region: "Veneto",
  },
  {
    name: "Salerno",
    abbr: "SA",
    region: "Campania",
  },
  {
    name: "Sassari",
    abbr: "SS",
    region: "Sardegna",
  },
  {
    name: "Savona",
    abbr: "SV",
    region: "Liguria",
  },
  {
    name: "Siena",
    abbr: "SI",
    region: "Toscana",
  },
  {
    name: "Siracusa",
    abbr: "SR",
    region: "Sicilia",
  },
  {
    name: "Sondrio",
    abbr: "SO",
    region: "Lombardia",
  },
  {
    name: "Sud Sardegna",
    abbr: "SU",
    region: "Sardegna",
  },
  {
    name: "Taranto",
    abbr: "TA",
    region: "Puglia",
  },
  {
    name: "Teramo",
    abbr: "TE",
    region: "Abruzzo",
  },
  {
    name: "Terni",
    abbr: "TR",
    region: "Umbria",
  },
  {
    name: "Torino",
    abbr: "TO",
    region: "Piemonte",
  },
  {
    name: "Trapani",
    abbr: "TP",
    region: "Sicilia",
  },
  {
    name: "Trento",
    abbr: "TN",
    region: "Trentino-Alto Adige",
  },
  {
    name: "Treviso",
    abbr: "TV",
    region: "Veneto",
  },
  {
    name: "Trieste",
    abbr: "TS",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Udine",
    abbr: "UD",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Varese",
    abbr: "VA",
    region: "Lombardia",
  },
  {
    name: "Venezia",
    abbr: "VE",
    region: "Veneto",
  },
  {
    name: "Verbano-Cusio-Ossola",
    abbr: "VB",
    region: "Piemonte",
  },
  {
    name: "Vercelli",
    abbr: "VC",
    region: "Piemonte",
  },
  {
    name: "Verona",
    abbr: "VR",
    region: "Veneto",
  },
  {
    name: "Vibo Valentia",
    abbr: "VV",
    region: "Calabria",
  },
  {
    name: "Vicenza",
    abbr: "VI",
    region: "Veneto",
  },
  {
    name: "Viterbo",
    abbr: "VT",
    region: "Lazio",
  },
];
