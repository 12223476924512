<template>
  <div class="landing-type-of-user">
    <v-row no-gutters :class="{ 'justify-center': $vuetify.breakpoint.mdAndDown }">
      <h3 id="type-of-user-title" class="users-section-title" :class="{ 'text-center': $vuetify.breakpoint.mdAndDown }">
        Chi sei?
      </h3>
    </v-row>
    <v-row no-gutters class="mt-5 flex-column" :class="{ 'align-content-center': $vuetify.breakpoint.mdAndDown }">
      <LandingUserCard v-for="typeOfUser in typeOfUsers" :key="typeOfUser.title" :title="typeOfUser.title"
        :subtitle="typeOfUser.subtitle" :page="typeOfUser.page" />
    </v-row>
  </div>
</template>

<script>
import LandingUserCard from "@cmp-landing/LandingUserCard.vue";
export default {
  name: "LandingTypeOfUser",
  components: { LandingUserCard },
  mixins: [],
  props: {
    updateSelectedUser: {
      type: Function,
      default: () => console.log(""),
    },
  },
  data() {
    return {
      typeOfUsers: [
        { title: "professore", subtitle: "PERCORSI PCTO CON NOI", page: "professor" },
        { title: "studente", subtitle: "READY TO CODE?", page: "student" },
        { title: "appassionato", subtitle: "SEGUI I CORSI GRATUITI", page: "guest" },
      ],
    };
  },
};
</script>

<style scoped>
.users-section-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #f4f5f6;
}
</style>
