var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('v-row',{staticClass:"mx-5 my-3"},_vm._l((_vm.articles.links),function(article){return _c('v-col',{key:article.id,attrs:{"align":"center","cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"rounded-xl d-flex flex-column justify-space-between",attrs:{"color":"#1D1C4C","height":_vm.$vuetify.breakpoint.xsOnly ? '510' : '450',"width":"361px"}},[_c('v-img',{attrs:{"max-height":"163","src":article.image_url}}),_c('v-card-title',[_c('h2',{staticClass:"article-title"},[_vm._v(" "+_vm._s(article.title)+" ")])]),_c('v-card-text',[_c('span',{staticClass:"news-card-paragraph"},[_vm._v(_vm._s(article.abstract))])]),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'justify-center' : 'justify-end'},[_c('v-btn',{staticClass:"white--text btn-size rounded-lg",attrs:{"dark":"","strong":"","outlined":"","color":"#F39200 !important","href":article.post_url,"target":"_blank"},on:{"click":() =>
                _vm.trackEvent(
                  'LandingPage',
                  'ClickOnReadArticle',
                  article.post_url
                )}},[_c('v-icon',{attrs:{"left":"","size":"24"}},[_vm._v(" mdi-file-document-outline ")]),_c('span',{staticClass:"button-text"},[_vm._v("Leggi articolo")])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"news-card-title mb-5 mt-15"},[_c('span',[_vm._v("contest, campus e news")])])
}]

export { render, staticRenderFns }