<template>
  <div>
    <v-row
      no-gutters
      :class="{ 'justify-center': $vuetify.breakpoint.mdAndDown }"
    >
      <h3
        class="opportunities-title"
        :class="{ 'text-center': $vuetify.breakpoint.mdAndDown }"
      >
        Le nostre opportunita'
      </h3>
    </v-row>
    <v-row
      no-gutters
      :class="
        $vuetify.breakpoint.mdAndDown
          ? 'align-content-center flex-column'
          : 'space-between flex-nowrap'
      "
      class="mt-5"
    >
      <LandingOpportunitiesCard
        v-for="opportunity in opportunities"
        :key="opportunity.title"
        :title="opportunity.title"
        :description="opportunity.description"
        :img="opportunity.img"
        :link="opportunity.link"
        :matomoAction="opportunity.matomoAction"
      />
    </v-row>
  </div>
</template>

<script>
import LandingOpportunitiesCard from "@cmp-landing/LandingOpportunitiesCard.vue";
import store from "@/store";

export default {
  name: "LandingOurOpportunities",
  components: { LandingOpportunitiesCard },
  data() {
    return {
      opportunities: [
        {
          title: "Premio Marilli",
          description:
            "Lo School Contest “Premio GFMarilli” è arrivato alla sua VI edizione! Hai sviluppato un progetto personale o scolastico? Non perdere questa grande occasione per vincere fantastici premi!",
          img: "PremioMarilli-min.jpg",
          link: "https://projects.hackersgen.com",
          matomoAction: "ClickOnHighlightedPremioMarilli",
        },
        {
          title: "Sorint Summer Campus",
          description:
            "Affacciati al mondo professionale dell'IT con noi! Partecipa a due settimane full-immersion sulle principali aree tecnologiche, con possibilità concrete di inserimento in organico Sorint!",
          img: "Campus-min.jpg",
          link: "https://drive.google.com/file/d/1WJV5ApMiYecmkvj_tufDVZV3yypXPUA1/view?usp=sharing",
          matomoAction: "ClickOnHighlightedCampus",
        },
        {
          title: "Inviaci il tuo CV!",
          description:
            "Perché lavorare in Sorint? Lavoro ibrido, crescita professionale, valorizzazione del talento, benessere delle persone, mobilità internazionale, formazione continua... Cosa aspetti? Be part of IT!",
          img: "AreaCV-min.jpg",
          link: "https://forms.hackersgen.com/upload-cv",
          matomoAction: "ClickOnHighlightedCV",
        },
      ],
    };
  },
};
</script>

<style scoped>
.opportunities-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #f4f5f6;
}
</style>
