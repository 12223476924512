<template>
  <v-alert
    class="tracking-banner"
    icon="mdi-cookie"
    color="#1d1c4c"
    dismissible
    border="left"
    prominent
    dark
  >
    Utilizziamo cookies ed un sistema di tracking per offrirti la migliore
    esperienza possibile e non condivideremo i tuoi dati con nessuno. Per
    saperne di più visita la nostra
    <a
      class="colored-link"
      href="https://www.sorint.com/privacy-policy/"
    >
      privacy policy</a>.
  </v-alert>
</template>

<script>
export default {
  name: "AcceptTrackingBanner",
};
</script>

<style>
.tracking-banner {
  position: fixed;
  bottom: 0;
  width: 84.3%;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 1em 0;
  z-index: 100;
  font-family: "Karasuma Gothic";
}
.colored-link {
  color: #f55657 !important;
  font-weight: bold;
}
</style>
