export const socialLinks = [
  {
    name: "instagram",
    url: "https://www.instagram.com/hackersgen_sorint/",
  },
  {
    name: "facebook",
    url: "https://it-it.facebook.com/sorintlab/",
  },
  {
    name: "twitter",
    url: "https://twitter.com/SorintLab",
  },
  {
    name: "linkedin",
    url: "https://it.linkedin.com/company/sorint-lab",
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/channel/UCYlS-aTWJ31i1t28QgExmFQ/featured",
  },
  {
    name: "globe",
    url: "https://www.sorint.com/",
  },
];
