<template>
  <div>
    <h1 class="news-card-title mb-5 mt-15">
      <span>contest, campus e news</span>
    </h1>
    <v-row class="mx-5 my-3">
      <v-col
        v-for="article in articles.links"
        :key="article.id"
        align="center"
        cols="12"
        sm="12"
        md="6"
        lg="4"
      >
        <v-card
          color="#1D1C4C"
          :height="$vuetify.breakpoint.xsOnly ? '510' : '450'"
          width="361px"
          class="rounded-xl d-flex flex-column justify-space-between"
        >
          <v-img max-height="163" :src="article.image_url" />
          <v-card-title>
            <h2 class="article-title">
              {{ article.title }}
            </h2>
          </v-card-title>
          <v-card-text>
            <span class="news-card-paragraph">{{ article.abstract }}</span>
          </v-card-text>
          <v-card-actions
            :class="
              $vuetify.breakpoint.mdAndDown ? 'justify-center' : 'justify-end'
            "
          >
            <v-btn
              dark
              strong
              outlined
              color="#F39200 !important"
              class="white--text btn-size rounded-lg"
              :href="article.post_url"
              target="_blank"
              @click="
                () =>
                  trackEvent(
                    'LandingPage',
                    'ClickOnReadArticle',
                    article.post_url
                  )
              "
            >
              <v-icon left size="24"> mdi-file-document-outline </v-icon>
              <span class="button-text">Leggi articolo</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import settingsServices from "@services/settings";
import { handleAxiosError } from "@utils/notifications";
import store from "@store";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "LandingArticles",
  mixins: [matomoTracker],
  props: {
    updateSelectedUser: {
      type: Function,
      default: () => ({}),
    },
  },
  data: () => ({
    retrievedArticles: { links: [] },
    articles: { links: [] },
  }),
  async mounted() {
    await this.getArticles();
  },
  methods: {
    backToStudent() {
      this.updateSelectedUser("Studente");
    },
    async getArticles() {
      try {
        const { data } = await settingsServices.getBlogArticles();
        this.retrievedArticles = data;
        this.articles.links = this.retrievedArticles.map((link) => ({
          ...link,
          id: Math.random(),
        }));
      } catch (err) {
        handleAxiosError("Errore nel recupero degli articoli", err);
      }
    },
  },
};
</script>

<style scoped>
.back-to-home {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
  text-transform: uppercase;
}

.news-card-paragraph {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98a6ae;
  text-align: center;
}

.news-card-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 24px;
  text-align: center;
  color: #f4f5f6;
}

.article-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #f4f5f6;
  text-align: center;
  word-break: normal;
}

.button-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
}
</style>
