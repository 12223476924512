<template>
  <v-footer
    dark
    color="#010024"
  >
    <v-container class="footer-wrapper">
      <v-col
        class="pa-0 mb-15"
        cols="12"
      >
        <v-row
          no-gutters
          class="mt-5 mb-15"
          :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'start'"
        >
          <img
            src="@assets/landingPage/HG_LOGO.webp"
            alt="hackersgen logo"
            class="hg-logo"
          >
        </v-row>
        <v-row
          no-gutters
          class="mt-10"
        >
          <v-col
            class="pa-0 mb-10"
            cols="12"
            md="4"
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
          >
            <h3 class="footer-title mb-2">
              Sei un professore?
            </h3>
            <a
              class="footer-link"
              href="mailto:hackersgen@sorint.com"
              @click="() => trackEvent('LandingPage', 'ClickOnSelectTypeOfUserPROFESSOR')"
            >Contattaci</a>
          </v-col>
          <v-col
            class="pa-0 mb-10"
            cols="12"
            md="4"
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
          >
            <h3 class="footer-title mb-2">
              Sei uno studente?
            </h3>
            <a
              class="footer-link"
              @click="moveTo('landing-student')"
            >Contest, Campus & News</a>
          </v-col>
          <v-col
            class="pa-0 mb-10"
            cols="12"
            md="4"
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
          >
            <h3 class="footer-title mb-2">
              Sei un appassionato?
            </h3>
            <a
              class="footer-link"
              @click="moveTo('landing-guest')"
            >Sei un ex-studente?</a>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mt-15"
          justify="space-between"
          align="center"
        >
          <v-col
            align="center"
            class="pa-0"
            cols="12"
            sm="4"
          >
            <h5 class="footer-info">
              C.FISC. 95164770166
            </h5>
          </v-col>
          <v-col
            align="center"
            class="pa-0"
            cols="12"
            sm="4"
          >
            <h5 class="footer-info">
              P.IVA 03419770163
            </h5>
          </v-col>
          <v-col
            align="center"
            class="pa-0"
            cols="12"
            sm="4"
          >
            <h5 class="footer-info">
              welisten@sorint.com
            </h5>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </v-footer>
</template>

<script>
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "LandingFooter",
  mixins:[matomoTracker],
  methods: {
    async moveTo(page) {
      const typeOfUser = page.slice(1).split("-").pop().toUpperCase();

      await this.trackEvent("LandingPage", `ClickOnSelectTypeOfUser${typeOfUser}`)
      this.$router.push({ name: page });
    }
  },
};
</script>

<style scoped>
.hg-logo {
  width: 273px;
  height: 31px;
  object-fit: contain;
  object-position: center;
}
.footer-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
  text-transform: uppercase;
}
.footer-wrapper{
  max-width: 1200px;
}
.footer-link,
.footer-link:visited,
.footer-link:active,
.footer-link:focus-within,
.footer-link:focus,
.footer-link:focus-visible {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #98a6ae;
  text-decoration: none;
  display: block;
}

.footer-info {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  color: #98a6ae;
}
</style>
