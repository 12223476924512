<template>
  <div
    ref="planetsContainer"
    class="background-planets-container"
  >
    <div
      v-for="planet in selectedPlanets"
      :key="`${planet.planet}-${planet.left}-${planet.top}`"
      class="planet"
      :style="{
        top: planet.top,
        left: planet.left,
        width: planet.size,
        height: planet.size,
      }"
    >
      <img
        :src="getPlanet(planet.src)"
        alt="planet"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundPlanets",
  props: {
    maxPlanetsNumber: {
      type: Number,
      default: 7,
    },
    paddingTop: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      planets: [
        "background/Pianeta_1.svg",
        "background/Pianeta_2.svg",
        "background/Pianeta_3.svg",
      ],
      selectedPlanets: [],
    };
  },
  mounted() {
    const width = this.$refs.planetsContainer.offsetWidth;
    const height = this.$refs.planetsContainer.offsetHeight;
    let planetHeight = this.paddingTop;
    while (planetHeight <= height) {
      const planetPositionWidth = Math.floor(Math.random() * width);
      const index = Math.floor(Math.random() * 2);
      const size = Math.floor(Math.random() * 400) + 100;
      this.selectedPlanets.push({
        src: this.planets[index],
        left: `${planetPositionWidth}px`,
        top: `${planetHeight}px`,
        size: `${size}px`,
      });
      planetHeight += size + 50;
    }
  },
  methods: {
    getPlanet(planetSrc) {
      return require(`@assets/${planetSrc}`);
    },
  },
};
</script>

<style scoped>
.background-planets-container {
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background: transparent;
  z-index: 0;
}
.planet {
  position: absolute;
  object-fit: contain;
  object-position: center;
}
</style>
