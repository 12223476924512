<template>
  <div
    class="earth-wrapper"
    @click="goTo"
  >
    <div
      ref="earth"
      class="earth-container"
      @click="() => trackEvent('LandingPage', 'ClickOnEarthImg')"
    />
  </div>
</template>

<script>
import * as THREE from "three";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "Earth",
  mixins:[matomoTracker],
  props: {
    goTo: {
      type: Function,
      required: false,
      default: () => ({}),
    }
  },
  data(){
    return {
      scene: null,
      earth: null,
      renderer: null,
      camera: null,
    }
  },
  mounted(){
    this.scene = new THREE.Scene()
    this.resetCamera()
    const pointLight = new THREE.PointLight(0xffffff, 1, 2000)
    pointLight.castShadow = true
    pointLight.position.set(250,180, 500)
    this.scene.add(pointLight)
    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.setSize(this.$refs.earth.clientWidth, this.$refs.earth.clientHeight)
    renderer.setPixelRatio(devicePixelRatio)
    renderer.setClearColor( 0x01002E, 1 );
    this.renderer = renderer
    this.$refs.earth.appendChild(this.renderer.domElement)
    this.addEarth()
    this.animate()
  },
  methods:{
    animate(){
      requestAnimationFrame(this.animate)
      this.renderer.render(this.scene, this.camera)
      this.earth.rotation.y += 0.001 
    },
    resetCamera(){
      const camera = new THREE.PerspectiveCamera(
        75,
        this.$refs.earth.clientWidth / this.$refs.earth.clientHeight,
        0.1,
        1000
      )
      camera.position.z = 30
      this.camera = camera;
    },
    addEarth(){
      const geometry = new THREE.SphereGeometry(15,32,64)
      const texture = new THREE.TextureLoader().load("world.webp");
      const bumpMap = new THREE.TextureLoader().load("bump_map.webp");
      const shineMap = new THREE.TextureLoader().load("shine_map.webp");
      const material = new THREE.MeshPhongMaterial({ map:texture, bumpMap, bumpScale: 0.5, specularMap: shineMap, specular: new THREE.Color("grey"), shininess: 12})
      const earth = new THREE.Mesh(geometry, material)
      earth.castShadow = false
      earth.position.set(0, 0, 0)
      earth.rotation.x = 0.65
      earth.rotation.y = 4.1
      this.earth = earth
      this.scene.add(earth)
    },
  }
}
</script>

<style scoped>
.earth-wrapper{
    width: 100%;
    position: relative;
}
.earth-wrapper::after{
    content: '';
    display: block;
    padding-bottom:100% ;
}
.earth-container{
    width: 100%;
    height: 100%;
    background: #01002e;
    position: absolute;
}
</style>