<template>
  <div>
    <v-row>
      <v-col cols="8">
        <h3 class="question-title d-flex justify-start">
          Domande anonime
        </h3>
      </v-col>
      <v-col cols="4">
        <span class="trainer-role d-flex justify-end"> {{ topics.length }} domande anonime </span>
      </v-col>
    </v-row>
    <v-tooltip
      attach=".new-comment"
      nudge-bottom="50px"
    >
      <template #activator="{ on, attrs }">
        <v-card
          class="rounded-lg comment-wrapper pt-3 px-4 new-comment"
          v-bind="attrs"
          v-on="on"
        >
          <v-card-actions>
            <v-row>
              <v-col
                cols="12"
                class="px-0 pb-0"
              >
                <textarea
                  disabled
                  rows="2"
                  maxlength="200"
                  class="input-field"
                  placeholder="Scrivi qui la tua domanda"
                />
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-end pr-0"
              >
                <v-btn
                  small
                  dark
                  strong
                  outlined
                  color="#F39200 !important"
                  class="white--text rounded-lg py-4"
                >
                  <img
                    src="@assets/landingPage/send.svg"
                    alt="send"
                    class="mr-1 icon-button"
                  >
                  <span class="button-text">Invia</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>
      <span>Accedi o Registrati per seguire il corso</span>
    </v-tooltip>
    <v-row class="mt-4">
      <v-col
        v-for="(topic, topicIndex) in topics"
        :key="topic.id"
        cols="12"
      >
        <v-card
          class="rounded-lg comment-wrapper pt-3 px-4 mb-1"
        >
          <v-card-text class="pb-0">
            <v-row class="mb-2">
              <v-col
                cols="2"
                md="1"
              >
                <v-img
                  min-width="40"
                  class="rounded-circle"
                  src="@assets/users-icon/volpe.svg"
                />
              </v-col>
              <v-col cols="8">
                <h4 class="trainer-name">
                  {{ topic.name }}
                </h4>
                <span class="trainer-role">{{ convertTime(topic.time) }}</span>
              </v-col>
              <v-col cols="12">
                <p class="course-paragraph">
                  {{ topic.text }}
                </p>
              </v-col>
              <v-col
                v-if="topicIndex !== 1"
                align="center"
                cols="12"
              >
                <v-btn
                  disabled
                  text
                  class="no-background-button comments-button"
                >
                  <span
                    class="comment-section-actions"
                  >Visualizza 2 commenti</span>
                </v-btn>
              </v-col>
              <v-row v-if="topic.answers.length">
                <v-col
                  v-for="comment in topic.answers"
                  :key="comment.id"
                  cols="12"
                >
                  <v-row class="justify-center">
                    <v-col cols="9">
                      <v-row class="justify-start">
                        <v-col
                          cols="2"
                          md="1"
                          class="mr-3"
                        >
                          <v-img
                            min-width="40"
                            class="rounded-circle"
                            src="@assets/users-icon/volpe.svg"
                          />
                        </v-col>
                        <v-col
                          cols="7"
                          md="8"
                        >
                          <h4 class="trainer-name">
                            {{ comment.name }}
                          </h4>
                          <span class="trainer-role">{{ convertTime(comment.time) }}</span>
                        </v-col>
                        <v-col
                          cols="2"
                          class="d-flex justify-end"
                        >
                          <v-btn
                            disabled
                            icon
                          >
                            <v-icon color="#98a6ae">
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pt-0"
                        >
                          <p class="course-paragraph">
                            {{ comment.text }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-tooltip
            :attach="`.new-reply-${topicIndex}`"
          >
            <template #activator="{ on, attrs }">
              <v-card-actions
                :class="`pt-0 my-5 new-reply-${topicIndex}`"
                v-bind="attrs"
                v-on="on"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="pa-0"
                  >
                    <input
                      disabled
                      class="input-field"
                      placeholder="Commenta qui"
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    class="pr-0 d-flex justify-end"
                  >
                    <v-btn
                      disabled
                      small
                      dark
                      strong
                      outlined
                      color="#F39200 !important"
                      class="white--text rounded-lg py-4"
                    >
                      <img
                        src="@assets/landingPage/send.svg"
                        alt="send"
                        class="mr-1 icon-button"
                        :class="{'disable-icon': !topic.active}"
                      >
                      <span class="button-text">Invia</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </template>
            <span>Accedi o Registrati per seguire il corso</span>
          </v-tooltip>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DemoMockedComments",
  data: () => ({
    topics: [
      {
        id: Math.random(),
        name: "Paolo Verdi",
        time: 1655479434,
        text:"Gli studenti verranno introdotti alle funzionalità base dei linguaggi di markup HTML e CSS?",
        answers: [],
      },
      {
        id: Math.random(),
        name: "Giulia Bianchi",
        time: 1655479900,
        text: "A che cosa può servire avere un proprio portfolio?",
        answers: [
          {
            id: Math.random(),
            name:"Professore",
            time: 1655579434,
            text: "È un ottimo modo per mostrare al pubblico le tue abilità!",
          },
          {
            id: Math.random(),
            name:"Ospite",
            time: 1655579500,
            text: "Io ho pubblicato il mio recentemente ed è molto bello.",
          }
        ]
      },
      {
        id: Math.random(),
        name: "Mario Rossi",
        time: 1655679434,
        text: "Farete anche un corso di CSS avanzato?",
        answers: [],
      },
    ],
  }),
  methods: {
    convertTime(time) {
      return new Date(time * 1000)
        .toLocaleString("it-IT")
        .slice(0, -3);
    },
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}

.course-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.course-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #98a6ae;
}

.trainer-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #f4f5f6;
}

.trainer-role {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #98a6ae;
}

.question-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.comment-wrapper {
  background-color: transparent !important;
  border: 1px #5a6972 solid;
}

.input-field {
  padding: 12px 16px;
  border: 1px solid #e8ebed;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}

.comment-section-actions{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #F4F5F6;
}

.input-field::placeholder {
  color: #5a6972;
}

.button-text {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
}

.icon-button {
  width: 18px;
  filter:invert(30%) sepia(50%) saturate(5258%) hue-rotate(7deg) brightness(102%) contrast(103%);
}

.disable-icon {
  filter:saturate(0);
  opacity: 0.4;
}

.comments-button{
  text-transform: none;
}
</style>