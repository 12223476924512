<template>
  <div class="landing-opportunity-card d-flex flex-column" :class="$vuetify.breakpoint.mdAndDown ? 'mb-5 mr-0' : 'mr-5'" :height="$vuetify.breakpoint.mdAndDown ? 'auto ' : '237'">
    <v-img :src="require(`@/assets/ourOpportunities/${img}`)" class="background-image" />

    <h1 class="opportunity-card-title mb-5">
      {{ title }}
    </h1>
    <p class="opportunity-card-description" :class="$vuetify.breakpoint.mdAndDown ? 'mb-5' : 'mb-0'">
      {{ description }}
    </p>

    <div class="d-flex justify-end mt-auto">
      <v-btn rounded min-width="44px" :href="link" target="_blank" width="44px" height="44px" color="#F39200"
        @click="() => trackEvent('LandingPage', matomoAction)">
        <v-icon color="white">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "LandingOpportunitiesCard",
  mixins: [matomoTracker],
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      required: true,
    },
    matomoAction: {
      type: String,
    }
  }
};
</script>

<style scoped>
.landing-opportunity-card {
  padding: 16px;
  max-width: 500px;
  width: 100%;
  position: relative;
  border-radius: 12px;
  background:
    linear-gradient(#1d1c4c 0 0) padding-box,
    linear-gradient(to right, #F39200, #FCEE21) border-box;
  border: 1px solid transparent;
}

.opportunity-card-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  z-index: 1;

}

.opportunity-card-description {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: #f4f5f6;
  z-index: 1;
}

.opportunity-card-btn {
  border-radius: 12px;
  text-transform: none;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f39200;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-size: cover;
  border-radius: inherit;
}
</style>