import publicApi from "@services/publicAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/post_contact_us
 */
function contactUs(from, text) {
  return publicApi.post("/contact-us", {
    from,
    text,
  });
}

export default {
  contactUs,
};
