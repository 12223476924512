<template>
  <div class="home-wrapper">
    <LandingHomeContest />
    <div
      class="d-flex justify-space-between mt-16"
      :class="{ 'flex-column': $vuetify.breakpoint.mdAndDown }"
    >
      <landing-our-opportunities />
      <landing-type-of-user
        ref="typeOfUserSection"
        :class="{ 'mt-10': $vuetify.breakpoint.mdAndDown }"
      />
    </div>
    <landing-our-offer id="corsi" />
    <SocialSection />
    <landing-contact :title="'Hai una domanda?'" />
    <event-sponsor />

    <v-scale-transition>
      <v-btn
        v-show="fab"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        color="#F39200"
        class="z-index-btn"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <AcceptTrackingBanner />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AcceptTrackingBanner from "@cmp-landing/AcceptTrackingBanner";
import landingTypeOfUser from "./LandingTypeOfUser.vue";
import landingOurOffer from "./LandingOurOffer.vue";
import SocialSection from "@cmp-landing/SocialSection.vue";
import landingContact from "./LandingContact.vue";
import eventSponsor from "@cmp-landing/EventSponsor.vue";
import LandingOurOpportunities from "./LandingOurOpportunities.vue";
import LandingHomeContest from "./LandingHomeContest.vue";

export default {
  name: "HomePage",
  components: {
    landingOurOffer,
    AcceptTrackingBanner,
    landingTypeOfUser,
    SocialSection,
    landingContact,
    eventSponsor,
    LandingOurOpportunities,
    LandingHomeContest,
  },
  metaInfo() {
    return {
      title: "HackersGen",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `HackersGen: Rimani aggiornato sulla proposta formativa live e ondemand interamente "Made in Sorint.lab" e pensata per supportare lo sviluppo di innovativi progetti scolastici`,
        },
      ],
    };
  },
  data: () => ({
    selectedUser: "",
    fab: null,
    color: "",
    flat: null,
    downloadLink: "https://download-event.io",
    marqueeText:
      "DOWNLOAD INNOVATION! Non perdere l'opportunità di partecipare all'evento gratuito dove potrai seguire sessioni IT sui principali trend tech, iscriverti all'Hackathon e conoscere professionisti IT anche internazionali. >>> CLICCA QUI <<<",
  }),
  computed: {
    ...mapGetters("config", ["isDownloadSeason"]),
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "#010024";
        this.flat = false;
      } else {
        this.color = "#01002e";
        this.flat = true;
      }
    },
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") {
        return;
      }
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.z-index-btn {
  z-index: 101;
}
.landing-wrapper {
  background-color: #01002e;
  padding-left: 0px !important;
}

.home-wrapper {
  position: relative;
}

.supporters-wrapper {
  background: #01002e;
  max-width: 100%;
}
</style>
