import Vue from "vue";
import { AUTH_ERROR } from "@store/helpers";
import router from "@/router";
import store from "@store";

const sendNotification = (message, type, timeout = 5000) => {
  Vue.$toast(message, {
    type,
    timeout,
    position: "bottom-right",
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
  });
};

const handleAxiosError = async (customMessage, err, showToast = true) => {
  if (err !== AUTH_ERROR) {
    let message = customMessage;

    /* ERR_BAD_SYNTAX, ERR_INVALID_TOKEN and ERR_MISSING_TOKEN are handled in a "non-standard" way by the be and must be handled individually */

    if (err?.config?.responseType === "blob") {
      const parsedError = await parseBlobError(err.response.data);
      if (parsedError.message?.it) {
        message = parsedError.message.it;
      }
    } else if (err?.response?.data?.message?.it) {
      message = err.response.data.message.it;
    } else if (err?.response?.data.includes("ERR_BAD_SYNTAX")) {
      message = "Errore, controlla di aver compilato correttamente il form";
    } else if (
      err?.response?.data.includes("ERR_INVALID_TOKEN") ||
      err?.response?.data.includes("ERR_MISSING_TOKEN")
    ) {
      message = "Non si è autenticati, rieseguire l'accesso alla piattaforma";
    }
    if (showToast) {
      sendNotification(message, "error");
    }
    throw err;
  } else {
    await router.replace({ name: "home-page" });
    if (showToast) {
      sendNotification(
        "Sessione scaduta, effettua nuovamente il login",
        "error"
      );
    }
    store.commit("auth/AUTH_LOGOUT");
    await store.dispatch("resetState");
  }
};

async function parseBlobError(blobError) {
  const parsedBlob = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const errorText = reader.result;
      resolve(errorText);
    };
    reader.onerror = () => {
      reject(new Error("Failed to parse Blob error"));
    };
    reader.readAsText(blobError);
  });
  return JSON.parse(parsedBlob);
}

export { sendNotification, handleAxiosError };
