import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/get_settings_landing_links
 */
function getLandingLinks() {
  return api.get("/settings/landing-links");
}
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/get_settings_blog_articles
 */
function getBlogArticles() {
  return api.get("/settings/blog-articles");
}
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/get_settings_messages
 */
function getMessages() {
  return api.get("/settings/messages");
}

export default {
  getLandingLinks,
  getBlogArticles,
  getMessages,
};
