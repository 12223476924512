export const eventSchools = [
  {
    name: "IS Archimede",
    city: "Treviglio (BG)"
  },
  {
    name: "IS Betty Ambiveri",
    city: "Presezzo (BG)"
  },
  {
    name: "IIS Blaise Pascal",
    city: "Reggio Emilia"
  },
  {
    name: "IIS David Maria Turoldo",
    city: "Zogno (BG)"
  },
  {
    name: "IISS Ettore Majorana",
    city: "Seriate (BG)"
  },
  {
    name: "Liceo Scientifico Statale \"Filippo Lussana\"",
    city: "Bergamo"
  },
  {
    name: "ITI Guglielmo Marconi",
    city: "Dalmine (BG)"
  },
  {
    name: "IIS \"Lorenzo Federici\"",
    city: "Trescore Balneario (BG)"
  },
  {
    name: "ITIS Pietro Paleocapa",
    city: "Bergamo"
  },
  {
    name: "ISISS Valle Seriana",
    city: "Gazzaniga (BG)"
  },
  {
    name: "ITIS Amedeo Avogadro",
    city: "Torino"
  },
  {
    name: "IISS Gaetano Salvemini",
    city: "Alessano (LE)"
  },
  {
    name: "ITIS Mario Delpozzo",
    city: "Cuneo"
  },
  {
    name: "ITI Enrico Fermi",
    city: "Siracusa"
  },
  {
    name: "IIS Federico Caffè",
    city: "Roma"
  },
  {
    name: "ITIS Galileo Galilei",
    city: "Roma"
  },
  {
    name: "ITC Galileo Galilei",
    city: "Avigliana (TO)"
  },
  {
    name: "ITIS Pininfarina",
    city: "Moncalieri (TO)"
  },
  {
    name: "ITIS Giancarlo Vallauri",
    city: "Velletri (RO)"
  },
  {
    name: "IIS Galileo Galilei",
    city: "Crema"
  },
  {
    name: "IIS Alessandro Volta",
    city: "Lodi"
  },
  {
    name: "IIS Giuseppe Peano",
    city: "Torino"
  },
  {
    name: "IIS Carlo e Nello Rosselli",
    city: "Aprilia (LT)"
  },
  {
    name: "IISS G. Marconi - M. Hack",
    city: "Bari"
  },
  {
    name: "ITIS Benedetto Castelli",
    city: "Brescia"
  },
  {
    name: "IIS Michele Giua",
    city: "Cagliari"
  },
  {
    name: "IS Giovanni XXIII - Cosentino",
    city: "Marsala (TP)"
  },
  {
    name: "IISS Benedetto Radice",
    city: "Bronte (CT)"
  },
  {
    name: "IISS Enrico Fermi",
    city: "Lecce"
  },
  {
    name: "ISIS Arturo Malignani",
    city: "Udine"
  },
  {
    name: "IIS Antonio Badoni",
    city: "Lecco"
  },
  {
    name: "IIS Giacomo Perlasca",
    city: "Vobarno (BS)"
  },
  {
    name: "ITT-LS Ettore Molinari",
    city: "Milano"
  },
  {
    name: "IIS Marconi - Mangano",
    city: "Catania"
  },
  {
    name: "ITI Francesco Severi",
    city: "Padova"
  },
  {
    name: "ISIS Città di Luino - C. Volonté",
    city: "Luino (VA)"
  },
  {
    name: "Istituto Giovanni Paolo II",
    city: "Roma"
  },
  {
    name: "IC G. Falcone e P. Borsellino",
    city: "Cremona"
  },
  {
    name: "ITC \"Leonardo da Vinci\"",
    city: "Bergamo"
  },
  {
    name: "IIS Primo Levi",
    city: "Torino"
  }
]