<template>
  <div class="landing-our-offer mt-15">
    <h2 class="our-offer-title">
      corsi in evidenza
    </h2>

    <v-expansion-panels
      class="mt-5"
      dark
      flat
      :value="0"
    >
      <v-expansion-panel
        v-for="(course, courseIndex) in courses.slice(0,6)"
        :key="course.title"
        class="our-offer-panel mb-5"
        :readonly="showDemo"
      >
        <v-expansion-panel-header @click="() => trackEvent('LandingPage', 'ClickOnCourse', course.title)">
          {{ course.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <DemoArea
            v-if="showDemo"
            :show-demo-area="showDemoArea"
          />
          <v-row
            v-else
            no-gutters
          >
            <v-col
              cols="12"
              sm="5"
              md="3"
              lg="2"
              align="center"
            >
              <v-img
                :src="getImg(course.imgLink)"
                class="course-img"
                alt="course image"
                :class="{
                  'course-img-mobile':
                    $vuetify.breakpoint.smAndDown,
                }"
              />
            </v-col>
            <v-col
              cols="12"
              sm="7"
              md="9"
              lg="10"
              :class="
                $vuetify.breakpoint.smAndUp ? 'pl-5' : 'pt-2'
              "
              class="info-container"
            >
              <v-row>
                <v-col>
                  <h4
                    class="course-content mt-3"
                    :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }"
                  >
                    {{ course.content }}
                  </h4>
                </v-col>
              </v-row>
              <v-row
                class="bottom-container"
                align="end"
              >
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-row align="end">
                    <v-col
                      v-for="teacher in course.teachers.slice()"
                      :key="teacher.name"
                      cols="12"
                      md="4"
                    >
                      <p class="teacher-name mb-0">
                        {{ teacher.name }}
                      </p>
                      <p class="teacher-role mb-0">
                        {{ teacher.role }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp && courseIndex === 0"
                  cols="12"
                  md="3"
                  class="d-flex justify-end"
                >
                  <v-btn
                    color="#F39200"
                    class="rounded-lg text-tansform-none"
                    @click="openDemoArea"
                  >
                    Prova la demo
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expand-transition
      v-for="(course) in courses.slice(6)"
      :key="course.title"
      class="mt-5"
    >
      <v-expansion-panels
        v-show="showMoreCourses"
        dark
        flat
      >
        <v-expansion-panel
          class="our-offer-panel mb-5"
          :readonly="showDemo"
        >
          <v-expansion-panel-header @click="() => trackEvent('LandingPage', 'ClickOnCourse', course.title)">
            {{ course.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <DemoArea
              v-if="showDemo"
              :show-demo-area="showDemoArea"
            />
            <v-row
              v-else
              no-gutters
            >
              <v-col
                cols="12"
                sm="5"
                md="3"
                lg="2"
                align="center"
              >
                <v-img
                  :src="getImg(course.imgLink)"
                  class="course-img"
                  alt="course image"
                  :class="{
                    'course-img-mobile':
                      $vuetify.breakpoint.smAndDown,
                  }"
                />
              </v-col>
              <v-col
                cols="12"
                sm="7"
                md="9"
                lg="10"
                :class="
                  $vuetify.breakpoint.smAndUp ? 'pl-5' : 'pt-2'
                "
                class="info-container"
              >
                <v-row>
                  <v-col>
                    <h4
                      class="course-content mt-3"
                      :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }"
                    >
                      {{ course.content }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row
                  class="bottom-container"
                  align="end"
                >
                  <v-col
                    v-for="teacher in course.teachers"
                    :key="teacher.name"
                    cols="12"
                    md="4"
                  >
                    <p class="teacher-name mb-0">
                      {{ teacher.name }}
                    </p>
                    <p class="teacher-role mb-0">
                      {{ teacher.role }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-expand-transition>
    <v-btn
      color="#F39200"
      outlined
      class="show-more-btn"
      @click="showMoreCourses = !showMoreCourses"
    >
      {{ showMoreCourses ? 'Vedi meno corsi' : 'Scopri tutti i corsi' }}
    </v-btn>
  </div>
</template>

<script>
import DemoArea from "@cmp-landing/DemoArea.vue";
import matomoTracker from "@mixins/matomoTracker";


export default {
  name: "LandingOurOffer",
  components: { DemoArea },
  mixins:[matomoTracker],
  data() {
    return {
      showMoreCourses: false,
      courses: [
        {
          title: "HTML-CSS Base",
          content:
            "Introduzione ai linguaggi di markup HTML e CSS. Dai concetti teorici di base fino allo sviluppo a 4 mani con il docente di un portfolio personale. Durante il corso verrà mostrato anche come rendere responsive le proprie pagine web.",
          teachers: [
            {
              name: "Leonardo Mussato",
              role: "Front End Developer"
            }
          ],
          imgLink: "HTML&CSS.webp",
        },
        {
          title: "HINTS OF REVERSE ENGINEERING",
          content:
            "Dal 02/05 al 12/05! Lo scopo del reverse engineering in ambito software è quello di riprodurre il codice di un programma esistente \"smontandolo\" prima pezzo per pezzo. Questa pratica è estremamente funzionale nella ricerca di vulnerabilità IT.",
          teachers: [
            {
              name: "Cesare Pizzi",
              role: "Cybersecurity Supervisor"
            }
          ],
          imgLink: "ReverseEngineering.webp",
        },{
          title: "LINUX BASH SCRIPTING",
          content:
            "Dal 16/01 al 27/01! Programma in fase di definizione...",
          teachers: [
            {
              name: "Adriano Bertesago",
              role: "Senior System Administrator"
            }
          ],
          imgLink: "Linux.webp",
        },
        {
          title: "DEVSECOPS, SECURITY FOR DEVELOPERS",
          content:
            "Dal 12/04 al 21/04! Corso dedicato alla presa di consapevolezza e alla promozione della cybersecurity come responsabilità comune di tutti i team coinvolti nello sviluppo del software.",
          teachers: [
            {
              name: "Luca Famà",
              role: "Security Consultant"
            }
          ],
          imgLink: "DevSecOps.webp",
        },
        {
          title: "ON MOBILE WITH REACT NATIVE",
          content:
            "Dal 20/03 al 31/03! React Native è un framework JavaScript usato per sviluppare app mobile multipiattaforma. È un framework open source pensato per consentire ai programmatori di usare lo stesso design di sviluppo per app desktop/browser e mobile.",
          teachers: [
            {
              name: "Fabrizio Rizzi",
              role: "Front End Developer"
            }
          ],
          imgLink: "React.webp",
        },
        {
          title: "LET’S LEARN PostrgreSQL",
          content:
            "Dal 30/03 al 10/02! PostgreSQL è un completo DBMS ad oggetti rilasciato con licenza libera. Rispetto al classico MySQL è più adatto a gestire situazioni complesse e per questo è sempre più competitivo nel mercato IT. Proviamo a conoscerlo meglio insieme!",
          teachers: [
            {
              name: "Lorenzo Farioli",
              role: "Senior DBA"
            }
          ],
          imgLink: "PostgreSQL.webp",
        },
        {
          title: "INTRODUCTION TO GOLANG",
          content:
            "Dal 27/02 al 10/03 2022! Golang è un linguaggio di programmazione open source sviluppato da Google, oggi sempre più competitivo nel mercato del lavoro. Sempre più progetti Back End vengono gestiti con questo linguaggio, proviamo a scoprire insieme perchè!",
          teachers: [
            {
              name: "Ivan Pesenti",
              role: "Back End Developer"
            }
          ],
          imgLink: "GoLang.webp",
        },
        {
          title: "Corso Linux completo con Ubuntu",
          content:
            "Introduzione al mondo Linux (Ubuntu) da primo approccio al sistema operativo fino a nozioni avanzate. Storia di Linux, comandi base, filesystem, utenti e gruppi, permessi, processi, ssh, redirezione I/O, logging, pacchetti, networking e tanto altro!",
          teachers: [
            {
              name: "Luca D'Addeo",
              role: "T-Shaped ICT Consultant & Trainer"
            },
            {
              name: "Matias Seco",
              role: "DevOps Engineer"
            }
          ],
          imgLink: "LinuxUbuntu.webp",
        },
        {
          title: "Introduzione a JavaScript",
          content:
            "Introduzione ai concetti essenziali per un primo utilizzo di JavaScript. Dalla sintassi passando per variabili, funzioni, istruzioni, cicli, oggetti, array... fino ad arrivare alle web APIs e al DOM, il tutto sviluppando qualche piccolo progetto insieme!",
          teachers: [
            {
              name: "Marco Bolis",
              role: "Full-Stack Developer"
            }
          ],
          imgLink: "Javascript.webp",
        },
        {
          title: "JavaScript Avanzato",
          content:
            "Presa confidenza con le funzionalità principali JavaScript (dal corso \"Introduzione a JavaScript\"), gli studenti verranno introdotti ad un utilizzo più approfondito e complesso del linguaggio, fino ad arrivare ad AJAX, Node.js e i frameworks.",
          teachers: [
            {
              name: "Fabrizio Rizzi",
              role: "Front End Developer"
            }
          ],
          imgLink: "Javascript.webp",
        },
        {
          title: "Fondamentali Linux con CentOS",
          content:
            "Da un approccio base ad operazioni avanzate con la distribuzione Red Hat CentOS.",
          teachers: [
            {
              name: "Luca Cavatorta",
              role: "Linux system engineer"
            },
            {
              name: "Fabrizio Colombo",
              role: "Linux system engineer"
            }
          ],
          imgLink: "CentOS.webp",
        },
        {
          title: "Metodologia Agile SCRUM",
          content:
            "Introduzione a SCRUM, framework Agile per la gestione del ciclo di sviluppo del software.",
          teachers: [
            {
              name: "Stefania Tiraboschi",
              role: "Scrum Master"
            }
          ],
          imgLink: "Scrum.webp",
        },
        {
          title: "GIT & GITHUB",
          content:
            "Introduzione allo strumento di controllo versione distribuito GIT e alla piattaforma di hosting GITHUB.",
          teachers: [
            {
              name: "Giuseppe Mancuso",
              role: "DevOps"
            },
            {
              name: "Simone Gotti",
              role: "Full-Stack Developer"
            }
          ],
          imgLink: "GitGitHub.webp",
        }
      ],
      showDemo: false,
    };
  },
  computed: {
    visibleCourses() {
      if (this.showMoreCourses) {
        return this.courses
      }

      return this.courses.slice(0,6)
    }
  },
  methods: {
    showDemoArea(boolean){
      this.showDemo = boolean;
    },
    getImg(imgPath) {
      try {
        return require(`@/assets/landingPage/${imgPath}`)
      } catch (e) {
        return require("@/assets/landingPage/NoCourseImg.webp")
      }
    },
    openDemoArea() {
      this.trackEvent("LandingPage", "ClickOnTryDemo");
      this.showDemoArea(true);
    }
  }
};
</script>

<style scoped>
.landing-our-offer {
  text-align: center;
}

.our-offer-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #f4f5f6;
  text-transform: uppercase;
}
.our-offer-panel {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  text-align: left;
}
.course-img {
  border-radius: 12px;
  width: 293px;
  height: 219px;
  object-fit: contain;
  object-position: center;
}

.course-content {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98a6ae;
}

.course-teacher {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #f4f5f6;
}

.teacher-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #f4f5f6;
}

.teacher-role {
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  color: #98a6ae;
}

.flex-expand{
  flex:1 1 0%;
}
.course-content-container{
  display: flex;
  flex-direction: column;
}
.bottom-container{
  display: flex;
  flex-direction: row;
}
.teacher-container{
  height: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.teacher-img{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.try-demo-button{
height: 40px;
background: #F39200 !important;
border-radius: 12px;
font-family: 'Karasuma Gothic';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: right;
color: #F4F5F6;
text-transform: capitalize;
padding: 8px 12px !important;
}

.show-more-btn {
  border-radius: 12px;
  text-transform: none;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f39200;
}

.info-container {
  display: flex;
  flex-direction: column;
}
</style>
