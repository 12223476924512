<template>
  <div>
    <v-expansion-panels dark>
      <v-expansion-panel
        v-for="(course, courseIndex) in courseSections"
        :key="course.id"
        :readonly="courseIndex > 1"
        active-class="course-list-wrapper-clicked"
        class="mb-5 transparent course-list-wrapper"
      >
        <v-tooltip
          :attach="`.course-panel-${courseIndex}`"
          :disabled="courseIndex <= 1"
        >
          <template #activator="{ on, attrs }">
            <v-expansion-panel-header
              class="course-list-header"
              active-class="course-list-clicked"
              v-bind="attrs"
              v-on="on"
            >
              <v-row>
                <v-col
                  cols="2"
                  class="d-flex align-center"
                >
                  <span :class="`wrapper-number course-panel-${courseIndex}`">{{ courseIndex + 1 }}</span>
                </v-col>
                <v-col cols="10">
                  <v-row
                    class="mx-2"
                    no-gutters
                  >
                    <v-col cols="12">
                      <span
                        class="course-paragraph number-of-videos"
                      >{{ course.videos.length }} risorse</span>
                    </v-col>
                    <v-col cols="12">
                      <h3 class="course-title">
                        {{ course.title }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </template>
          <span>Accedi o Registrati per seguire il corso</span>
        </v-tooltip>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              v-for="(video, videoIndex) in course.videos"
              :key="video.id"
              cols="12"
              class="px-0"
            >
              <v-card
                width="100%"
                height="44"
                class="rounded-lg video-title-wrapper pb-2 px-2 mt-2"
              >
                <v-row align="center">
                  <v-col
                    cols="1"
                    class="pr-0 py-0 mt-3"
                  >
                    <v-checkbox
                      :ripple="false"
                      disabled
                      class="pb-0 check-box"
                      readonly
                      dense
                    />
                  </v-col>
                  <v-col cols="9">
                    <span class="course-paragraph">{{ video.title }}</span>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-end"
                  >
                    <v-icon
                      left
                      disabled
                    >
                      {{ videoIndex === course.videos.length - 1 ? 'mdi-pencil' : 'mdi-clock-outline' }}
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "DemoAreaPath",
  data: () => ({
    courseSections: [
      {
        id: Math.random(),
        title: "Introduzione a HTML",
        videos: [
          {
            id: Math.random(),
            title: "Introduzione al corso",
          },
          {
            id: Math.random(),
            title: "Gli elementi HTML",
          },
          {
            id: Math.random(),
            title: "Gli elementi più comuni",
          },
          {
            id: Math.random(),
            title: "Quiz sezione 1",
          },
        ],
      },
      {
        id: Math.random(),
        title: "Introduzione al css",
        videos: [
          {
            id: Math.random(),
            title: "Introduzione al css",
          },
          {
            id: Math.random(),
            title: "I selettori css",
          },
          {
            id: Math.random(),
            title: "Il box model",
          },
          {
            id: Math.random(),
            title: "Background, colori e testo",
          },
          {
            id: Math.random(),
            title: "Quiz sezione 2",
          },
        ],
      },
      {
        id: Math.random(),
        title: "Iniziamo con la pratica",
        videos: [{}, {}, {}, {}],
      },
      {
        id: Math.random(),
        title: "Aggiustiamo con il CSS",
        videos: [{}, {}, {}, {}, {}, {}, {}, {}],
      },
      {
        id: Math.random(),
        title: "Componiamo le altre pagine del portfolio",
        videos: [{}, {}, {}, {}, {}, {}, {}],
      },
      {
        id: Math.random(),
        title: "Rendiamo la pagina Responsive",
        videos: [{}, {}, {}, {}, {}],
      },
      {
        id: Math.random(),
        title: "Quiz Finale",
        videos: [{}, {}],
      },
    ],
  }),
};
</script>

<style scoped>
.course-list-header {
  background-clip: border-box;
  background: linear-gradient(
    to right,
    #f39200 0%,
    #f39200 20%,
    transparent 20%,
    transparent 100%
  ) !important;
  border-radius: 12px !important;
}

.course-list-wrapper {
  border: thin solid #555571 !important;
  border-radius: 13px !important;
}

.course-list-wrapper-clicked {
  border-radius: 0px;
  border: 2px solid #f39200 !important;
}
.course-list-wrapper-clicked .course-list-header {
  border-radius: 10px 0px 0px 0px !important;
  transition: border-radius 0.1s;
}

.wrapper-number {
  font-weight: 700;
  font-size: 48px;
  line-height: 28px;
  color: #ffffff;
}

.number-of-videos {
  color: #f4f5f6;
}

.course-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
}

.video-title-wrapper{
  background-color: transparent !important;
  border: 1px #5a6972 solid;
}

.course-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #98a6ae;
}

.check-box input{
  pointer-events: none !important;
}
</style>
